<!-- 校长报告 -->
<template>
    <div class="report">
        <el-row class="tac">
            <el-col :span="12">
                <el-menu default-active="2" class="el-menu-vertical-demo" unique-opened @open="handleOpen"
                    @close="handleClose" @select="handSelect" background-color="#545c64" text-color="#fff"
                    active-text-color="#ffd04b" :collapse='isCollapse'>
                    <div class="mySelect">
                        <el-select v-model="termValue" placeholder="请选择学期" @change="termChange">
                            <el-option v-for="item in termOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <el-menu-item index="首师优字前测报告">
                        <i class="el-icon-menu"></i>
                        <span slot="title">首师优字前测报告</span>
                    </el-menu-item>
                    <el-submenu index="学校整体报告">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>学校整体报告</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item :index="item.name" v-for="(item) in gradeList" :key="item.orgId">
                                {{ item.name + '报告' }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <!-- <el-submenu index="学生个人报告">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>学生个人报告</span>
                        </template>
                        <el-submenu :index="item.label" v-for="(item, index) in data" :key="index">
                            <template slot="title">{{ item.label }}</template>
                            <el-menu-item :index="items.label + ',' + items.classId" v-for="(items) in item.children"
                                :key="items.classId">
                                {{ items.label }}</el-menu-item>
                        </el-submenu>
                    </el-submenu> -->
                    <el-menu-item index="报告助手" @click="clickMenu('报告助手')">
                        <i class="el-icon-menu"></i>
                        <span slot="title">报告助手</span>
                    </el-menu-item>
                    <!-- <el-menu-item index="首师优字后测报告">
                        <i class="el-icon-menu"></i>
                        <span slot="title">首师优字后测报告</span>
                    </el-menu-item>
                    <el-submenu index="学校整体报告后测">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>学校整体报告</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item :index="item.name" v-for="(item) in gradeList_after" :key="item.orgId">
                                {{ item.name + '报告' }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu> -->
                    <!-- <el-submenu index="学生个人报告后测">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>学生个人报告</span>
                        </template>
                        <el-submenu :index="item.label + ' '" v-for="(item, index) in data_after" :key="index">
                            <template slot="title">{{ item.label }}</template>
                            <el-menu-item :index="items.label + ',' + items.classId" v-for="(items) in item.children"
                                :key="items.classId">
                                {{ items.label }}</el-menu-item>
                        </el-submenu>
                    </el-submenu> -->
                    <!-- <el-menu-item index="hide">
                        <i class="el-icon-caret-top"></i>
                        <span slot="title">收起</span>
                    </el-menu-item> -->
                </el-menu>
                <div class="hidden" @click="hide">
                    <i class="el-icon-caret-left"></i>
                    <span slot="title">收起</span>
                </div>
            </el-col>
        </el-row>
        <template v-if="gradeList.length > 0">
            <!-- 前测-整体 -->
            <div class="content flex-column-center" id='pdfDom' v-show="currentType == 'grade'">
                <div class="all flex-column-center" id="gradeAll">
                    <div class="page_one flex-column-center">
                        <div class="school">{{ schoolName }}</div>
                        <div class="title">学生识写能力</div>
                        <!-- <el-button type="primary" @click="exportpdf" icon="el-icon-download">下载学校报告</el-button> -->
                        <div class="name">
                            <span>前</span>
                            <span>测</span>
                            <span>报</span>
                            <span>告</span>
                        </div>
                        <div class="serve">首都师范大学“双优云桥”教育服务中心</div>
                    </div>
                    <div class="page_two flex-column">
                        <div class="title">整体报告</div>
                        <div class="item_title">一、总述</div>
                        <p>为在本校精准、高效的开展关于规范汉字书写的常规教学活动，由首都师范大学首师优字团队，对我校在校学生集中进行了规范汉字识写能力前期测评。</p>
                        <p>1.参与情况</p>
                        <p> 经过前期试卷收集及入库，本次前测活动共有{{ totalData.joinCount }}个学生参与，
                            共回收到有效前测试卷{{ allData.result }}页（每份试卷两页）。其中{{ maxRateName }}参与率最高，{{ minRateName }}参与率最低。</p>
                        <MyTable type="join" :thead="['年级', '注册人数', '参与总人数', '参与率']" :tableData="allTableData">
                        </MyTable>
                        <p>2.书写习惯</p>
                        <p>(1)针对握笔姿势有{{ holdInfo.failNum }}名学生使用了错误的握笔姿势，占全校的{{ holdInfo.faliRatio }}%。
                            其中{{ classLists[holdInfo.lowName.substring(0, 1)] }}的错误率最高，{{
                                classLists[holdInfo.highName.substring(0, 1)] }}错误率最低。
                        </p>
                        <MyTable type="wbzs" :thead="['年级', '握笔错误人数', '错误比例']" :tableData="woziAll"></MyTable>
                        <div class="myChart" id="myChart24"></div>
                        <p>(2)针对坐姿，有{{ sitInfo.failNum }}名学生出现坐姿错误，占全校的{{ sitInfo.faliRatio }}%。
                            其中{{ classLists[sitInfo.lowName.substring(0, 1)] }}错误率最高，{{
                                classLists[sitInfo.highName.substring(0, 1)] }}错误率最低。
                        </p>
                        <MyTable type="zz" :thead="['年级', '坐姿错误人数', '错误比例']" :tableData="sitAll"></MyTable>
                        <div class="myChart" id="myChart25"></div>
                        <p>3.书写水平</p>
                        <!-- <p>(1)全校书写水平</p> -->
                        <!-- <p>针对前测样本数据综合分析四个书写基础维度得分，学生在笔画形态和结构组合两个维度低于全国平均水平。</p> -->
                        <MyTable type="sxwd" :thead="['书写维度', '考核要求', '前测平均水平', '全国常模']" :tableData="allInfo"></MyTable>
                        <p>①　{{ comments_art1 }}</p>
                        <p>②　{{ comments_art2 }}</p>
                        <!-- <p>(2)年级书写水平</p>
                        <p v-for="(item, index) in gradeCommentList" :key="index">{{ item }}</p> -->
                        <p>4.优秀作品获得者，详细名单如下</p>
                        <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.all"></MyTable>
                        <div style="font-size:14px">部分优秀作品实例:</div>
                        <div class="youxiu_works" v-for="(item, index) in excellentWork.all" :key="index + 'a'">
                            <div class="flex-align">
                                <img :src="item.works[0]" alt="">
                                <img :src="item.works[1]" alt="">
                            </div>
                            <span>作者:{{ item.userName }} {{ item.className }}</span>
                        </div>
                        <p>5.教师上课情况。各班级教师上课总次数如下：</p>
                        <MyTable type="teacher" :thead="['序号', '年级', '班级', '教师', '本学期上课总次数']" :tableData="teaData">
                        </MyTable>
                        <div class="myChart" id="myChart29"></div>
                        <div class="item_title">二、项目背景</div>
                        <p> 经研究表明，学生识写能力与精细运动能力和综合学习能力发展密切相关，为了解学生汉字的识写能力水平，
                            特引进首都师范大学专业团队设计并开展规范汉字书写指导工作。前期需先对学生基础水平，常见问题进行一项测评，并于学期末进行后测，形成对比。
                            形式采用线下测评的方式，组织学生进行一套测试题的书写，不同年级书写量有所不同，书写时间10-30分钟左右。
                            首师大专家会针对学生的情况出具详细的测评报告，为后续开展有针对性地指导打好基础。
                        </p>
                        <div class="item_title">三、试卷设计</div>
                        <p>本次前期检测试卷由首都师范大学“双优云桥”教育服务中心统一命题，内容覆盖面广，
                            重点突出，具有一定的代表性；试卷题量适中，由易到难，具备一定的层次性。
                        </p>
                        <p>根据每个年级学生认知水平进行不同内容与考察点，同时试卷内容设计既重视对基础书写规范的考查，同时注重对学生汉字识字及应用能力的培养、归纳，
                            能较为全面的检测参与测试的学生对已有汉字识写基础知识的掌握情况。</p>
                        <p>识字方面，通过拼音、组词、成语及笔顺（根据年级有所侧重）等角度考察学生对汉字的认知；写字方面，通过日常书写、刻意书写两个角度来测评学生的书写水平。</p>
                        <div class="item_title">四、开展过程</div>
                        <p>本次前测，采用统一命题，使用首都师范大学专业技术团队研发的AI智能评价技术进行统一测评，能够准确评价学生识写能力，并做出量化分析。</p>
                        <p>开展过程包括以下几个阶段：</p>
                        <p>（一）准备阶段</p>
                        <p>印刷书写材料及信息识别码。</p>
                        <p>（二）书写阶段</p>
                        <p>学生在一定时间内，进行前测书写，可使用黑色铅笔、钢笔、水性笔，保持卷面整洁、书写完整清晰。</p>
                        <p>（三）测评阶段</p>
                        <p>回收试卷，并对试卷进行扫描入库，系统识别及对每个字的评价。</p>
                        <p>（四）总结报告</p>
                        <p>针对评价结果，产生对应的测评报告。</p>
                        <div class="item_title">五、试卷回收情况</div>
                        <p>经过试卷分析统计，本次前测活动共识别到{{ totalData.joinCount }}个学生参与，
                            共回收到有效的前测试卷{{ totalData.joinCount * 2 }}页。共收集到各年级试卷样本数量如下：</p>
                        <table border="1">
                            <tr>
                                <td>年级班级</td>
                                <td>参与总人数</td>
                                <td>有效试卷页数</td>
                                <td>空白卷/污损/不清晰导致测评失败</td>
                            </tr>
                            <tr v-for="(item, index) in allTableData" :key="index">
                                <td>{{ item.termName }}</td>
                                <td>{{ item.joinCount }}</td>
                                <!-- <td>{{ item.joinCount * 2 }}</td> -->
                                <td>{{ item.workCount }}</td>
                                <td>{{ item.joinCount * 2 - item.workCount }}</td>
                            </tr>
                            <tr>
                                <td>{{ totalData.termName }}</td>
                                <td>{{ totalData.joinCount }}</td>
                                <!-- <td>{{ totalData.joinCount * 2 }}</td> -->
                                <td>{{ totalData.workCount }}</td>
                                <td>{{ totalData.joinCount * 2 - totalData.workCount }}</td>
                            </tr>
                        </table>
                        <p style="font-size:14px">
                            *为提高识别率及成绩的完整性，建议同学们在测评过程中，内容书写完整，字迹清晰，试卷整洁，尽量不涂鸦，保持四周边线及二维码等清晰无遮挡（需要粘贴条码，请在引导框整正确粘贴个人信息条码）。
                        </p>
                        <div class="item_title">六、测评结果分析</div>
                        <p>（一）书写习惯</p>
                        <p>●握笔姿势</p>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。</p>
                        <p>（1）全校学生握笔姿势整体分布 </p>
                        <p>关于本校学生握笔姿势的调查，各年级选择的人数分布如下：</p>
                        <table border="1">
                            <tr>
                                <td>年级</td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿1
                                        <img style="width:100px" src="../../assets/report/u0.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿2
                                        <img style="width:100px" src="../../assets/report/u1.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿3(正确)
                                        <img style="width:100px" src="../../assets/report/u2.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        握姿4
                                        <img style="width:100px" src="../../assets/report/u3.jpg" alt="">
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(item, index) in woziAll" :key="index">
                                <td>{{ item.name }}</td>
                                <td>{{ item.posture_one }}</td>
                                <td>{{ item.posture_two }}</td>
                                <td>{{ item.posture_three }}</td>
                                <td>{{ item.posture_four }}</td>
                            </tr>
                        </table>
                        *（注：本次调查可能出现学生选择多项）
                        <!-- 全校学生握笔姿势分布饼图 -->
                        <div class="myChart" id='myChart7'></div>
                        <p>根据调查结果，学生选择正确的握笔姿势（握姿3）总人数为{{ holdInfo.rightNnm }}人，占全校学生的{{ holdInfo.rightRatio }}%；
                            学生选择错误握笔姿势（选择：握姿1，握姿2，握姿4）的共有{{ holdInfo.failNum }}人，占全校学生的{{ holdInfo.faliRatio }}%。</p>
                        <p>（2）各年级学生握笔姿势正确率 </p>
                        <!-- 各年级学生握笔姿势正确率 -->
                        <div class="myChart" id="myChart8"></div>
                        <p>根据调查结果可见，{{ holdInfo.highName }}握笔正确率最高占{{ holdInfo.highRatio }}%；{{ holdInfo.lowName
                        }}握笔正确率最低，为{{ holdInfo.lowRatio }}%。
                        </p>
                        <p>（3）全校学生错误握笔姿势分布</p>
                        <div class="myChart" id='myChart9'></div>
                        <p>根据数据分析：</p>
                        <p>（1）学生选择握姿1的共有{{ holdInfo.hold_1 }}人，占错误人数的{{ holdInfo.ratio_1
                        }}%，该错误握笔姿势是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；
                        </p>
                        <p>（2）选择握姿2的有{{ holdInfo.hold_2 }}人，占错误人数的{{ holdInfo.ratio_2 }}%，该错误握笔姿势是把拇指藏在食指后面，是普遍易犯错误的执笔手势；
                        </p>
                        <p>（3）握姿4的有{{ holdInfo.hold_4 }}人，占错误人数的{{ holdInfo.ratio_4
                        }}%，该错误握笔姿势是手腕内钩，笔向自己，会造成书写角度的错误，同时这种执笔方法会影响脊骨健康。
                        </p>
                        <p>握姿1,2,4都是错误的，这些执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感，应予以重视。</p>
                        <p>（4）各年级学生错误握笔姿势分布</p>
                        <div class="myChart" id='myChart10'></div>
                        <p>按照年级统计如图，各年级四种握笔姿势分别的人数统计如下：整体来看错误的握笔姿势中，握笔姿势{{ holdInfo.mostFail }}所占人数最多，应引起重视。</p>
                        <p>●坐姿</p>
                        <p>（1）全校学生坐姿整体分布 </p>
                        <p>关于本校学生坐姿的调查，各年级选择的人数分布如下：</p>
                        <table border="1">
                            <tr>
                                <td>年级</td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿1
                                        <img style="width:100px" src="../../assets/report/u4.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿2
                                        <img style="width:100px" src="../../assets/report/u5.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿3
                                        <img style="width:100px" src="../../assets/report/u6.jpg" alt="">
                                    </div>
                                </td>
                                <td>
                                    <div class="flex-column-center" style="padding-bottom:5px">
                                        坐姿4(正确)
                                        <img style="width:100px" src="../../assets/report/u7.jpg" alt="">
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(item, index) in sitAll" :key="index">
                                <td>{{ item.name }}</td>
                                <td>{{ item.sit_one }}</td>
                                <td>{{ item.sit_two }}</td>
                                <td>{{ item.sit_three }}</td>
                                <td>{{ item.sit_four }}</td>
                            </tr>
                        </table>
                        *（注：本次调查可能出现学生选择多项）
                        <div class="myChart" id='myChart11'></div>
                        <p>根据调查结果，学生选择正确的坐姿（坐姿4）总人数为{{ sitInfo.rightNum }}人，占全校学生的{{ sitInfo.rightRatio }}%；
                            学生选择错误坐姿（坐姿1，2，3）的共有{{ sitInfo.failNum }}人，占全校学生的{{ sitInfo.faliRatio }}%。</p>
                        <p>（2）各年级学生坐姿正确率 </p>
                        <div class="myChart" id="myChart12"></div>
                        <p>根据调查结果可见，{{ sitInfo.highName }}坐姿正确率最高占{{ sitInfo.highRatio }}%；{{ sitInfo.lowName }}坐姿正确率最低，为{{
                            sitInfo.lowRatio }}%。
                        </p>
                        <p>（3）全校学生错误坐姿分布 </p>
                        <div class="myChart" id='myChart13'></div>
                        <p>（1）学生选择坐姿1的共有{{ sitInfo.hold_1 }}人，占{{ sitInfo.ratio_1 }}%，该坐姿学生趴在桌子上写字，可能会引起驼背、近视等问题；</p>
                        <p>（2）选择坐姿2的有{{ sitInfo.hold_2 }}人，占{{ sitInfo.ratio_2 }}%，该坐姿学生身体倾向一侧，可能会引起脊柱侧弯等问题；</p>
                        <p>（3）坐姿3的有{{ sitInfo.hold_4 }}人，占{{ sitInfo.ratio_4 }}%，该坐姿学生身体贴着桌子，可能会造成不稳定，且影响视力。</p>
                        <p>前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。</p>
                        <p>（4）各年级学生错误坐姿分布 </p>
                        <div class="myChart" id='myChart14'></div>
                        <p>按照年级统计如图，各年级四种坐姿姿势分别的人数统计结果，整体来看错误的坐姿中，{{ sitInfo.mostFail }}所占人数最多，应引起重视。</p>
                        <p>（二）书写基础</p>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。</p>
                        <p>其中，作品完整度指整个篇幅中，正确书写内容所占的百分比；笔画形态指所写的字与示范字的相似度；章法占格指所有书写内容书写大小占格的偏差情况；结构组合指整个篇幅中，所有字的上下左右对齐的情况。根据各维度学生成绩的分布情况，划分为三个分数段进行统计，各年级学生具体得分情况分析如下：
                        </p>
                        <p>●作品完整度</p>
                        <p>
                            考察学生在书写时能够完整正确书写的情况，涂画或错别字会造成成绩的偏差。本校在作品完整度各分数段整体分布情况如下：
                            A(80-100)占{{ (allInfo.all_wzd_a * 100).toFixed(2) }}%；B(65-80)占{{ (allInfo.all_wzd_b *
                                100).toFixed(2) }}%；C(0-65)占{{ (allInfo.all_wzd_c * 100).toFixed(2) }}%。
                            整体来看，各分数段中，{{ allInfo.all_wzd_high }}等级的占比最高，该分数段中{{ allInfo.all_wzd_HClass }}平均完整度最高，{{
                                allInfo.all_wzd_LClass }}平均完整度最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart17'></div>
                        <p>●笔画形态</p>
                        <p>整体成绩分析</p>
                        <p>
                            考察前测中学生书写笔法是否正确，字法的规范，本校在笔画形态各分数段整体分布情况如下：
                            A(80-100)占{{ (allInfo.all_xsx_a * 100).toFixed(2) }}%；B(65-80)占{{ (allInfo.all_xsx_b *
                                100).toFixed(2) }}%；C(0-65)占{{ (allInfo.all_xsx_c * 100).toFixed(2) }}%。
                            整体来看，各分数段中，{{ allInfo.all_xsx_high }}等级的占比最高，该分数段中{{ allInfo.all_xsx_HClass }}笔画形态平均成绩最高，{{
                                allInfo.all_xsx_LClass }}笔画形态平均成绩最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart18'></div>
                        <p>分维度分析</p>
                        <p>经过统计发现，笔画书写中各三级维度，平均成绩分布如下表，其中方向维度{{ fx_min.name }}平均分最低为{{ fx_min.val }}分;
                            笔性维度{{ bx_min.name }}平均分最低为{{ bx_min.val }}分;
                            粗细维度{{ cx_min.name }}的平均分最低为{{ cx_min.val }}分。</p>
                        <MyTable type="bihua" :thead="['二级维度', '方向', '笔性', '粗细']" :tableData="bihuaTableData"></MyTable>
                        <div class="myChart" id='myChart26'></div>
                        <p>分析各年级在笔画形态中的成绩发现：</p>
                        <p v-for="(item, index) in gradeCommentList_bihua" :key="index + Date.now()">{{ item }}</p>
                        <p>●章法占格</p>
                        <p>
                            考察学生书写时生字在格子中的占格大小情况，本校在章法占格各分数段整体分布情况如下：
                            A(80-100)占{{ (allInfo.all_yzx_a) }}%；B(65-80)占{{ (allInfo.all_yzx_b) }}%；C(0-65)占{{
                                (allInfo.all_yzx_c) }}%。
                            整体来看，各分数段中，{{ allInfo.all_yzx_high }}等级的占比最高，该分数段中{{ allInfo.all_yzx_HClass }}章法占格平均成绩最高，{{
                                allInfo.all_yzx_LClass }}章法占格平均成绩最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart19'></div>
                        <p>分维度分析</p>
                        <p>经过统计发现，章法占格的四个维度中，平均成绩分布如下表，其中卷面整洁维度{{ jm_min.name }}平均分最低为{{ jm_min.val }}分；
                            占格占位维度{{ zg_min.name }}平均分最低为{{ zg_min.val }}分；
                            字形大小维度{{ zx_min.name }}的平均分最低为{{ zx_min.val }}分。
                        </p>
                        <MyTable type="zhangfa" :thead="['二级维度', '卷面整洁', '占格占位', '字形大小']" :tableData="zhangfaTableData">
                        </MyTable>
                        <div class="myChart" id='myChart27'></div>
                        <p>分析各年级在章法占格中的成绩发现：</p>
                        <p v-for="(item, index) in gradeCommentList_zhangfa" :key="index + Date.now() + 'x'">{{ item }}
                        </p>
                        <p>●结构组合</p>
                        <p>整体成绩分析</p>
                        <p>
                            考察学生在书写时篇章内容之间整齐书写的情况，本校在结构组合各分数段整体分布情况如下：
                            A(80-100)占{{ (allInfo.all_zqd_a) }}%；B(65-80)占{{ (allInfo.all_zqd_b) }}%；C(0-65)占{{
                                (allInfo.all_zqd_c) }}%。
                            整体来看，各分数段中，{{ allInfo.all_zqd_high }}等级的占比最高，该分数段中{{ allInfo.all_zqd_HClass }}平均相似性最高，{{
                                allInfo.all_zqd_LClass }}平均相似性最低。
                        </p>
                        <p>各年级详细分布见下图：</p>
                        <div class="myChart" id='myChart20'></div>
                        <p>分维度分析</p>
                        <p>经过统计发现，结构组合四个维度中，平均成绩分布如下表，
                            其中字形重心维度{{ zx_min.name }}平均分最低为{{ zx_min.val }}分；
                            主次收放维度{{ zc_min.name }}平均分最低为{{ zc_min.val }}分；
                            笔画关系维度{{ bh_min.name }}的平均分最低为{{ bh_min.val }}分；
                            部件关系维度{{ bj_min.name }}的平均分最低为{{ bj_min.val }}分。
                        </p>
                        <MyTable type="jiegou" :thead="['二级维度', '字形重心', '主次收放', '笔画关系', '部件关系']"
                            :tableData="jiegouTableData">
                        </MyTable>
                        <div class="myChart" id='myChart28'></div>
                        <p>分析各年级在结构组合中的成绩发现：</p>
                        <p v-for="(item, index) in gradeCommentList_jiegou" :key="index + Date.now() + 'xc'">{{ item }}
                        </p>
                        <p>（三）书写辨析</p>
                        <p>
                            通过二、三、四题考察音形义、结构、笔顺笔画等书写辨析能力，
                            其中在生字音形义辨析中，{{ computedMinVal(zqlTable.zql_q2).name }}正确率最低为{{
                                computedMinVal(zqlTable.zql_q2).value }}%；
                            在不同结构生字辨析中，{{ computedMinVal(zqlTable.zql_q3).name }}正确率最低为{{
                                computedMinVal(zqlTable.zql_q3).value }}%；
                            在不同笔顺笔画生字辨析中，{{ computedMinVal(zqlTable.zql_q4).name }}正确率最低为{{
                                computedMinVal(zqlTable.zql_q4).value }}%。
                        </p>
                        <p>书写辨析详细数据如下：</p>
                        <table border="1">
                            <tr>
                                <th style="width:80px">题目</th>
                                <th>辨析内容</th>
                                <th v-for="(item, index) in zqlTable.zql_name" :key="index">{{ item }}正确率</th>
                            </tr>
                            <tr>
                                <td>第二题</td>
                                <td>生字音形义辨析</td>
                                <td v-for="(item, index) in zqlTable.zql_q2" :key="index">{{ item.value }}%</td>
                            </tr>
                            <tr>
                                <td>第三题</td>
                                <td>不同结构生字辨析</td>
                                <td v-for="(item, index) in zqlTable.zql_q3" :key="index">{{ item.value }}%</td>
                            </tr>
                            <tr>
                                <td>第四题</td>
                                <td>不同笔顺笔画生字辨析</td>
                                <td v-for="(item, index) in zqlTable.zql_q4" :key="index">{{ item.value }}%</td>
                            </tr>
                        </table>
                        <div class="myChart" id='myChart22'></div>
                    </div>
                </div>
                <div class="first_grade flex-column-center" id="一年级" v-if="JSON.stringify(gradeOne) !== '{}'">
                    <div class="school">{{ schoolName }}一年级</div>
                    <div class="name">学生识写能力分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、总述</div>
                        <p>1.参与情况</p>
                        <p>
                            截止目前，本轮前测中，共计收到来自{{ schoolName }}一年级{{ gradeOne.dimension_f_6.count }}位同学提交的{{
                                gradeOne.dimension_f_6.result }}页试卷。
                            其中{{ tableData_one.max }}参与率最高,{{ tableData_one.min }}班参与率最低。
                        </p>
                        <MyTable type="join" :thead="['年级班级', '注册人数', '参与人数', '参与率']" :tableData="tableData_one.tabal">
                        </MyTable>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                        <p>2.书写习惯</p>
                        <MyTable type="wbzs_class" :thead="['年级', '握笔错误人数', '错误比例']" :tableData="tableData_one.tabal2" :tableAll="tableData_one.tabal">
                        </MyTable>
                        <MyTable type="zz_class" :thead="['年级', '坐姿错误人数', '错误比例']" :tableData="tableData_one.tabal2" :tableAll="tableData_one.tabal">
                        </MyTable>
                        <p>3.书写水平</p>
                        <!-- <p>(1)年级书写水平</p> -->
                        <p>针对前测样本数据综合分析四个书写基础维度得分,本年级学生在{{ chartOne_str }}{{ chartOne_count }}个维度低于全国平均水平</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>全国常模</th>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td v-if="gradeOne.complete_art">
                                    {{ gradeOne.complete_art.count == 0 ? 0 : (gradeOne.complete_art.result /
                                        gradeOne.complete_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeOne.complete_art.count == 0 ? 0 : (gradeOne.complete_art.result / gradeOne.complete_art.count).toFixed(2)) > 86">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeOne.dimension_c_4.count == 0 ? 0 : (gradeOne.dimension_c_4.result * 100 /
                                        gradeOne.dimension_c_4.count).toFixed(2) }}
                                </td>
                                <td>86</td>
                            </tr>
                            <tr>
                                <td>笔画形态</td>
                                <td v-if="gradeOne.layout_art">
                                    {{ gradeOne.layout_art.count == 0 ? 0 : (gradeOne.layout_art.result /
                                        gradeOne.layout_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeOne.layout_art.count == 0 ? 0 : (gradeOne.layout_art.result / gradeOne.layout_art.count).toFixed(2)) > 78">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeOne.dimension_d_4.count == 0 ? 0 : (gradeOne.dimension_d_4.result /
                                        gradeOne.dimension_d_4.count).toFixed(2) }}
                                </td>
                                <td>78</td>
                            </tr>
                            <tr>
                                <td>结构组合</td>
                                <td v-if="gradeOne.structure_art">
                                    {{ gradeOne.structure_art.count == 0 ? 0 : (gradeOne.structure_art.result /
                                        gradeOne.structure_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeOne.structure_art.count == 0 ? 0 : (gradeOne.structure_art.result / gradeOne.structure_art.count).toFixed(2)) > 75">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeOne.dimension_f_1.count == 0 ? 0 : (gradeOne.dimension_f_1.result /
                                        gradeOne.dimension_f_1.count).toFixed(2) }}
                                </td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <td>章法占格</td>
                                <td v-if="gradeOne.layout_art">
                                    {{ gradeOne.form_art.count == 0 ? 0 : (gradeOne.form_art.result /
                                        gradeOne.form_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeOne.form_art.count == 0 ? 0 : (gradeOne.form_art.result / gradeOne.form_art.count).toFixed(2)) > 76">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeOne.dimension_e_4.count == 0 ? 0 : (gradeOne.dimension_e_4.result /
                                        gradeOne.dimension_e_4.count).toFixed(2) }}
                                </td>
                                <td>76</td>
                            </tr>
                        </table>
                        <p v-if="badComment_first_grade_1">①　{{ badComment_first_grade_1 }}</p>
                        <p v-if="badComment_first_grade_2">②　{{ badComment_first_grade_2 }}</p>
                        <!-- <p>(2)班级书写水平</p>
                        <p v-for="(item, index) in badComment_one" :key="index">{{ item }}</p> -->
                        <template v-if="excellentUsers.first_grade.length > 0">
                            <p>4.优秀作品获得者,详细名单如下:</p>
                            <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.first_grade">
                            </MyTable>
                            <div style="font-size:14px">部分优秀作品实例:</div>
                            <div class="youxiu_works" v-for="(item, index) in excellentWork.first_grade"
                                :key="index + 'a'">
                                <div class="flex-align">
                                    <img :src="item.works[0]" alt="">
                                    <img :src="item.works[1]" alt="">
                                </div>
                                <span>作者:{{ item.userName }} {{ item.className }}</span>
                            </div>
                        </template>

                    </div>
                    <div class="part_two">
                        <div class="part_title">二、测评结果分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{ gradeOne.dimension_a_1.result }}人，占{{ (gradeOne.dimension_a_1.result *
                                100 / gradeOne.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeOne.dimension_a_2.result }}人，占{{ (gradeOne.dimension_a_2.result * 100 /
                                gradeOne.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeOne.dimension_a_3.result }}人，占{{ (gradeOne.dimension_a_3.result * 100 /
                                gradeOne.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeOne.dimension_a_4.result }}人，占{{ (gradeOne.dimension_a_4.result * 100 /
                                gradeOne.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{ gradeOne.dimension_b_1.result }}人，占{{ (gradeOne.dimension_b_1.result *
                                100 / gradeOne.dimension_b_1.count).toFixed(2) }}%；
                            坐姿2的有{{ gradeOne.dimension_b_2.result }}人，占{{ (gradeOne.dimension_b_2.result * 100 /
                                gradeOne.dimension_b_2.count).toFixed(2) }}%；
                            坐姿3的有{{ gradeOne.dimension_b_3.result }}人，占{{ (gradeOne.dimension_b_3.result * 100 /
                                gradeOne.dimension_b_3.count).toFixed(2) }}%；
                            坐姿4的有{{ gradeOne.dimension_b_4.result }}人，占{{ (gradeOne.dimension_b_4.result * 100 /
                                gradeOne.dimension_b_4.count).toFixed(2) }}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four"
                        v-if="gradeCommentList_all.filter(item => { return item.className == '一年级'; })[0].data.complete.length > 0">
                        <div class="items">（二）书写基础</div>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                        <div class="myChart" id="myChart"></div>
                        <p>作品完整度平均分为
                            {{ gradeOne.complete_art.count == 0 ? 0 : (gradeOne.complete_art.result /
                                gradeOne.complete_art.count).toFixed(2) }},
                            {{ gradeOne.complete_art.count == 0 ? 0 : (gradeOne.complete_art.result /
                                gradeOne.complete_art.count).toFixed(2) > 86 ? '高于' : '低于' }}
                            全国常模86。学生整体书写
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.complete[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.complete[0].advice }}
                        </p>
                        <p>笔画形态平均分为
                            {{ gradeOne.form_art.count == 0 ? 0 : (gradeOne.form_art.result /
                                gradeOne.form_art.count).toFixed(2) }},
                            {{ gradeOne.form_art.count == 0 ? 0 : (gradeOne.form_art.result /
                                gradeOne.form_art.count).toFixed(2) > 78 ? '高于' : '低于' }}
                            全国常模78,
                            从作品的书写笔话形态来看,
                            {{ gradeCommentList_all.filter(item => { return item.className == '一年级'; })[0].data.form[0].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '一年级';
                            })[0].data.form[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '一年级';
                            })[0].data.form[0].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '一年级'; })[0].data.form[1].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '一年级';
                            })[0].data.form[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '一年级';
                            })[0].data.form[1].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '一年级'; })[0].data.form[2].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '一年级';
                            })[0].data.form[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '一年级';
                            })[0].data.form[2].describe }}
                        </p>
                        <p>章法占格平均分为
                            {{ gradeOne.layout_art.count == 0 ? 0 : (gradeOne.layout_art.result /
                                gradeOne.layout_art.count).toFixed(2) }},
                            {{ gradeOne.layout_art.count == 0 ? 0 : (gradeOne.layout_art.result /
                                gradeOne.layout_art.count).toFixed(2) > 76 ? '高于' : '低于' }}
                            全国常模76,
                            从作品的章法占格来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.layout[2].describe }}
                        </p>
                        <p>结构组合平均分为
                            {{ gradeOne.structure_art.count == 0 ? 0 : (gradeOne.structure_art.result /
                                gradeOne.structure_art.count).toFixed(2) }},
                            {{ gradeOne.structure_art.count == 0 ? 0 : (gradeOne.structure_art.result /
                                gradeOne.structure_art.count).toFixed(2) > 75 ? '高于' : '低于' }}
                            全国常模75,
                            从作品的结构组合来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[2].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[3].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[3].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '一年级';
                            })[0].data.structure[3].describe }}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.拼音识写</div>
                        <img src="../../assets/report/1-1.png">
                        <p>通过“看拼音，补充笔画”，可以考察学生根据拼音和生字的轮廓认识生字的能力。本阶段学生初识汉字，根据新课标要求，需要具备拼音的学习及基础笔画的认识。</p>
                        <p>
                            通过本题测评，我们考察了“捺、横折、撇、竖、长横”笔画的补充书写，难度相对较小。平均正确率为{{ this.zqlTable.zql_q2.filter(item => {
                                return
                                item.name == '一年级'
                            })[0].value }}%，学生基本具备了从识字到基本笔画书写的基础。
                        </p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/report/chart.jpg">
                        <p>
                            通过“偏旁部首组合生字”，可以考察学生对基本部件的认识，及所整组合生字的认识。能够考察学生对“木字旁、穴字头、门字框、走之旁”等常用偏旁位置及组合规律的认识。通过测评发现，本题的平均正确率为{{
                                this.zqlTable.zql_q3.filter(item => {
                                    return
                                    item.name == '一年级'
                                })[0].value }}%。
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/report/strokes.jpg">
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '一年级'
                                })[0].value }}%。学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。
                        </p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次前测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{ gradeOne.dimension_a_1.result }}人，占{{ (gradeOne.dimension_a_1.result * 100 /
                                gradeOne.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeOne.dimension_a_2.result }}人，占{{ (gradeOne.dimension_a_2.result * 100 /
                                gradeOne.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeOne.dimension_a_3.result }}人，占{{ (gradeOne.dimension_a_3.result * 100 /
                                gradeOne.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeOne.dimension_a_4.result }}人，占{{ (gradeOne.dimension_a_4.result * 100 /
                                gradeOne.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <!-- <img src="../../assets/report/posture.jpg"> -->
                            <img src="../../assets/report/wobi.jpg">
                            <!-- <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span> -->
                        </div>
                        <div class="items">2.掌握基本笔画的写法</div>
                        <p>基础笔画，如“圆弧撇”、“长横”、“竖弯钩”等常用笔画，作为主笔时，所占比重都相对较重，所有基本笔画会影响到整体的书写效果。需要通过简单的字，进行重要笔画的基础练习。</p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>通过偏旁部首组字题，我们考察了不同偏旁部首的书写方式，低年级学生对于生字的组合关系与基本部件的掌握尤为重要。可通过课程学习，系统的积累每一类偏旁部首的书写能力。</p>
                        <div class="items">4.笔顺笔画的掌握</div>
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本卷中通过常见的易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '一年级'
                                })[0].value }}%。
                        </p>
                    </div>
                </div>
                <div class="second_grade flex-column-center" id="二年级" v-if="JSON.stringify(gradeTwo) !== '{}'">
                    <div class="school">{{ schoolName }}二年级</div>
                    <div class="name">学生识写能力分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、总述</div>
                        <p>1.参与情况</p>
                        <p>
                            截止目前，本轮前测中，共计收到来自{{ schoolName }}二年级{{ gradeTwo.dimension_f_6.count }}位同学提交的{{
                                gradeTwo.dimension_f_6.result }}页试卷。
                            其中{{ tableData_two.max }}参与率最高，{{ tableData_two.min }}参与率最低。
                        </p>
                        <MyTable type="join" :thead="['年级班级', '注册人数', '参与人数', '参与率']" :tableData="tableData_two.tabal">
                        </MyTable>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                        <p>2.书写习惯</p>
                        <MyTable type="wbzs_class" :thead="['年级', '握笔错误人数', '错误比例']" :tableData="tableData_two.tabal2"
                         :tableAll="tableData_two.tabal">
                        </MyTable>
                        <MyTable type="zz_class" :thead="['年级', '坐姿错误人数', '错误比例']" :tableData="tableData_two.tabal2"
                         :tableAll="tableData_two.tabal">
                        </MyTable>
                        <p>3.书写水平</p>
                        <!-- <p>(1)年级书写水平</p> -->
                        <p>针对前测样本数据综合分析四个书写基础维度得分,本年级学生在{{ chartTwo_str }}{{ chartTwo_count }}个维度低于全国平均水平</p>
                        <!-- <MyTable type="sxwd" :thead="['书写维度','考核要求','前测平均水平','全国常模']" :tableData="allInfo"></MyTable> -->
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>全国常模</th>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td v-if="gradeTwo.complete_art">
                                    {{ gradeTwo.complete_art.count == 0 ? 0 : (gradeTwo.complete_art.result /
                                        gradeTwo.complete_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeTwo.complete_art.count == 0 ? 0 : (gradeTwo.complete_art.result / gradeTwo.complete_art.count).toFixed(2)) > 86">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeTwo.dimension_c_4.count == 0 ? 0 : (gradeTwo.dimension_c_4.result * 100 /
                                        gradeTwo.dimension_c_4.count).toFixed(2) }}
                                </td>
                                <td>86</td>
                            </tr>
                            <tr>
                                <td>笔画形态</td>
                                <td v-if="gradeTwo.layout_art">
                                    {{ gradeTwo.form_art.count == 0 ? 0 : (gradeTwo.form_art.result /
                                        gradeTwo.form_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeTwo.form_art.count == 0 ? 0 : (gradeTwo.form_art.result / gradeTwo.form_art.count).toFixed(2)) > 78">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeTwo.dimension_d_4.count == 0 ? 0 : (gradeTwo.dimension_d_4.result /
                                        gradeTwo.dimension_d_4.count).toFixed(2) }}
                                </td>
                                <td>78</td>
                            </tr>
                            <tr>
                                <td>结构组合</td>
                                <td v-if="gradeTwo.structure_art">
                                    {{ gradeTwo.structure_art.count == 0 ? 0 : (gradeTwo.structure_art.result /
                                        gradeTwo.structure_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeTwo.structure_art.count == 0 ? 0 : (gradeTwo.structure_art.result / gradeTwo.structure_art.count).toFixed(2)) > 75">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeTwo.dimension_f_1.count == 0 ? 0 : (gradeTwo.dimension_f_1.result /
                                        gradeTwo.dimension_f_1.count).toFixed(2) }}
                                </td>
                                <td>75</td>
                            </tr>
                            <tr>
                                <td>章法占格</td>
                                <td v-if="gradeTwo.layout_art">
                                    {{ gradeTwo.layout_art.count == 0 ? 0 : (gradeTwo.layout_art.result /
                                        gradeTwo.layout_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeTwo.layout_art.count == 0 ? 0 : (gradeTwo.layout_art.result / gradeTwo.layout_art.count).toFixed(2)) > 76">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeTwo.dimension_e_4.count == 0 ? 0 : (gradeTwo.dimension_e_4.result /
                                        gradeTwo.dimension_e_4.count).toFixed(2) }}
                                </td>
                                <td>76</td>
                            </tr>
                        </table>
                        <p v-if="badComment_second_grade_1">①　{{ badComment_second_grade_1 }}</p>
                        <p v-if="badComment_second_grade_2">②　{{ badComment_second_grade_2 }}</p>
                        <!-- <p>(2)班级书写水平</p>
                        <p v-for="(item, index) in badComment_two" :key="index">{{ item }}</p> -->
                        <template v-if="excellentUsers.second_grade.length > 0">
                            <p>4.优秀作品获得者,详细名单如下:</p>
                            <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.second_grade">
                            </MyTable>
                            <div style="font-size:14px">部分优秀作品实例:</div>
                            <div class="youxiu_works" v-for="(item, index) in excellentWork.second_grade"
                                :key="index + 'a'">
                                <div class="flex-align">
                                    <img :src="item.works[0]" alt="">
                                    <img :src="item.works[1]" alt="">
                                </div>
                                <span>作者:{{ item.userName }} {{ item.className }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、测评结果分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{ gradeTwo.dimension_a_1.result }}人，占{{ (gradeTwo.dimension_a_1.result *
                                100 / gradeTwo.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeTwo.dimension_a_2.result }}人，占{{ (gradeTwo.dimension_a_2.result * 100 /
                                gradeTwo.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeTwo.dimension_a_3.result }}人，占{{ (gradeTwo.dimension_a_3.result * 100 /
                                gradeTwo.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeTwo.dimension_a_4.result }}人，占{{ (gradeTwo.dimension_a_4.result * 100 /
                                gradeTwo.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{ gradeTwo.dimension_b_1.result }}人，占{{ (gradeTwo.dimension_b_1.result *
                                100 / gradeTwo.dimension_b_1.count).toFixed(2) }}%；
                            坐姿2的有{{ gradeTwo.dimension_b_2.result }}人，占{{ (gradeTwo.dimension_b_2.result * 100 /
                                gradeTwo.dimension_b_2.count).toFixed(2) }}%；
                            坐姿3的有{{ gradeTwo.dimension_b_3.result }}人，占{{ (gradeTwo.dimension_b_3.result * 100 /
                                gradeTwo.dimension_b_3.count).toFixed(2) }}%；
                            坐姿4的有{{ gradeTwo.dimension_b_4.result }}人，占{{ (gradeTwo.dimension_b_4.result * 100 /
                                gradeTwo.dimension_b_4.count).toFixed(2) }}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four"
                        v-if="gradeCommentList_all.filter(item => { return item.className == '二年级'; })[0].data.complete.length > 0">
                        <div class="items">（二）书写基础</div>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                        <div class="myChart" id="myChart2"></div>
                        <p>作品完整度平均分为
                            {{ gradeTwo.complete_art.count == 0 ? 0 : (gradeTwo.complete_art.result /
                                gradeTwo.complete_art.count).toFixed(2) }},
                            {{ gradeTwo.complete_art.count == 0 ? 0 : (gradeTwo.complete_art.result /
                                gradeTwo.complete_art.count).toFixed(2) > 86 ? '高于' : '低于' }}
                            全国常模86。学生整体书写
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.complete[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.complete[0].advice }}
                        </p>
                        <p>笔画形态平均分为
                            {{ gradeTwo.form_art.count == 0 ? 0 : (gradeTwo.form_art.result /
                                gradeTwo.form_art.count).toFixed(2) }},
                            {{ gradeTwo.form_art.count == 0 ? 0 : (gradeTwo.form_art.result /
                                gradeTwo.form_art.count).toFixed(2) > 78 ? '高于' : '低于' }}
                            全国常模78,
                            从作品的书写笔话形态来看,
                            {{ gradeCommentList_all.filter(item => { return item.className == '二年级'; })[0].data.form[0].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '二年级';
                            })[0].data.form[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '二年级';
                            })[0].data.form[0].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '二年级'; })[0].data.form[1].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '二年级';
                            })[0].data.form[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '二年级';
                            })[0].data.form[1].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '二年级'; })[0].data.form[2].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '二年级';
                            })[0].data.form[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '二年级';
                            })[0].data.form[2].describe }}
                        </p>
                        <p>章法占格平均分为
                            {{ gradeTwo.layout_art.count == 0 ? 0 : (gradeTwo.layout_art.result /
                                gradeTwo.layout_art.count).toFixed(2) }},
                            {{ gradeTwo.layout_art.count == 0 ? 0 : (gradeTwo.layout_art.result /
                                gradeTwo.layout_art.count).toFixed(2) > 76 ? '高于' : '低于' }}
                            全国常模76,
                            从作品的章法占格来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.layout[2].describe }}
                        </p>
                        <p>结构组合平均分为
                            {{ gradeTwo.structure_art.count == 0 ? 0 : (gradeTwo.structure_art.result /
                                gradeTwo.structure_art.count).toFixed(2) }},
                            {{ gradeTwo.structure_art.count == 0 ? 0 : (gradeTwo.structure_art.result /
                                gradeTwo.structure_art.count).toFixed(2) > 75 ? '高于' : '低于' }}
                            全国常模75,
                            从作品的结构组合来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[2].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[3].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[3].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '二年级';
                            })[0].data.structure[3].describe }}
                        </p>
                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.拼音识写</div>
                        <img src="../../assets/report/2-1.png">
                        <p>通过“看拼音写汉字”，可以考察学生根据拼音和词语辨识生字的能力。通过本题测评发现，四组生字书写中，整体的平均正确率为
                            {{ this.zqlTable.zql_q2.filter(item => { return item.name == '二年级' })[0].value
                            }}%，学生基本具备了从识字到写字的基础准备。

                        </p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/report/2-2.png">
                        <p>
                            通过“偏旁部首组合生字”，可以考察学生对基本部件的认识，及所整组合生字的认识。能够考察学生对“心字底、火字旁、右耳刀”等常用偏旁位置及组合规律的认识。通过测评发现，本题的平均正确率为{{
                                this.zqlTable.zql_q3.filter(item => {
                                    return
                                    item.name == '二年级'
                                })[0].value }}%。
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/report/2-3.png">
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '二年级'
                                })[0].value }}%。学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。
                        </p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次前测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{ gradeTwo.dimension_a_1.result }}人，占{{ (gradeTwo.dimension_a_1.result * 100 /
                                gradeTwo.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeTwo.dimension_a_2.result }}人，占{{ (gradeTwo.dimension_a_2.result * 100 /
                                gradeTwo.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeTwo.dimension_a_3.result }}人，占{{ (gradeTwo.dimension_a_3.result * 100 /
                                gradeTwo.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeTwo.dimension_a_4.result }}人，占{{ (gradeTwo.dimension_a_4.result * 100 /
                                gradeTwo.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/wobi.jpg">
                            <!-- <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span> -->
                        </div>
                        <div class="items">2.掌握基本笔画的写法</div>
                        <p> 基础笔画，如“撇”、“卧钩”、“竖弯钩”等常用笔画，作为主笔时，所占比重都相对较重，所有基本笔画会影响到整体的书写效果。需要通过简单的字，进行重要笔画的基础练习。</p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>
                            通过偏旁部首组字等题目，我们考察不同偏旁部首的书写“心字底、火字旁、右耳刀”等的书写，本年级此题的平均正确率为{{ this.zqlTable.zql_q3.filter(item => {
                                return
                                item.name == '二年级'
                            })[0].value }}%。
                            可以通过课程学习，逐渐积累每一类偏旁部首的书写能力。</p>
                        <div class="items">4.独体结构理解</div>
                        <p>
                            本年级大量书写了独体结构的字，如“言、毛、川、为、出、火、竹、虫、多、心、我”等，独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。
                        </p>
                    </div>
                </div>
                <div class="third_grade flex-column-center" id="三年级" v-if="JSON.stringify(gradeThree) !== '{}'">
                    <div class="school">{{ schoolName }}三年级</div>
                    <div class="name">学生识写能力分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、总述</div>
                        <p>
                            截止目前，本轮前测中，共计收到来自{{schoolName}}三年级{{tableData_three.totalNum}}位同学提交的{{tableData_three.totalPage}}页试卷。
                            其中{{tableData_three.max}}参与率最高，{{tableData_three.min}}参与率最低。
                        </p>
                        <MyTable type="join" :thead="['年级班级', '注册人数', '参与人数', '参与率']"
                            :tableData="tableData_three.tabal">
                        </MyTable>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                        <p>2.书写习惯</p>
                        <MyTable type="wbzs_class" :thead="['年级', '握笔错误人数', '错误比例']"
                            :tableData="tableData_three.tabal2" :tableAll="tableData_three.tabal">
                        </MyTable>
                        <MyTable type="zz_class" :thead="['年级', '坐姿错误人数', '错误比例']"
                            :tableData="tableData_three.tabal2" :tableAll="tableData_three.tabal">
                        </MyTable>
                        <p>3.书写水平</p>
                        <!-- <p>(1)年级书写水平</p> -->
                        <p>针对前测样本数据综合分析四个书写基础维度得分,本年级学生在{{ chartThree_str }}{{ chartThree_count }}个维度低于全国平均水平</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>全国常模</th>
                            </tr>

                            <tr>
                                <td>作品完整度</td>
                                <td v-if="gradeThree.complete_art">
                                    {{ gradeThree.complete_art.count == 0 ? 0 : (gradeThree.complete_art.result /
                                        gradeThree.complete_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeThree.complete_art.count == 0 ? 0 : (gradeThree.complete_art.result / gradeThree.complete_art.count).toFixed(2)) > 90">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeThree.dimension_c_4.count == 0 ? 0 : (gradeThree.dimension_c_4.result * 100 /
                                        gradeThree.dimension_c_4.count).toFixed(2) }}
                                </td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>笔画形态</td>
                                <td v-if="gradeThree.form_art">
                                    {{ gradeThree.form_art.count == 0 ? 0 : (gradeThree.form_art.result /
                                        gradeThree.form_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeThree.form_art.count == 0 ? 0 : (gradeThree.form_art.result / gradeThree.form_art.count).toFixed(2)) > 83">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeThree.dimension_d_4.count == 0 ? 0 : (gradeThree.dimension_d_4.result /
                                        gradeThree.dimension_d_4.count).toFixed(2) }}
                                </td>
                                <td>83</td>
                            </tr>
                            <tr>
                                <td>结构组合</td>
                                <td v-if="gradeThree.structure_art">
                                    {{ gradeThree.structure_art.count == 0 ? 0 : (gradeThree.structure_art.result /
                                        gradeThree.structure_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeThree.structure_art.count == 0 ? 0 : (gradeThree.structure_art.result / gradeThree.structure_art.count).toFixed(2)) > 78">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeThree.dimension_f_1.count == 0 ? 0 : (gradeThree.dimension_f_1.result /
                                        gradeThree.dimension_f_1.count).toFixed(2) }}
                                </td>
                                <td>78</td>
                            </tr>
                            <tr>
                                <td>章法占格</td>
                                <td v-if="gradeThree.layout_art">
                                    {{ gradeThree.layout_art.count == 0 ? 0 : (gradeThree.layout_art.result /
                                        gradeThree.layout_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeThree.layout_art.count == 0 ? 0 : (gradeThree.layout_art.result / gradeThree.layout_art.count).toFixed(2)) > 79">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeThree.dimension_e_4.count == 0 ? 0 : (gradeThree.dimension_e_4.result /
                                        gradeThree.dimension_e_4.count).toFixed(2) }}
                                </td>
                                <td>79</td>
                            </tr>
                        </table>
                        <p v-if="badComment_third_grade_1">①　{{ badComment_third_grade_1 }}</p>
                        <p v-if="badComment_third_grade_2">②　{{ badComment_third_grade_2 }}</p>
                        <!-- <p>(2)班级书写水平</p>
                        <p v-for="(item, index) in badComment_three" :key="index">{{ item }}</p> -->
                        <template v-if="excellentUsers.third_grade.length > 0">
                            <p>4.优秀作品获得者,详细名单如下:</p>
                            <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.third_grade">
                            </MyTable>
                            <div style="font-size:14px">部分优秀作品实例:</div>
                            <div class="youxiu_works" v-for="(item, index) in excellentWork.third_grade"
                                :key="index + 'a'">
                                <div class="flex-align">
                                    <img :src="item.works[0]" alt="">
                                    <img :src="item.works[1]" alt="">
                                </div>
                                <span>作者:{{ item.userName }} {{ item.className }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、测评结果分析</div>
                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{ gradeThree.dimension_a_1.result }}人，占{{ (gradeThree.dimension_a_1.result
                                * 100 / gradeThree.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeThree.dimension_a_2.result }}人，占{{ (gradeThree.dimension_a_2.result * 100 /
                                gradeThree.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeThree.dimension_a_3.result }}人，占{{ (gradeThree.dimension_a_3.result * 100 /
                                gradeThree.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeThree.dimension_a_4.result }}人，占{{ (gradeThree.dimension_a_4.result * 100 /
                                gradeThree.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{ gradeThree.dimension_b_1.result }}人，占{{
                                (gradeThree.dimension_b_1.result * 100 / gradeThree.dimension_b_1.count).toFixed(2) }}%；
                            坐姿2的有{{ gradeThree.dimension_b_2.result }}人，占{{ (gradeThree.dimension_b_2.result * 100 /
                                gradeThree.dimension_b_2.count).toFixed(2) }}%；
                            坐姿3的有{{ gradeThree.dimension_b_3.result }}人，占{{ (gradeThree.dimension_b_3.result * 100 /
                                gradeThree.dimension_b_3.count).toFixed(2) }}%；
                            坐姿4的有{{ gradeThree.dimension_b_4.result }}人，占{{ (gradeThree.dimension_b_4.result * 100 /
                                gradeThree.dimension_b_4.count).toFixed(2) }}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four"
                        v-if="gradeCommentList_all.filter(item => { return item.className == '三年级'; })[0].data.complete.length > 0">
                        <div class="items">（二）书写基础</div>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                        <div class="myChart" id="myChart3"></div>
                        <p>作品完整度平均分为
                            {{ gradeThree.complete_art.count == 0 ? 0 : (gradeThree.complete_art.result /
                                gradeThree.complete_art.count).toFixed(2) }},
                            {{ gradeThree.complete_art.count == 0 ? 0 : (gradeThree.complete_art.result /
                                gradeThree.complete_art.count).toFixed(2) > 90 ? '高于' : '低于' }}
                            全国常模90。学生整体书写
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.complete[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.complete[0].advice }}
                        </p>
                        <p>笔画形态平均分为
                            {{ gradeThree.form_art.count == 0 ? 0 : (gradeThree.form_art.result /
                                gradeThree.form_art.count).toFixed(2) }},
                            {{ gradeThree.form_art.count == 0 ? 0 : (gradeThree.form_art.result /
                                gradeThree.form_art.count).toFixed(2) > 83 ? '高于' : '低于' }}
                            全国常模83,
                            从作品的书写笔话形态来看,
                            {{ gradeCommentList_all.filter(item => { return item.className == '三年级'; })[0].data.form[0].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '三年级';
                            })[0].data.form[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '三年级';
                            })[0].data.form[0].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '三年级'; })[0].data.form[1].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '三年级';
                            })[0].data.form[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '三年级';
                            })[0].data.form[1].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '三年级'; })[0].data.form[2].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '三年级';
                            })[0].data.form[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '三年级';
                            })[0].data.form[2].describe }}
                        </p>
                        <p>章法占格平均分为
                            {{ gradeThree.layout_art.count == 0 ? 0 : (gradeThree.layout_art.result /
                                gradeThree.layout_art.count).toFixed(2) }},
                            {{ gradeThree.layout_art.count == 0 ? 0 : (gradeThree.layout_art.result /
                                gradeThree.layout_art.count).toFixed(2) > 79 ? '高于' : '低于' }}

                            全国常模79,
                            从作品的章法占格来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.layout[2].describe }}
                        </p>
                        <p>结构组合平均分为
                            {{ gradeThree.structure_art.count == 0 ? 0 : (gradeThree.structure_art.result /
                                gradeThree.structure_art.count).toFixed(2) }},
                            {{ gradeThree.structure_art.count == 0 ? 0 : (gradeThree.structure_art.result /
                                gradeThree.structure_art.count).toFixed(2) > 78 ? '高于' : '低于' }}
                            全国常模78,
                            从作品的结构组合来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[2].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[3].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[3].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '三年级';
                            })[0].data.structure[3].describe }}
                        </p>
                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.补充成语</div>
                        <img src="../../assets/report/3-1.png">
                        <p>
                            通过设置语境或补充成语的方式，我们主要考察学生对生字的应用。通过本题测评发现，六组成语补充书写中，学生的平均正确率为{{ this.zqlTable.zql_q2.filter(item => {
                                return
                                item.name == '三年级'
                            })[0].value }}%，整体还是不错的。
                            但是部分同学出现涂改，对整体卷面整洁会有一些影响，建议可在下笔之前做好对比和选择，确保书写正确又美观。</p>
                        <div class="items">2.结构认知</div>
                        <img src="../../assets/report/3-2.png">
                        <p>
                            通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。经过统计，按结构分类的整体平均正确率为{{
                                this.zqlTable.zql_q3.filter(item => {
                                    return
                                    item.name == '三年级'
                                })[0].value }}%。
                            需要注意日常生字间架结构书写特点的理解与记忆，同时容易混淆的结构，需要着重加强。</p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/report/3-3.png">
                        <p>
                            笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '三年级'
                                })[0].value }}%。
                            学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次前测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{ gradeThree.dimension_a_1.result }}人，占{{ (gradeThree.dimension_a_1.result * 100 /
                                gradeThree.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeThree.dimension_a_2.result }}人，占{{ (gradeThree.dimension_a_2.result * 100 /
                                gradeThree.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeThree.dimension_a_3.result }}人，占{{ (gradeThree.dimension_a_3.result * 100 /
                                gradeThree.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeThree.dimension_a_4.result }}人，占{{ (gradeThree.dimension_a_4.result * 100 /
                                gradeThree.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/wobi.jpg">
                            <!-- <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span> -->
                        </div>
                        <div class="items">2.掌握基本笔画的写法</div>
                        <p>通过设置语境补充成语的方式，考察学生对生字的应用及字义的掌握。本次调查，六组成语补充书写中，学生的平均正确率为{{ this.zqlTable.zql_q2.filter(item => {
                            return
                            item.name == '三年级'
                        })[0].value }}%。
                            本年级学生对于成语的应用整体较好，请继续保持。</p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>通过偏旁部首组字等题目，我们考察不同偏旁部首的书写“心字底、火字旁、右耳刀”等的书写，可以通过课程学习，逐渐积累每一类偏旁部首的书写能力。</p>
                        <div class="items">4.汉字结构理解</div>
                        <p>通过考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分尤为重要，本卷通过以下汉字选择“群、抓、温、冒、背、花、围、道、问、成、及、母”等生字进行考察。经过统计
                            ，按结构分类的整体平均正确率为{{ this.zqlTable.zql_q3.filter(item => {
                                return
                                item.name == '三年级'
                            })[0].value }}%；学生还需要进一步掌握对不同结构的字的理解：
                        </p>
                        <p>1）包围结构字形，在日常书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。</p>
                        <p>2）左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上。</p>
                        <p>3）独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。</p>
                        <p>4）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。
                        </p>
                    </div>
                </div>
                <div class="fourth_grade flex-column-center" id="四年级" v-if="JSON.stringify(gradeFour) !== '{}'">
                    <div class="school">{{ schoolName }}四年级</div>
                    <div class="name">学生识写能力分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、总述</div>
                        <p>
                            截止目前，本轮前测中，共计收到来自{{schoolName}}四年级{{tableData_four.totalNum}}位同学提交的{{tableData_four.totalPage}}页试卷。
                            其中{{tableData_four.max}}参与率最高，{{tableData_four.min}}参与率最低。
                        </p>
                        <MyTable type="join" :thead="['年级班级', '注册人数', '参与人数', '参与率']" :tableData="tableData_four.tabal">
                        </MyTable>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                        <p>2.书写习惯</p>
                        <MyTable type="wbzs_class" :thead="['年级', '握笔错误人数', '错误比例']" :tableData="tableData_four.tabal2" :tableAll="tableData_four.tabal">
                        </MyTable>
                        <MyTable type="zz_class" :thead="['年级', '坐姿错误人数', '错误比例']" :tableData="tableData_four.tabal2" :tableAll="tableData_four.tabal">
                        </MyTable>
                        <p>3.书写水平</p>
                        <!-- <p>(1)年级书写水平</p> -->
                        <p>针对前测样本数据综合分析四个书写基础维度得分,本年级学生在{{ chartFour_str }}{{ chartFour_count }}个维度低于全国平均水平</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>全国常模</th>
                            </tr>

                            <tr>
                                <td>作品完整度</td>
                                <td v-if="gradeFour.complete_art">
                                    {{ gradeFour.complete_art.count == 0 ? 0 : (gradeFour.complete_art.result /
                                        gradeFour.complete_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFour.complete_art.count == 0 ? 0 : (gradeFour.complete_art.result / gradeFour.complete_art.count).toFixed(2)) > 90">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFour.dimension_c_4.count == 0 ? 0 : (gradeFour.dimension_c_4.result * 100 /
                                        gradeFour.dimension_c_4.count).toFixed(2) }}
                                </td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>笔画形态</td>
                                <td v-if="gradeFour.form_art">
                                    {{ gradeFour.form_art.count == 0 ? 0 : (gradeFour.form_art.result /
                                        gradeFour.form_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFour.form_art.count == 0 ? 0 : (gradeFour.form_art.result / gradeFour.form_art.count).toFixed(2)) > 83">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFour.dimension_d_4.count == 0 ? 0 : (gradeFour.dimension_d_4.result /
                                        gradeFour.dimension_d_4.count).toFixed(2) }}
                                </td>
                                <td>83</td>
                            </tr>
                            <tr>
                                <td>结构组合</td>
                                <td v-if="gradeFour.structure_art">
                                    {{ gradeFour.structure_art.count == 0 ? 0 : (gradeFour.structure_art.result /
                                        gradeFour.structure_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFour.structure_art.count == 0 ? 0 : (gradeFour.structure_art.result / gradeFour.structure_art.count).toFixed(2)) > 78">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFour.dimension_f_1.count == 0 ? 0 : (gradeFour.dimension_f_1.result /
                                        gradeFour.dimension_f_1.count).toFixed(2) }}
                                </td>
                                <td>78</td>
                            </tr>
                            <tr>
                                <td>章法占格</td>
                                <td v-if="gradeFour.layout_art">
                                    {{ gradeFour.layout_art.count == 0 ? 0 : (gradeFour.layout_art.result /
                                        gradeFour.layout_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFour.layout_art.count == 0 ? 0 : (gradeFour.layout_art.result / gradeFour.layout_art.count).toFixed(2)) > 79">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFour.dimension_e_4.count == 0 ? 0 : (gradeFour.dimension_e_4.result /
                                        gradeFour.dimension_e_4.count).toFixed(2) }}
                                </td>
                                <td>79</td>
                            </tr>
                        </table>
                        <p v-if="badComment_fourth_grade_1">①　{{ badComment_fourth_grade_1 }}</p>
                        <p v-if="badComment_fourth_grade_2">②　{{ badComment_fourth_grade_2 }}</p>
                        <!-- <p>(2)班级书写水平</p>
                        <p v-for="(item, index) in badComment_four" :key="index">{{ item }}</p> -->
                        <template v-if="excellentUsers.fourth_grade.length > 0">
                            <p>4.优秀作品获得者,详细名单如下:</p>
                            <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.fourth_grade">
                            </MyTable>
                            <div style="font-size:14px">部分优秀作品实例:</div>
                            <div class="youxiu_works" v-for="(item, index) in excellentWork.fourth_grade"
                                :key="index + 'a'">
                                <div class="flex-align">
                                    <img :src="item.works[0]" alt="">
                                    <img :src="item.works[1]" alt="">
                                </div>
                                <span>作者:{{ item.userName }} {{ item.className }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、测评结果分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{ gradeFour.dimension_a_1.result }}人，占{{ (gradeFour.dimension_a_1.result *
                                100 / gradeFour.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeFour.dimension_a_2.result }}人，占{{ (gradeFour.dimension_a_2.result * 100 /
                                gradeFour.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeFour.dimension_a_3.result }}人，占{{ (gradeFour.dimension_a_3.result * 100 /
                                gradeFour.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeFour.dimension_a_4.result }}人，占{{ (gradeFour.dimension_a_4.result * 100 /
                                gradeFour.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{ gradeFour.dimension_b_1.result }}人，占{{ (gradeFour.dimension_b_1.result
                                * 100 / gradeFour.dimension_b_1.count).toFixed(2) }}%；
                            坐姿2的有{{ gradeFour.dimension_b_2.result }}人，占{{ (gradeFour.dimension_b_2.result * 100 /
                                gradeFour.dimension_b_2.count).toFixed(2) }}%；
                            坐姿3的有{{ gradeFour.dimension_b_3.result }}人，占{{ (gradeFour.dimension_b_3.result * 100 /
                                gradeFour.dimension_b_3.count).toFixed(2) }}%；
                            坐姿4的有{{ gradeFour.dimension_b_4.result }}人，占{{ (gradeFour.dimension_b_4.result * 100 /
                                gradeFour.dimension_b_4.count).toFixed(2) }}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four"
                        v-if="gradeCommentList_all.filter(item => { return item.className == '四年级'; })[0].data.complete.length > 0">
                        <div class="items">（二）书写基础</div>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                        <div class="myChart" id="myChart4"></div>
                        <p>作品完整度平均分为
                            {{ gradeFour.complete_art.count == 0 ? 0 : (gradeFour.complete_art.result /
                                gradeFour.complete_art.count).toFixed(2) }},
                            {{ gradeFour.complete_art.count == 0 ? 0 : (gradeFour.complete_art.result /
                                gradeFour.complete_art.count).toFixed(2) > 90 ? '高于' : '低于' }}
                            全国常模90。学生整体书写
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.complete[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.complete[0].advice }}
                        </p>
                        <p>笔画形态平均分为
                            {{ gradeFour.form_art.count == 0 ? 0 : (gradeFour.form_art.result /
                                gradeFour.form_art.count).toFixed(2) }},
                            {{ gradeFour.form_art.count == 0 ? 0 : (gradeFour.form_art.result /
                                gradeFour.form_art.count).toFixed(2) > 83 ? '高于' : '低于' }}
                            全国常模83,
                            从作品的书写笔话形态来看,
                            {{ gradeCommentList_all.filter(item => { return item.className == '四年级'; })[0].data.form[0].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '四年级';
                            })[0].data.form[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '四年级';
                            })[0].data.form[0].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '四年级'; })[0].data.form[1].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '四年级';
                            })[0].data.form[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '四年级';
                            })[0].data.form[1].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '四年级'; })[0].data.form[2].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '四年级';
                            })[0].data.form[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '四年级';
                            })[0].data.form[2].describe }}
                        </p>
                        <p>章法占格平均分为
                            {{ gradeFour.layout_art.count == 0 ? 0 : (gradeFour.layout_art.result /
                                gradeFour.layout_art.count).toFixed(2) }},
                            {{ gradeFour.layout_art.count == 0 ? 0 : (gradeFour.layout_art.result /
                                gradeFour.layout_art.count).toFixed(2) > 79 ? '高于' : '低于' }}

                            全国常模79,
                            从作品的章法占格来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.layout[2].describe }}
                        </p>
                        <p>结构组合平均分为
                            {{ gradeFour.structure_art.count == 0 ? 0 : (gradeFour.structure_art.result /
                                gradeFour.structure_art.count).toFixed(2) }},
                            {{ gradeFour.structure_art.count == 0 ? 0 : (gradeFour.structure_art.result /
                                gradeFour.structure_art.count).toFixed(2) > 78 ? '高于' : '低于' }}
                            全国常模78,
                            从作品的结构组合来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[2].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[3].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[3].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '四年级';
                            })[0].data.structure[3].describe }}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.成语补充</div>
                        <img src="../../assets/report/4-1.png">
                        <p>
                            通过设置语境或补充成语的方式，我们主要考察学生对生字的应用及字义的掌握。通过本题考察，本年级学生的平均正确率为{{ this.zqlTable.zql_q2.filter(item => {
                                return
                                item.name == '四年级'
                            })[0].value }}%，整体还是不错的。
                        </p>

                        <div class="items">2.结构认知</div>
                        <img src="../../assets/report/4-2.png">
                        <p>通过“按结构分类”题目，可以考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分与认识。经过统计，
                            本题内容的平均正确率为{{ this.zqlTable.zql_q3.filter(item => {
                                return
                                item.name == '四年级'
                            })[0].value }}%。需要注意日常生字间架结构书写特点的理解与记忆。但是结构认知中，也存在一些容易混淆的生字，请着重加强。
                        </p>
                        <div class="items">3.笔顺笔画</div>
                        <img src="../../assets/report/4-3.png">
                        <p>
                            笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '四年级'
                                })[0].value }}%。
                            学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次前测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{ gradeFour.dimension_a_1.result }}人，占{{ (gradeFour.dimension_a_1.result * 100 /
                                gradeFour.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeFour.dimension_a_2.result }}人，占{{ (gradeFour.dimension_a_2.result * 100 /
                                gradeFour.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeFour.dimension_a_3.result }}人，占{{ (gradeFour.dimension_a_3.result * 100 /
                                gradeFour.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeFour.dimension_a_4.result }}人，占{{ (gradeFour.dimension_a_4.result * 100 /
                                gradeFour.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/wobi.jpg">
                            <!-- <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span> -->
                        </div>
                        <div class="items">2.掌握汉字含义与应用</div>
                        <p>
                            通过设置补充成语的方式，考察学生对生字的应用及字义的掌握。本次调查，本年级学生对于成语的应用整体较好，本年级学生的平均正确率为{{
                                this.zqlTable.zql_q2.filter(item => {
                                    return
                                    item.name == '四年级'
                                })[0].value }}%，请继续保持。
                        </p>
                        <div class="items">3.掌握偏旁部首的写法</div>
                        <p>通过设置语境或补充成语的方式，我们主要考察学生对生字的应用及字义的掌握。同时我们考察了不同偏旁部首的书写方式，学生对于生字的组合关系与基本部件的掌握尤为重要。可通过课程学习，系统的积累每一类偏旁部首的书写能力。
                        </p>
                        <div class="items">4.笔顺笔画的掌握</div>
                        <p>
                            通过考察学生对结构的认识，包括“左右结构、上下结构、包围结构、独体字”等四类结构的区分尤为重要，本卷通过以下汉字选择“堵顿跃 哲占劈 固凤阔 丈穴卫”进行考察。经过统计，
                            本题内容的平均正确率为{{ (gradeFour.dimension_c_3.result * 100 / gradeFour.dimension_c_3.count).toFixed(2)
                            }}%。学生还需要进一步掌握对不同结构的字的理解：
                        </p>
                        <p>1）包围结构字形，在日常书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。</p>
                        <p>2）左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上。</p>
                        <p>3）独体结构的字形由于笔画数相对较少，部分复合笔画在单字中伸展、突出且书写难度偏大，因此在独体字的结构稳定度以及笔画与笔画之间位置关系处理上难度较大。</p>
                        <p>4）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握。
                        </p>

                    </div>
                </div>
                <div class="fifth_grade flex-column-center" id="五年级" v-if="JSON.stringify(gradeFive) !== '{}'">
                    <div class="school">{{ schoolName }}五年级</div>
                    <div class="name">学生识写能力分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、总述</div>
                        <p>
                            截止目前，本轮前测中，共计收到来自{{schoolName}}五年级{{tableData_five.totalNum}}位同学提交的{{tableData_five.totalPage}}页试卷。
                            其中{{tableData_five.max}}参与率最高，{{tableData_five.min}}参与率最低。
                        </p>
                        <MyTable type="join" :thead="['年级班级', '注册人数', '参与人数', '参与率']" :tableData="tableData_five.tabal">
                        </MyTable>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                        <p>2.书写习惯</p>
                        <MyTable type="wbzs_class" :thead="['年级', '握笔错误人数', '错误比例']" :tableData="tableData_five.tabal2" :tableAll="tableData_five.tabal">
                        </MyTable>
                        <MyTable type="zz_class" :thead="['年级', '坐姿错误人数', '错误比例']" :tableData="tableData_five.tabal2" :tableAll="tableData_five.tabal">
                        </MyTable>
                        <p>3.书写水平</p>
                        <!-- <p>(1)年级书写水平</p> -->
                        <p>针对前测样本数据综合分析四个书写基础维度得分,本年级学生在{{ chartFive_str }}{{ chartFive_count }}个维度低于全国平均水平</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>全国常模</th>
                            </tr>

                            <tr>
                                <td>作品完整度</td>
                                <td v-if="gradeFive.complete_art">
                                    {{ gradeFive.complete_art.count == 0 ? 0 : (gradeFive.complete_art.result /
                                        gradeFive.complete_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFive.complete_art.count == 0 ? 0 : (gradeFive.complete_art.result / gradeFive.complete_art.count).toFixed(2)) > 92">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFive.dimension_c_4.count == 0 ? 0 : (gradeFive.dimension_c_4.result * 100 /
                                        gradeFive.dimension_c_4.count).toFixed(2) }}
                                </td>
                                <td>92</td>
                            </tr>
                            <tr>
                                <td>笔画形态</td>
                                <td v-if="gradeFive.form_art">
                                    {{ gradeFive.form_art.count == 0 ? 0 : (gradeFive.form_art.result /
                                        gradeFive.form_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFive.form_art.count == 0 ? 0 : (gradeFive.form_art.result / gradeFive.form_art.count).toFixed(2)) > 85">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFive.dimension_d_4.count == 0 ? 0 : (gradeFive.dimension_d_4.result /
                                        gradeFive.dimension_d_4.count).toFixed(2) }}
                                </td>
                                <td>85</td>
                            </tr>
                            <tr>
                                <td>结构组合</td>
                                <td v-if="gradeFive.structure_art">
                                    {{ gradeFive.structure_art.count == 0 ? 0 : (gradeFive.structure_art.result /
                                        gradeFive.structure_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFive.structure_art.count == 0 ? 0 : (gradeFive.structure_art.result / gradeFive.structure_art.count).toFixed(2)) > 81">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFive.dimension_f_1.count == 0 ? 0 : (gradeFive.dimension_f_1.result /
                                        gradeFive.dimension_f_1.count).toFixed(2) }}
                                </td>
                                <td>81</td>
                            </tr>
                            <tr>
                                <td>章法占格</td>
                                <td v-if="gradeFive.layout_art">
                                    {{ gradeFive.layout_art.count == 0 ? 0 : (gradeFive.layout_art.result /
                                        gradeFive.layout_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeFive.layout_art.count == 0 ? 0 : (gradeFive.layout_art.result / gradeFive.layout_art.count).toFixed(2)) > 82">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeFive.dimension_e_4.count == 0 ? 0 : (gradeFive.dimension_e_4.result /
                                        gradeFive.dimension_e_4.count).toFixed(2) }}
                                </td>
                                <td>82</td>
                            </tr>
                        </table>
                        <p v-if="badComment_fifth_grade_1">①　{{ badComment_fifth_grade_1 }}</p>
                        <p v-if="badComment_fifth_grade_1">②　{{ badComment_fifth_grade_2 }}</p>
                        <!-- <p>(2)班级书写水平</p>
                        <p v-for="(item, index) in badComment_five" :key="index">{{ item }}</p> -->
                        <template v-if="excellentUsers.fifth_grade.length > 0">
                            <p>4.优秀作品获得者,详细名单如下:</p>
                            <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.fifth_grade">
                            </MyTable>
                            <div style="font-size:14px">部分优秀作品实例:</div>
                            <div class="youxiu_works" v-for="(item, index) in excellentWork.fifth_grade"
                                :key="index + 'a'">
                                <div class="flex-align">
                                    <img :src="item.works[0]" alt="">
                                    <img :src="item.works[1]" alt="">
                                </div>
                                <span>作者:{{ item.userName }} {{ item.className }}</span>
                            </div>
                        </template>

                    </div>
                    <div class="part_two">
                        <div class="part_title">二、测评结果分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{ gradeFive.dimension_a_1.result }}人，占{{ (gradeFive.dimension_a_1.result *
                                100 / gradeFive.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeFive.dimension_a_2.result }}人，占{{ (gradeFive.dimension_a_2.result * 100 /
                                gradeFive.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeFive.dimension_a_3.result }}人，占{{ (gradeFive.dimension_a_3.result * 100 /
                                gradeFive.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeFive.dimension_a_4.result }}人，占{{ (gradeFive.dimension_a_4.result * 100 /
                                gradeFive.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{ gradeFive.dimension_b_1.result }}人，占{{ (gradeFive.dimension_b_1.result
                                * 100 / gradeFive.dimension_b_1.count).toFixed(2) }}%；
                            坐姿2的有{{ gradeFive.dimension_b_2.result }}人，占{{ (gradeFive.dimension_b_2.result * 100 /
                                gradeFive.dimension_b_2.count).toFixed(2) }}%；
                            坐姿3的有{{ gradeFive.dimension_b_3.result }}人，占{{ (gradeFive.dimension_b_3.result * 100 /
                                gradeFive.dimension_b_3.count).toFixed(2) }}%；
                            坐姿4的有{{ gradeFive.dimension_b_4.result }}人，占{{ (gradeFive.dimension_b_4.result * 100 /
                                gradeFive.dimension_b_4.count).toFixed(2) }}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four"
                        v-if="gradeCommentList_all.filter(item => { return item.className == '五年级'; })[0].data.complete.length > 0">
                        <div class="items">（二）书写基础</div>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                        <div class="myChart" id="myChart5"></div>
                        <p>作品完整度平均分为
                            {{ gradeFive.complete_art.count == 0 ? 0 : (gradeFive.complete_art.result /
                                gradeFive.complete_art.count).toFixed(2) }},
                            {{ gradeFive.complete_art.count == 0 ? 0 : (gradeFive.complete_art.result /
                                gradeFive.complete_art.count).toFixed(2) > 92 ? '高于' : '低于' }}
                            全国常模92。学生整体书写
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.complete[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.complete[0].advice }}
                        </p>
                        <p>笔画形态平均分为
                            {{ gradeFive.form_art.count == 0 ? 0 : (gradeFive.form_art.result /
                                gradeFive.form_art.count).toFixed(2) }},
                            {{ gradeFive.form_art.count == 0 ? 0 : (gradeFive.form_art.result /
                                gradeFive.form_art.count).toFixed(2) > 85 ? '高于' : '低于' }}
                            全国常模85,
                            从作品的书写笔话形态来看,
                            {{ gradeCommentList_all.filter(item => { return item.className == '五年级'; })[0].data.form[0].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '五年级';
                            })[0].data.form[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '五年级';
                            })[0].data.form[0].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '五年级'; })[0].data.form[1].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '五年级';
                            })[0].data.form[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '五年级';
                            })[0].data.form[1].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '五年级'; })[0].data.form[2].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '五年级';
                            })[0].data.form[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '五年级';
                            })[0].data.form[2].describe }}
                        </p>
                        <p>章法占格平均分为
                            {{ gradeFive.layout_art.count == 0 ? 0 : (gradeFive.layout_art.result /
                                gradeFive.layout_art.count).toFixed(2) }},
                            {{ gradeFive.layout_art.count == 0 ? 0 : (gradeFive.layout_art.result /
                                gradeFive.layout_art.count).toFixed(2) > 82 ? '高于' : '低于' }}

                            全国常模82,
                            从作品的章法占格来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.layout[2].describe }}
                        </p>
                        <p>结构组合平均分为
                            {{ gradeFive.structure_art.count == 0 ? 0 : (gradeFive.structure_art.result /
                                gradeFive.structure_art.count).toFixed(2) }},
                            {{ gradeFive.structure_art.count == 0 ? 0 : (gradeFive.structure_art.result /
                                gradeFive.structure_art.count).toFixed(2) > 81 ? '高于' : '低于' }}
                            全国常模81,
                            从作品的结构组合来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[2].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[3].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[3].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '五年级';
                            })[0].data.structure[3].describe }}
                        </p>
                    </div>
                    <div class="part_three">
                        <div class="items">（三）书写辨析</div>
                        <div class="items">1.同音字辨析</div>
                        <img src="../../assets/report/5-1.png">
                        <p>
                            通过选字填空的方式，我们主要考察学生对读音及形似字的辨析与应用。四组生字中，同学生的平均正确率为{{ this.zqlTable.zql_q2.filter(item => {
                                return
                                item.name == '五年级'
                            })[0].value }}%，
                            大多同学能够正确辨析相应生字并完成书写。</p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/report/5-2.png">
                        <p>
                            通过“重组生字”，可以考察学生对偏旁部首及结构的认识，包括“走字旁、耳字旁、门字框、田字旁、雨字头”等偏旁的认知，也包括对“左右结构、
                            上下结构、包围结构”等结构的认知与应用。本题难度较大，经过统计，本题的平均正确率为{{ this.zqlTable.zql_q3.filter(item => {
                                return
                                item.name == '五年级'
                            })[0].value }}%。需要注意日常生字的理解和分析。
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/report/5-3.png">
                        <p>
                            笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '五年级'
                                })[0].value }}%。
                        </p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次前测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{ gradeFive.dimension_a_1.result }}人，占{{ (gradeFive.dimension_a_1.result * 100 /
                                gradeFive.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeFive.dimension_a_2.result }}人，占{{ (gradeFive.dimension_a_2.result * 100 /
                                gradeFive.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeFive.dimension_a_3.result }}人，占{{ (gradeFive.dimension_a_3.result * 100 /
                                gradeFive.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeFive.dimension_a_4.result }}人，占{{ (gradeFive.dimension_a_4.result * 100 /
                                gradeFive.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/wobi.jpg">
                            <!-- <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span> -->
                        </div>
                        <div class="items">2.掌握形近字辨析应用</div>
                        <p>通过选字填空的方式，我们主要考察学生对读音及形似字的辨析与应用掌握。本次调查，本年级学生对于相近字的应用整体较好，请继续保持。</p>
                        <div class="items">3.掌握偏旁部首与结构</div>
                        <p>
                            通过“重组生字”，可以考察学生对偏旁部首及结构的认识，包括“走字旁、耳字旁、门字框、田字旁、雨字头”等偏旁的认知，难度相对较大，经过统计，本题的平均正确率为{{
                                (gradeFive.dimension_c_2.result * 100 / gradeFive.dimension_c_2.count).toFixed(2) }}%。
                            学生对于生字的组合关系与基本部件的掌握尤为重要。可通过课程学习，系统的积累每一类偏旁部首的书写能力。</p>
                        <p>同时也考察对“左右结构、上下结构、包围结构”等结构的认知与应用。</p>
                        <p>1）包围结构字形，在日常书写时可以根据字形外部轮廓特征，较为准确的定位出单字形态以及占格位置。</p>
                        <p>2）左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上</p>
                        <p>3）上下结构字形的单字在书写时，既要关注对上、下部件间的比例大小、间距等细节的处理，更要关注整字重心的排布。这就要求在书写时，熟练把握笔画、结构的书写技巧的同时，也要有对整个字重心位置的把握
                        </p>

                    </div>
                </div>
                <div class="sixth_grade flex-column-center" id="六年级" v-if="JSON.stringify(gradeSix) !== '{}'">
                    <div class="school">{{ schoolName }}六年级</div>
                    <div class="name">学生识写能力分析报告</div>
                    <div class="part_one">
                        <div class="part_title">一、总述</div>
                        <p>
                            截止目前，本轮前测中，共计收到来自{{schoolName}}六年级{{tableData_six.totalNum}}位同学提交的{{tableData_six.totalPage}}页试卷。
                            其中{{tableData_six.max}}参与率最高，{{tableData_six.min}}参与率最低。
                        </p>
                        <MyTable type="join" :thead="['年级班级', '注册人数', '参与人数', '参与率']" :tableData="tableData_six.tabal">
                        </MyTable>
                        <p>所有参与者的试卷样本，我们从以下几个角度，进行具体分析：</p>
                        <p>2.书写习惯</p>
                        <MyTable type="wbzs_class" :thead="['年级', '握笔错误人数', '错误比例']" :tableData="tableData_six.tabal2" :tableAll="tableData_six.tabal">
                        </MyTable>
                        <MyTable type="zz_class" :thead="['年级', '坐姿错误人数', '错误比例']" :tableData="tableData_six.tabal2" :tableAll="tableData_six.tabal">
                        </MyTable>
                        <p>3.书写水平</p>
                        <!-- <p>(1)年级书写水平</p> -->
                        <p>针对前测样本数据综合分析四个书写基础维度得分,本年级学生在{{ chartSix_str }}{{ chartSix_count }}个维度低于全国平均水平</p>
                        <table border="1">
                            <tr>
                                <th>评价维度</th>
                                <th>平均分值</th>
                                <th>全国常模</th>
                            </tr>
                            <tr>
                                <td>章法占格</td>
                                <td v-if="gradeSix.layout_art">
                                    {{ gradeSix.form_art.count == 0 ? 0 : (gradeSix.form_art.result /
                                        gradeSix.form_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeSix.form_art.count == 0 ? 0 : (gradeSix.form_art.result / gradeSix.form_art.count).toFixed(2)) > 82">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeSix.dimension_e_4.count == 0 ? 0 : (gradeSix.dimension_e_4.result /
                                        gradeSix.dimension_e_4.count).toFixed(2) }}
                                </td>
                                <td>82</td>
                            </tr>
                            <tr>
                                <td>作品完整度</td>
                                <td v-if="gradeSix.complete_art">
                                    {{ gradeSix.complete_art.count == 0 ? 0 : (gradeSix.complete_art.result /
                                        gradeSix.complete_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeSix.complete_art.count == 0 ? 0 : (gradeSix.complete_art.result / gradeSix.complete_art.count).toFixed(2)) > 92">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeSix.dimension_c_4.count == 0 ? 0 : (gradeSix.dimension_c_4.result * 100 /
                                        gradeSix.dimension_c_4.count).toFixed(2) }}
                                </td>
                                <td>92</td>
                            </tr>
                            <tr>
                                <td>笔画形态</td>
                                <td v-if="gradeSix.layout_art">
                                    {{ gradeSix.layout_art.count == 0 ? 0 : (gradeSix.layout_art.result /
                                        gradeSix.layout_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeSix.layout_art.count == 0 ? 0 : (gradeSix.layout_art.result / gradeSix.layout_art.count).toFixed(2)) > 85">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeSix.dimension_d_4.count == 0 ? 0 : (gradeSix.dimension_d_4.result /
                                        gradeSix.dimension_d_4.count).toFixed(2) }}
                                </td>
                                <td>85</td>
                            </tr>
                            <tr>
                                <td>结构组合</td>
                                <td v-if="gradeSix.structure_art">
                                    {{ gradeSix.structure_art.count == 0 ? 0 : (gradeSix.structure_art.result /
                                        gradeSix.structure_art.count).toFixed(2) }}
                                    <span
                                        v-if="Number(gradeSix.structure_art.count == 0 ? 0 : (gradeSix.structure_art.result / gradeSix.structure_art.count).toFixed(2)) > 85">↑</span>
                                    <span v-else style="color:red">↓</span>
                                </td>
                                <td v-else>
                                    {{ gradeSix.dimension_f_1.count == 0 ? 0 : (gradeSix.dimension_f_1.result /
                                        gradeSix.dimension_f_1.count).toFixed(2) }}
                                </td>
                                <td>81</td>
                            </tr>
                        </table>
                        <p v-if="badComment_sixth_grade_1">①　{{ badComment_sixth_grade_1 }}</p>
                        <p v-if="badComment_sixth_grade_2">②　{{ badComment_sixth_grade_2 }}</p>
                        <!-- <p>(2)班级书写水平</p>
                        <p v-for="(item, index) in badComment_six" :key="index">{{ item }}</p> -->
                        <template v-if="excellentUsers.sixth_grade.length > 0">
                            <p>4.优秀作品获得者,详细名单如下:</p>
                            <MyTable type="works" :thead="['姓名', '年级', '班级']" :tableData="excellentUsers.sixth_grade">
                            </MyTable>
                            <div style="font-size:14px">部分优秀作品实例:</div>
                            <div class="youxiu_works" v-for="(item, index) in excellentWork.sixth_grade"
                                :key="index + 'a'">
                                <div class="flex-align">
                                    <img :src="item.works[0]" alt="">
                                    <img :src="item.works[1]" alt="">
                                </div>
                                <span>作者:{{ item.userName }} {{ item.className }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="part_two">
                        <div class="part_title">二、测评结果分析</div>

                        <div class="items">（一）书写习惯</div>
                        <p> 掌握合理的坐姿和握笔姿势，是写好汉字的基础。所以首先我们会对学生进行双姿调查，本题为开放选择题，目的是调查学生自己当前的握笔和坐姿情况，题目选取了四种最常见的握笔和坐姿姿势，学生可不定项选择或不选。结果如下
                        </p>
                        <div class="items">1.握笔姿势</div>
                        <img src="../../assets/report/pen1.png">
                        <p>握姿1，是属于拇指内扣，会导致运笔比较死板，孩子写字缓慢、写字太用力、容易疲倦；握姿2，把拇指藏在食指后面，是第二多孩子易犯的执笔手势；握姿3是正确的；握姿4中手腕内钩，笔向自己，
                            会造成书写角度的错误，同时这种执笔方法会影响脊骨。</p>
                        <p>
                            通过本次调查发现，本年级学生选择握姿1的{{ gradeSix.dimension_a_1.result }}人，占{{ (gradeSix.dimension_a_1.result *
                                100 / gradeSix.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeSix.dimension_a_2.result }}人，占{{ (gradeSix.dimension_a_2.result * 100 /
                                gradeSix.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeSix.dimension_a_3.result }}人，占{{ (gradeSix.dimension_a_3.result * 100 /
                                gradeSix.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeSix.dimension_a_4.result }}人，占{{ (gradeSix.dimension_a_4.result * 100 /
                                gradeSix.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <div class="items">2.坐姿情况</div>
                        <img src="../../assets/report/sit.png">
                        <p>
                            通过调查发现，学生在日常坐姿中，选择坐姿1的有{{ gradeSix.dimension_b_1.result }}人，占{{ (gradeSix.dimension_b_1.result *
                                100 / gradeSix.dimension_b_1.count).toFixed(2) }}%；
                            坐姿2的有{{ gradeSix.dimension_b_2.result }}人，占{{ (gradeSix.dimension_b_2.result * 100 /
                                gradeSix.dimension_b_2.count).toFixed(2) }}%；
                            坐姿3的有{{ gradeSix.dimension_b_3.result }}人，占{{ (gradeSix.dimension_b_3.result * 100 /
                                gradeSix.dimension_b_3.count).toFixed(2) }}%；
                            坐姿4的有{{ gradeSix.dimension_b_4.result }}人，占{{ (gradeSix.dimension_b_4.result * 100 /
                                gradeSix.dimension_b_4.count).toFixed(2) }}%。前三种坐姿会对脊椎和视力造成很大影响，需要予以指导和纠正。
                        </p>
                        <p>正确的坐姿是，身体坐直，把书本放平，眼离书本一尺，胸离桌边一拳。</p>
                    </div>
                    <div class="part_four"
                        v-if="gradeCommentList_all.filter(item => { return item.className == '六年级'; })[0].data.complete.length > 0">
                        <div class="items">（二）书写基础</div>
                        <p>通过学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                        <div class="myChart" id="myChart6"></div>
                        <p>作品完整度平均分为
                            {{ gradeSix.complete_art.count == 0 ? 0 : (gradeSix.complete_art.result /
                                gradeSix.complete_art.count).toFixed(2) }},
                            {{ gradeSix.complete_art.count == 0 ? 0 : (gradeSix.complete_art.result /
                                gradeSix.complete_art.count).toFixed(2) > 92 ? '高于' : '低于' }}
                            全国常模92。学生整体书写
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.complete[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.complete[0].advice }}
                        </p>
                        <p>笔画形态平均分为
                            {{ gradeSix.form_art.count == 0 ? 0 : (gradeSix.form_art.result /
                                gradeSix.form_art.count).toFixed(2) }},
                            {{ gradeSix.form_art.count == 0 ? 0 : (gradeSix.form_art.result /
                                gradeSix.form_art.count).toFixed(2) > 85 ? '高于' : '低于' }}
                            全国常模85,
                            从作品的书写笔话形态来看,
                            {{ gradeCommentList_all.filter(item => { return item.className == '六年级'; })[0].data.form[0].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '六年级';
                            })[0].data.form[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '六年级';
                            })[0].data.form[0].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '六年级'; })[0].data.form[1].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '六年级';
                            })[0].data.form[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '六年级';
                            })[0].data.form[1].describe }}
                            {{ gradeCommentList_all.filter(item => { return item.className == '六年级'; })[0].data.form[2].name
                            }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '六年级';
                            })[0].data.form[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return item.className == '六年级';
                            })[0].data.form[2].describe }}
                        </p>
                        <p>章法占格平均分为
                            {{ gradeSix.layout_art.count == 0 ? 0 : (gradeSix.layout_art.result /
                                gradeSix.layout_art.count).toFixed(2) }},
                            {{ gradeSix.layout_art.count == 0 ? 0 : (gradeSix.layout_art.result /
                                gradeSix.layout_art.count).toFixed(2) > 82 ? '高于' : '低于' }}
                            全国常模82,
                            从作品的章法占格来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.layout[2].describe }}
                        </p>
                        <p>结构组合平均分为
                            {{ gradeSix.structure_art.count == 0 ? 0 : (gradeSix.structure_art.result /
                                gradeSix.structure_art.count).toFixed(2) }},
                            {{ gradeSix.structure_art.count == 0 ? 0 : (gradeSix.structure_art.result /
                                gradeSix.structure_art.count).toFixed(2) > 81 ? '高于' : '低于' }}
                            全国常模81,
                            从作品的结构组合来看,
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[0].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[0].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[0].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[1].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[1].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[1].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[2].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[2].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[2].describe }}
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[3].name }}维度--
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[3].comment }},
                            {{ gradeCommentList_all.filter(item => {
                                return
                                item.className == '六年级';
                            })[0].data.structure[3].describe }}
                        </p>

                    </div>
                    <div class="part_three">
                        <div class="part_title">（三）书写辨析</div>
                        <div class="items">1.成语补充</div>
                        <img src="../../assets/report/6-1.png">
                        <p>
                            通过前后文理解，补充成语缺失的汉字，主要考察成语认知与书写。同学生的平均正确率为{{ this.zqlTable.zql_q2.filter(item => {
                                return
                                item.name == '六年级'
                            })[0].value }}%，大多同学能够正确辨析相应生字并完成书写。
                        </p>
                        <div class="items">2.部件与结构认知</div>
                        <img src="../../assets/report/6-2.png">
                        <p>
                            通过“结构分类”的试题，考察学生对左右结构中，三类间架结构的认识与常见生字的结构区分。左右结构一般分为“左小右大、左右平分、左大右小”三类。
                            本题具有一定的难度，经过统计整体平均正确率为{{ this.zqlTable.zql_q3.filter(item => {
                                return
                                item.name == '六年级'
                            })[0].value }}%。需要注意日常生字间架结构书写特点的理解与记忆。
                        </p>
                        <div class="items">3.笔顺笔画掌握</div>
                        <img src="../../assets/report/6-3.png">
                        <p>
                            书写笔顺关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。本题主要通过易错字进行笔顺笔画的考察，平均正确率为{{
                                this.zqlTable.zql_q4.filter(item => {
                                    return
                                    item.name == '六年级'
                                })[0].value }}%。
                        </p>
                        <p>学生大多都能正确理解题意，并完成数笔画、并正确的填写生字的过程。本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                    </div>
                    <div class="part_five">
                        <div class="part_title">三、总结</div>
                        <p>通过本次前测，考察了本年级学生在书写习惯、识字及写字各个方面，整体建议：</p>
                        <div class="items">1.握笔姿势掌握</div>
                        <p>良好的握笔姿势是书写提升的前提。错误执笔手势可能导致儿童坐姿有问题、手形扭曲、骨骼变形、容易疲倦、做功课的速度慢，字体亦不美观，连锁反应就是孩子对写字反感。通过本次调查，
                            本年级学生选择握姿1的{{ gradeSix.dimension_a_1.result }}人，占{{ (gradeSix.dimension_a_1.result * 100 /
                                gradeSix.dimension_a_1.count).toFixed(2) }}%；
                            选择握姿2的有{{ gradeSix.dimension_a_2.result }}人，占{{ (gradeSix.dimension_a_2.result * 100 /
                                gradeSix.dimension_a_2.count).toFixed(2) }}%；
                            握姿3的有{{ gradeSix.dimension_a_3.result }}人，占{{ (gradeSix.dimension_a_3.result * 100 /
                                gradeSix.dimension_a_3.count).toFixed(2) }}%；
                            握姿4的有{{ gradeSix.dimension_a_4.result }}人，占{{ (gradeSix.dimension_a_4.result * 100 /
                                gradeSix.dimension_a_4.count).toFixed(2) }}%。
                        </p>
                        <p>
                            因此学生的握笔姿势仍有一定的提升空间，正确握笔方式，应采用三指执笔法。具体要求是：右手执笔，大拇指、食指、中指分别从三个方向捏住离笔尖3厘米左右的笔杆下端。执笔要做到“指实掌虚”，就是手指握笔要实，掌心要空，这样书写起来才能灵活运笔。
                            教师可以在平台上打开首都师范大学专家录制的《握笔儿歌》，写字之前练习握笔姿势，养成良好的书写习惯：</p>
                        <div class="music flex-column-center">
                            <img src="../../assets/report/wobi.jpg">
                            <!-- <img src="../../assets/report/posture.jpg">
                            <span>龙须并排握在前，龙角分开在两边。</span>
                            <span>中间一只大龙眼，龙肚一定留空间。</span> -->
                        </div>
                        <div class="items">2.掌握基本笔画的写法</div>
                        <p>通过设置语境补充成语的方式，考察学生对生字的应用及字义的掌握。本次调查，本年级学生对于成语的应用整体较好，请继续保持。</p>
                        <div class="items">3.掌握汉字间架结构</div>
                        <p>通过“结构分类”，考察学生对结构的认识。对左右结构中，三类间架结构的认识与常见生字的结构区分。
                            左右结构一般分为“左小右大、左右平分、左大右小”三类，经过统计整体平均正确率为
                            {{ this.zqlTable.zql_q3.filter(item => { return item.name == '六年级' })[0].value }}%
                            <!-- {{(gradeSix.dimension_c_2.result*100/gradeSix.dimension_c_2.count).toFixed(2)}}%。 -->
                            左右结构在常用汉字中的占比高达63.7%，左右结构的单字注意其部件与部件之间的位置、大小及高低排布等关系处理上。
                        </p>
                    </div>
                </div>
            </div>
            <!-- 后测-整体 -->
            <reportAfter v-if="currentType=='gradeAfter'" ref="afterReport"></reportAfter>
            <!-- 前测-个人 -->
            <stuReport :className="currentClass" :termKey="termValue" :classId="currentClassId"
                v-if="currentType=='person'"></stuReport>
            <!-- 后测-个人 -->
            <stuReportAfter :className="currentClass" :termKey="termValue" :classId="currentClassId"
                v-if="currentType=='personAfter'">
            </stuReportAfter>
            <Report_whole v-if="currentType=='reportWhole'" :termKey="termValue" :orgId="schoolOrgID"></Report_whole>
        </template>
        <template v-else>
            <el-empty style="margin-top:200px" description="暂无数据"></el-empty>
        </template>
    </div>
</template>
<script>
    // import stuReport from './report_stu.vue';
    import stuReport from './newreport_stu.vue';
    import stuReportAfter from './report_stu_after.vue';
    import reportAfter from './report_after.vue';
    import MyTable from './table.vue';
    import Report_whole from './report_whole.vue';
    import {
        getPdf
    } from '../../utils/exportPdf'
    export default {
        data() {
            return {
                isCollapse: false,
                isShow: true,
                drawer: true,
                // currentType: 'reportWhole',
                currentType: 'grade',
                currentClass: '',
                currentClassId: '',
                data: [],
                data_after: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                schoolName: '',
                gradeList: [],
                gradeList2: [],
                allData: {},
                tabalAll: [],
                tabalOne: [],
                tabalTwo: [],
                tabalThree: [],
                tabalFour: [],
                tabalFive: [],
                tabalSix: [],
                tabalOne_after: [],
                tabalTwo_after: [],
                tabalThree_after: [],
                tabalFour_after: [],
                tabalFive_after: [],
                tabalSix_after: [],
                gradeOne: {},
                gradeTwo: {},
                gradeThree: {},
                gradeFour: {},
                gradeFive: {},
                gradeSix: {},
                schoolOrgID: '',
                chartOne: [],
                chartTwo: [],
                chartThree: [],
                chartFour: [],
                chartFive: [],
                chartSix: [],
                comments: {
                    consistency: [
                        "学生整体对于单字的书写大小适中，整体表现优异。建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体书写大小基本一致，整体看来稍有不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体字的书写大小偏差较大，分布不均匀，整体看来不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。"
                    ],
                    like: [
                        "学生整体书写工整，与笔画形态较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。",
                        "学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。",
                        "学生整体书写不太规范，与笔画形态较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。"
                    ]
                },
                woziAll: [],
                sitAll: [],
                holdInfo: {
                    allNum: 0,
                    rightNnm: 0,
                    failNum: 0,
                    rightRatio: 0,
                    faliRatio: 0,
                    highName: '',
                    highRatio: 0,
                    lowName: '',
                    lowRatio: 0,
                    hold_1: 0,
                    hold_2: 0,
                    hold_4: 0,
                    ratio_1: 0,
                    ratio_2: 0,
                    ratio_4: 0,
                    mostFail: ''
                },
                sitInfo: {
                    allNum: 0,
                    rightNum: 0,
                    failNum: 0,
                    rightRatio: 0,
                    faliRatio: 0,
                    highName: '',
                    highRatio: 0,
                    lowName: '',
                    lowRatio: 0,
                    hold_1: 0,
                    hold_2: 0,
                    hold_4: 0,
                    ratio_1: 0,
                    ratio_2: 0,
                    ratio_4: 0,
                    mostFail: ''
                },
                allInfo: {
                    stuNum: 0,
                    pageNum: 0,
                    all_wzd: 0, // 完整度(AI测评)
                    all_xsx: 0, // 笔画形态(AI测评)
                    all_yzx: 0, // 章法占格(AI测评)
                    all_zqd: 0, // 结构组合(AI测评)
                    all_wzd_art: 0, // 完整度(人工点评)
                    all_xsx_art: 0, // 笔画形态(人工点评)
                    all_yzx_art: 0, // 章法占格(人工点评)
                    all_zqd_art: 0, // 结构组合(人工点评)
                    all_baowei: 0,
                    all_shangxia: 0,
                    all_zuoyou: 0,
                    all_duti: 0,
                    all_wzd_a: 0,
                    all_wzd_b: 0,
                    all_wzd_c: 0,
                    all_wzd_high: '',
                    all_xsx_a: 0,
                    all_xsx_b: 0,
                    all_xsx_c: 0,
                    all_xsx_high: '',
                    all_yzx_a: 0,
                    all_yzx_b: 0,
                    all_yzx_c: 0,
                    all_yzx_high: '',
                    all_zqd_a: 0,
                    all_zqd_b: 0,
                    all_zqd_c: 0,
                    all_zqd_high: '',
                    all_wzd_HClass: '',
                    all_wzd_LClass: '',
                    all_xsx_HClass: '',
                    all_xsx_LClass: '',
                    all_yzx_HClass: '',
                    all_yzx_LClass: '',
                    all_zqd_HClass: '',
                    all_zqd_LClass: '',
                },
                allComments: {
                    wzd: [
                        "学生整体书写在内容书写完整度方面表现较为优异，可以较为完整、准确的书写出指定测评文字内容，基本做到不缺字、不漏字。",
                        "学生整体书写在内容书写完整度方面基本达标，可以大部分书写出指定测评文字内容，存在部分错字、涂鸦、缺字、漏字的情况。建议今后的书写中落笔之前多加注意。",
                        "学生整体书写篇章不够完整，整体在内容书写完整度方面有所不足，部分内容未能按照要求书写出指定测评文字内容，错字、涂鸦、缺字、漏字的情况较多。建议今后的书写中减慢速度，先增加书写的准确度。"
                    ],
                    xsx: [
                        "学生整体书写工整，与笔画形态较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。",
                        "学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。",
                        "学生整体书写不太规范，与笔画形态较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。"
                    ],
                    yzx: [
                        "学生整体对于单字的书写大小适中，整体表现优异。建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体书写大小基本一致，整体看来稍有不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体字的书写大小偏差较大，分布不均匀，整体看来不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。"
                    ],
                    zqd: [
                        "学生整体书写布局协调，占格居中。对字组位置间距、篇章行列布局等方面完成较好，书写高低基本一致，没有杂乱感。",
                        "学生整体书写布局基本合理，注意重心位置。对字组位置间距、篇章行列布局等方面仍有提升空间，书写的高低稍有杂乱感，建议可以在今后书写及训练中多加注意。",
                        "学生整体书写布局不够整齐，注意上下左右保持对齐。对字组位置间距、篇章行列布局等方面重视程度不足，致使书写效果高低错落的杂乱感，建议可以在今后的日常书写及训练中加强对行列排布的重视程度。"
                    ]
                },
                constructionInfo: {
                    bwMax: 0,
                    bwMin: 0,
                    bwMaxName: '',
                    bwMinName: '',
                    zyMax: 0,
                    zyMin: 0,
                    zyMaxName: '',
                    zyMinName: '',
                    dtMax: 0,
                    dtMin: 0,
                    dtMaxName: '',
                    dtMinName: '',
                    sxMax: 0,
                    sxMin: 0,
                    sxMaxName: '',
                    sxMinName: '',
                },
                zqlTable: {
                    zql_name: [],
                    zql_q2: [{
                            name: '一年级',
                            value: 0
                        },
                        {
                            name: '二年级',
                            value: 0
                        },
                        {
                            name: '三年级',
                            value: 0
                        },
                        {
                            name: '四年级',
                            value: 0
                        },
                        {
                            name: '五年级',
                            value: 0
                        },
                        {
                            name: '六年级',
                            value: 0
                        },
                    ],
                    zql_q3: [{
                            name: '一年级',
                            value: 0
                        },
                        {
                            name: '二年级',
                            value: 0
                        },
                        {
                            name: '三年级',
                            value: 0
                        },
                        {
                            name: '四年级',
                            value: 0
                        },
                        {
                            name: '五年级',
                            value: 0
                        },
                        {
                            name: '六年级',
                            value: 0
                        },
                    ],
                    zql_q4: [{
                            name: '一年级',
                            value: 0
                        },
                        {
                            name: '二年级',
                            value: 0
                        },
                        {
                            name: '三年级',
                            value: 0
                        },
                        {
                            name: '四年级',
                            value: 0
                        },
                        {
                            name: '五年级',
                            value: 0
                        },
                        {
                            name: '六年级',
                            value: 0
                        },
                    ],
                },
                jgTable: {
                    class: [],
                    zy_data: [],
                    sx_data: [],
                    dt_data: [],
                    bw_data: [],
                },
                pjwdTable: {
                    rc_data: [{
                            name: '一年级',
                            value: 0
                        },
                        {
                            name: '二年级',
                            value: 0
                        },
                        {
                            name: '三年级',
                            value: 0
                        },
                        {
                            name: '四年级',
                            value: 0
                        },
                        {
                            name: '五年级',
                            value: 0
                        },
                        {
                            name: '六年级',
                            value: 0
                        },
                    ],
                    ky_data: [{
                            name: '一年级',
                            value: 0
                        },
                        {
                            name: '二年级',
                            value: 0
                        },
                        {
                            name: '三年级',
                            value: 0
                        },
                        {
                            name: '四年级',
                            value: 0
                        },
                        {
                            name: '五年级',
                            value: 0
                        },
                        {
                            name: '六年级',
                            value: 0
                        },
                    ],
                    compare: '',
                    comment: ''
                },
                gradeList_after: [],
                termOptions: [],
                termValue: '',
                allTableData: [],
                maxRateName: '',
                minRateName: '',
                allComments_art: {
                    complete: '作品完整度要求作品内容完整、用字正确。',
                    layout: '章法占格由卷面整洁、占格占位、字形大小组成。',
                    structure: '结构组合由字形重心、主次收放、笔画关系、部件关系组成。',
                    form: '笔画形态由方向、笔性、粗细组成。'
                },
                comments_art1: '', // 成绩最差的2个维度评语
                comments_art2: '', // 成绩最差的2个维度评语 
                gradeCommentList: [],
                // 各年级成绩最差的2个维度评语 
                badComment_first_grade_1: '',
                badComment_second_grade_1: '',
                badComment_third_grade_1: '',
                badComment_fourth_grade_1: '',
                badComment_fifth_grade_1: '',
                badComment_sixth_grade_1: '',
                badComment_first_grade_2: '',
                badComment_second_grade_2: '',
                badComment_third_grade_2: '',
                badComment_fourth_grade_2: '',
                badComment_fifth_grade_2: '',
                badComment_sixth_grade_2: '',
                excellentUsers: {
                    all: [],
                    first_grade: [],
                    second_grade: [],
                    third_grade: [],
                    fourth_grade: [],
                    fifth_grade: [],
                    sixth_grade: []
                }, // 优秀作品名单
                excellentWork: {
                    all: [],
                    first_grade: [],
                    second_grade: [],
                    third_grade: [],
                    fourth_grade: [],
                    fifth_grade: [],
                    sixth_grade: []
                }, // 优秀作品列表
                bihuaTableData: [], // 笔画形态分维度分析表格
                zhangfaTableData: [], // 章法占格分维度分析表格
                jiegouTableData: [], // 结构组合分维度分析表格
                fx_min: {
                    name: '',
                    val: ''
                }, //笔画形态方法最低年级
                bx_min: {
                    name: '',
                    val: ''
                }, //笔画形态方法最低年级
                cx_min: {
                    name: '',
                    val: ''
                }, //笔画形态方法最低年级
                gradeCommentList_bihua: [], // 笔画形态各年级评语
                jm_min: {
                    name: '',
                    val: ''
                }, //章法占格-卷面整洁最低年级
                zg_min: {
                    name: '',
                    val: ''
                }, //章法占格-占格占位最低年级
                zx_min: {
                    name: '',
                    val: ''
                }, //章法占格-字形大小最低年级
                gradeCommentList_zhangfa: [], // 章法占格各年级评语
                zx_min: {
                    name: '',
                    val: ''
                }, //结构组合-自行重心最低年级
                zc_min: {
                    name: '',
                    val: ''
                }, //结构组合-主次收放最低年级
                bh_min: {
                    name: '',
                    val: ''
                }, //结构组合-笔画关系最低年级
                bj_min: {
                    name: '',
                    val: ''
                }, //结构组合-部件关系最低年级
                gradeCommentList_jiegou: [], // 章法占格各年级评语
                gradeCommentList_all: [], // 各年级一级维度评语
                tableData_one: {
                    max: '',
                    min: '',
                    tabal: [],
                    tabal2: [],
                    errortotal_wbzs: '', //该年级握笔错误人数
                    total_wbzs: '', //该年级握笔总人数
                    errortotal_zz: '', //该年级坐姿错误人数
                    total_zz: '', // 该年级坐姿总人数
                    max_wbzs: '', //握笔错误率最高
                    min_wbzs: '', //握笔错误率最低
                    max_zz: '', //坐姿错误率最高
                    min_zz: '', //坐姿错误率最低 
                    totalNum: null,
                    totalPage: null
                }, // 1年级注册人数表格
                tableData_two: {
                    max: '',
                    min: '',
                    tabal: [],
                    tabal2: [],
                    errortotal_wbzs: '', //该年级握笔错误人数
                    total_wbzs: '', //该年级握笔总人数
                    errortotal_zz: '', //该年级坐姿错误人数
                    total_zz: '', // 该年级坐姿总人数
                    max_wbzs: '', //握笔错误率最高
                    min_wbzs: '', //握笔错误率最低
                    max_zz: '', //坐姿错误率最高
                    min_zz: '', //坐姿错误率最低 
                    totalNum: null,
                    totalPage: null

                }, // 2年级注册人数表格
                tableData_three: {
                    max: '',
                    min: '',
                    tabal: [],
                    tabal2: [],
                    errortotal_wbzs: '', //该年级握笔错误人数
                    total_wbzs: '', //该年级握笔总人数
                    errortotal_zz: '', //该年级坐姿错误人数
                    total_zz: '', // 该年级坐姿总人数
                    max_wbzs: '', //握笔错误率最高
                    min_wbzs: '', //握笔错误率最低
                    max_zz: '', //坐姿错误率最高
                    min_zz: '', //坐姿错误率最低 
                    totalNum: null,
                    totalPage: null
                }, // 3年级注册人数表格
                tableData_four: {
                    max: '',
                    min: '',
                    tabal: [],
                    tabal2: [],
                    errortotal_wbzs: '', //该年级握笔错误人数
                    total_wbzs: '', //该年级握笔总人数
                    errortotal_zz: '', //该年级坐姿错误人数
                    total_zz: '', // 该年级坐姿总人数
                    max_wbzs: '', //握笔错误率最高
                    min_wbzs: '', //握笔错误率最低
                    max_zz: '', //坐姿错误率最高
                    min_zz: '', //坐姿错误率最低 
                    totalNum: null,
                    totalPage: null
                }, // 4年级注册人数表格
                tableData_five: {
                    max: '',
                    min: '',
                    tabal: [],
                    tabal2: [],
                    errortotal_wbzs: '', //该年级握笔错误人数
                    total_wbzs: '', //该年级握笔总人数
                    errortotal_zz: '', //该年级坐姿错误人数
                    total_zz: '', // 该年级坐姿总人数
                    max_wbzs: '', //握笔错误率最高
                    min_wbzs: '', //握笔错误率最低
                    max_zz: '', //坐姿错误率最高
                    min_zz: '', //坐姿错误率最低 
                    totalNum: null,
                    totalPage: null
                }, // 5年级注册人数表格
                tableData_six: {
                    max: '',
                    min: '',
                    tabal: [],
                    tabal2: [],
                    errortotal_wbzs: '', //该年级握笔错误人数
                    total_wbzs: '', //该年级握笔总人数
                    errortotal_zz: '', //该年级坐姿错误人数
                    total_zz: '', // 该年级坐姿总人数
                    max_wbzs: '', //握笔错误率最高
                    min_wbzs: '', //握笔错误率最低
                    max_zz: '', //坐姿错误率最高
                    min_zz: '', //坐姿错误率最低 
                    totalNum: null,
                    totalPage: null
                }, // 6年级注册人数表格

                badComment_one: [], // 1年级各班最差维度评论
                badComment_two: [], // 1年级各班最差维度评论
                badComment_three: [], // 1年级各班最差维度评论
                badComment_four: [], // 1年级各班最差维度评论
                badComment_five: [], // 1年级各班最差维度评论
                badComment_six: [], // 1年级各班最差维度评论
                classLists: {
                    1: '一年级',
                    2: '二年级',
                    3: '三年级',
                    4: '四年级',
                    5: '五年级',
                    6: '六年级'
                },
                chartOne_str: '',
                chartOne_count: 0,
                chartTwo_str: '',
                chartTwo_count: 0,
                chartThree_str: '',
                chartThree_count: 0,
                chartFour_str: '',
                chartFour_count: 0,
                chartFive_str: '',
                chartFive_count: 0,
                chartSix_str: '',
                chartSix_count: 0,
                teaData: null,
                totalData: {
                    termName: ''
                }
            }
        },
        components: {
            stuReport,
            stuReportAfter,
            reportAfter,
            MyTable,
            Report_whole
        },
        watch: {
            currentType(val) {
                // console.log('新val:', val);
                if (val == 'grade') {
                    this.data = [];
                    this.queryUserMaxOrg();
                }
            }
        },
        computed: {
            // 作品完整度
            gradeOneFull() {
                return (this.gradeOne.dimension_c_4.result * 100 / this.gradeOne.dimension_c_4.count).toFixed(2)
            },
            // 结构组合
            gradeOneLayout() {
                return (this.gradeOne.dimension_f_1.result / this.gradeOne.dimension_f_1.count).toFixed(2)
            },
            // 章法占格
            gradeOneBeautufy() {
                return (this.gradeOne.dimension_e_4.result / this.gradeOne.dimension_e_4.count).toFixed(2)
            },
            // 笔画形态
            gradeOneContent() {
                return (this.gradeOne.dimension_d_4.result / this.gradeOne.dimension_d_4.count).toFixed(2)
            },
            gradeTwoFull() {
                return (this.gradeTwo.dimension_c_4.result * 100 / this.gradeTwo.dimension_c_4.count).toFixed(2)
            },
            gradeTwoLayout() {
                return (this.gradeTwo.dimension_f_1.result / this.gradeTwo.dimension_f_1.count).toFixed(2)
            },
            gradeTwoBeautufy() {
                return (this.gradeTwo.dimension_e_4.result / this.gradeTwo.dimension_e_4.count).toFixed(2)
            },
            gradeTwoContent() {
                return (this.gradeTwo.dimension_d_4.result / this.gradeTwo.dimension_d_4.count).toFixed(2)
            },
            gradeThreeFull() {
                return (this.gradeThree.dimension_c_4.result * 100 / this.gradeThree.dimension_c_4.count).toFixed(2)
            },
            gradeThreeLayout() {
                return (this.gradeThree.dimension_f_1.result / this.gradeThree.dimension_f_1.count).toFixed(2)
            },
            gradeThreeBeautufy() {
                return (this.gradeThree.dimension_e_4.result / this.gradeThree.dimension_e_4.count).toFixed(2)
            },
            gradeThreeContent() {
                return (this.gradeThree.dimension_d_4.result / this.gradeThree.dimension_d_4.count).toFixed(2)
            },
            gradeFourFull() {
                return (this.gradeFour.dimension_c_4.result * 100 / this.gradeFour.dimension_c_4.count).toFixed(2)
            },
            gradeFourLayout() {
                return (this.gradeFour.dimension_f_1.result / this.gradeFour.dimension_f_1.count).toFixed(2)
            },
            gradeFourBeautufy() {
                return (this.gradeFour.dimension_e_4.result / this.gradeFour.dimension_e_4.count).toFixed(2)
            },
            gradeFourContent() {
                return (this.gradeFour.dimension_d_4.result / this.gradeFour.dimension_d_4.count).toFixed(2)
            },
            gradeFiveFull() {
                return (this.gradeFive.dimension_c_4.result * 100 / this.gradeFive.dimension_c_4.count).toFixed(2)
            },
            gradeFiveLayout() {
                return (this.gradeFive.dimension_f_1.result / this.gradeFive.dimension_f_1.count).toFixed(2)
            },
            gradeFiveBeautufy() {
                return (this.gradeFive.dimension_e_4.result / this.gradeFive.dimension_e_4.count).toFixed(2)
            },
            gradeFiveContent() {
                return (this.gradeFive.dimension_d_4.result / this.gradeFive.dimension_d_4.count).toFixed(2)
            },
            gradeSixFull() {
                return (this.gradeSix.dimension_c_4.result * 100 / this.gradeSix.dimension_c_4.count).toFixed(2)
            },
            gradeSixLayout() {
                return (this.gradeSix.dimension_f_1.result / this.gradeSix.dimension_f_1.count).toFixed(2)
            },
            gradeSixBeautufy() {
                return (this.gradeSix.dimension_e_4.result / this.gradeSix.dimension_e_4.count).toFixed(2)
            },
            gradeSixContent() {
                return (this.gradeSix.dimension_d_4.result / this.gradeSix.dimension_d_4.count).toFixed(2)
            },
        },
        methods: {
            hide() {
                this.isCollapse = !this.isCollapse
            },
            // 展开导航
            handleOpen(key, keyPath) {
                console.log('展开', key);
                window.location.hash = '';
                if (key == '学校整体报告') {
                    this.currentType = 'grade';
                    // console.log('显示整体报告-前测');
                } else if (key == '学生个人报告') {
                    this.currentType = 'person';
                    // console.log('显示个人报告-前测');
                    this.currentClassId = this.data[0].children[0].classId;
                    this.currentClass = this.data[0].children[0].label
                } else if (key == '学校整体报告后测') {
                    this.currentType = 'gradeAfter';
                    // console.log('显示整体报告-后测')
                } else if (key == '学生个人报告后测') {
                    this.currentType = 'personAfter';
                    // console.log('显示个人报告-后测')
                    this.currentClassId = this.data_after[0].children[0].classId
                } else if (key == '报告助手') {

                    this.currentType = 'reportWhole';
                    // console.log('显示个人报告-后测')
                    // this.currentClassId = this.data_after[0].children[0].classId
                } else {
                    if (this.currentType == 'person') {
                        // console.log('---个人报告前测')
                        let index = this.data.findIndex(item => item.label == key);
                        // console.log('index:',index)
                        this.currentClassId = this.data[index].children[0].classId
                        this.currentClass = this.data[index].children[0].label
                    } else if (this.currentType = 'personAfter') {
                        // console.log('---个人报告后测')
                        let index = this.data_after.findIndex(item => item.label == key.trim());
                        // console.log('index:', index)
                        this.currentClassId = this.data_after[index].children[0].classId;
                        this.currentClass = this.data_after[index].children[0].label
                    }
                }
            },
            // 关闭导航
            handleClose(key, keyPath) {
                window.location.hash = ''
                console.log('收起', key);
                if (key == '学校整体报告') {
                    this.currentType = 'grade';
                } else if (key == '学生个人报告') {
                    this.currentType = 'person';
                } else if (key == '学校整体报告后测') {
                    this.currentType = 'gradeAfter';
                } else if (key == '学生个人报告后测') {
                    this.currentType = 'personAfter';
                } else if (key == '报告助手') {
                    this.currentType = 'reportWhole';
                }
            },
            // 选择导航
            handSelect(index, indexPath) {
                // console.log('班级id:', index, '班级path:', indexPath);
                if (index == '首师优字前测报告' || index == '首师优字后测报告') {
                    return
                } else {
                    window.location.hash = index.split(',')[0];
                    this.currentClass = index.split(',')[0];
                    this.currentClassId = index.split(',')[1];
                }
            },
            //学生报告：wzx
            clickMenu(key) {
                console.log('学生报告', key);
                this.currentType = 'reportWhole';
            },
            hideCatalogue() {
                this.isShow = !this.isShow
            },
            exportpdf() {
                getPdf(`${this.schoolName}测评报告`, 'pdfDom')
            },
            // 整体数据
            async initData() {
                let data = {
                    chart: 4,
                    level: 5, // 5学校 6年级 7班级
                    orgId: this.schoolOrgID,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                console.log('整体数据', resData);
                this.allData = resData.data["dimension_f_6"];

                this.allInfo.all_wzd_a = (resData.data.complete_art_a.count / resData.data.complete_art.count)
                    .toFixed(2);
                this.allInfo.all_wzd_b = (resData.data.complete_art_b.count / resData.data.complete_art.count)
                    .toFixed(2);
                this.allInfo.all_wzd_c = (resData.data.complete_art_c.count / resData.data.complete_art.count)
                    .toFixed(2);
                this.allInfo.all_xsx_a = (resData.data.form_art_a.count / resData.data.form_art.count).toFixed(2);
                this.allInfo.all_xsx_b = (resData.data.form_art_b.count / resData.data.form_art.count).toFixed(2);
                this.allInfo.all_xsx_c = (resData.data.form_art_c.count / resData.data.form_art.count).toFixed(2);
                this.allInfo.all_zqd_a = (resData.data.structure_art_a.count * 100 / resData.data.structure_art
                    .count).toFixed(2);
                this.allInfo.all_zqd_b = (resData.data.structure_art_b.count * 100 / resData.data.structure_art
                    .count).toFixed(2);
                this.allInfo.all_zqd_c = (resData.data.structure_art_c.count * 100 / resData.data.structure_art
                    .count).toFixed(2);
                this.allInfo.all_yzx_a = (resData.data.layout_art_a.count * 100 / resData.data.layout_art.count)
                    .toFixed(2);
                this.allInfo.all_yzx_b = (resData.data.layout_art_b.count * 100 / resData.data.layout_art.count)
                    .toFixed(2);;
                this.allInfo.all_yzx_c = (resData.data.layout_art_c.count * 100 / resData.data.layout_art.count)
                    .toFixed(2);;

                this.allInfo.all_wzd = resData.data.all_wzd.count ? (resData.data.all_wzd.result * 100 / resData
                    .data.all_wzd.count).toFixed(2) : 0; // 整体-作品完整度(AI测评成绩)
                this.allInfo.all_xsx = resData.data.all_xsx.count ? (resData.data.all_xsx.result / resData.data
                    .all_xsx.count).toFixed(2) : 0; // 整体-笔画形态(AI测评成绩)
                this.allInfo.all_yzx = resData.data.all_yzx.count ? (resData.data.all_yzx.result / resData.data
                    .all_yzx.count).toFixed(2) : 0; // 整体-章法占格(AI测评成绩)
                this.allInfo.all_zqd = resData.data.all_zqd.count ? (resData.data.all_zqd.result / resData.data
                    .all_zqd.count).toFixed(2) : 0; // 整体-结构组合(AI测评成绩)
                this.allInfo.all_wzd_art = resData.data.complete_art ? (resData.data.complete_art.result / resData
                    .data.complete_art.count).toFixed(2) : ''; // 整体-作品完整度(人工测评成绩)
                this.allInfo.all_xsx_art = resData.data.form_art ? (resData.data.form_art.result / resData.data
                    .form_art.count).toFixed(2) : ''; // 整体-笔画形态(人工测评成绩)
                this.allInfo.all_yzx_art = resData.data.layout_art ? (resData.data.layout_art.result / resData.data
                    .layout_art.count).toFixed(2) : ''; // 整体-章法占格(人工测评成绩)
                this.allInfo.all_zqd_art = resData.data.structure_art ? (resData.data.structure_art.result / resData
                    .data.structure_art.count).toFixed(2) : ''; // 整体-结构组合(人工测评成绩)

                this.allInfo.all_baowei = resData.data.xsx_baowei.count ? (resData.data.xsx_baowei.result / resData
                    .data.xsx_baowei.count).toFixed(2) : 0;
                this.allInfo.all_shangxia = resData.data.xsx_shangxia.count ? (resData.data.xsx_shangxia.result /
                    resData.data.xsx_shangxia.count).toFixed(2) : 0;
                this.allInfo.all_zuoyou = resData.data.xsx_zuoyou.count ? (resData.data.xsx_zuoyou.result / resData
                    .data.xsx_zuoyou.count).toFixed(2) : 0;
                this.allInfo.all_duti = resData.data.xsx_duti.count ? (resData.data.xsx_duti.result / resData.data
                    .xsx_duti.count).toFixed(2) : 0;

                let xData = [this.allInfo.all_wzd, this.allInfo.all_xsx, this.allInfo
                    .all_yzx, this.allInfo.all_zqd
                ];
                let val = Number(this.allInfo.all_wzd_a) > Number(this.allInfo.all_wzd_b) ? this.allInfo.all_wzd_a :
                    this.allInfo.all_wzd_b;
                let high_wzd = Number(val) > this.allInfo.all_wzd_c ? val : this.allInfo.all_wzd_c;
                this.allInfo.all_wzd_high = high_wzd == this.allInfo.all_wzd_a ? 'A(80-100)' : high_wzd == this
                    .allInfo.all_wzd_b ? 'B(65-80)' : 'C(0-65)';
                let val2 = Number(this.allInfo.all_xsx_a) > Number(this.allInfo.all_xsx_b) ? this.allInfo
                    .all_xsx_a : this.allInfo.all_xsx_b;
                let high_xsx = Number(val2) > this.allInfo.all_xsx_c ? val2 : this.allInfo.all_xsx_c;
                this.allInfo.all_xsx_high = high_xsx == this.allInfo.all_xsx_a ? 'A(80-100)' : high_xsx == this
                    .allInfo.all_xsx_b ? 'B(65-80)' : 'C(0-65)';
                let val3 = Number(this.allInfo.all_yzx_a) > Number(this.allInfo.all_yzx_b) ? this.allInfo
                    .all_yzx_a : this.allInfo.all_yzx_b;
                let high_yzx = Number(val3) > this.allInfo.all_yzx_c ? val3 : this.allInfo.all_yzx_c;
                this.allInfo.all_yzx_high = high_yzx == this.allInfo.all_yzx_a ? 'A(80-100)' : high_yzx == this
                    .allInfo.all_yzx_b ? 'B(65-80)' : 'C(0-65)';
                let val4 = Number(this.allInfo.all_zqd_a) > Number(this.allInfo.all_zqd_b) ? this.allInfo
                    .all_zqd_a : this.allInfo.all_zqd_b;
                let high_zqd = Number(val4) > Number(this.allInfo.all_zqd_c) ? val4 : this.allInfo.all_zqd_c;
                this.allInfo.all_zqd_high = high_zqd == this.allInfo.all_zqd_a ? 'A(80-100)' : high_zqd == this
                    .allInfo.all_zqd_b ? 'B(65-80)' : 'C(0-65)';

                // 报告2.0人工点评新增
                if (resData.data.complete_art) {
                    this.getExcellentUsers('school', this.schoolOrgID); // 全校优秀作品名单列表
                    this.getExcellentWork('school', this.schoolOrgID); // 全校优秀作品列表
                    this.getAnalyseChart(this.schoolOrgID, 'form', this.termValue); // 笔画形态分维度分析
                    this.getAnalyseChart2(this.schoolOrgID, 'layout', this.termValue); // 章法占格分维度分析
                    this.getAnalyseChart3(this.schoolOrgID, 'structure', this.termValue); // 结构组合分维度分析
                    // console.log('有人工点评');
                    // 计算成绩最差的2个维度进行分析
                    let arr = [{
                            code: "complete",
                            name: '作品完整度',
                            value: this.allInfo.all_wzd_art
                        },
                        {
                            code: "form",
                            name: '笔画形态',
                            value: this.allInfo.all_xsx_art
                        },
                        {
                            code: "layout",
                            name: '章法占格',
                            value: this.allInfo.all_yzx_art
                        },
                        {
                            code: "structure",
                            name: '结构组合',
                            value: this.allInfo.all_zqd_art
                        },
                    ].sort((a, b) => a.value - b.value)
                    // console.log('计算成绩最差的2个维度进行分析', arr);
                    let data = {
                        codes: [arr[0].code, arr[1].code],
                        orgId: this.schoolOrgID,
                        termKey: this.termValue
                    };
                    let resData = await this.$Api.DataScreen.getwdComment(data);
                    // console.log('请求报告',resData);
                    this.comments_art1 = this.allComments_art[arr[0].code] + '需注意书写' + resData.data[arr[0].code]
                        .name + '问题,建议' + resData.data[arr[0].code].advice;
                    this.comments_art2 = this.allComments_art[arr[1].code] + '需注意书写' + resData.data[arr[1].code]
                        .name + '问题,建议' + resData.data[arr[1].code].advice
                } else {
                    // console.log('没有人工点评')
                }

            },
            initChart() {
                try {
                    this.$echarts.init(document.getElementById("myChart")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '笔画形态',
                                    max: 100
                                },
                                {
                                    name: '章法占格',
                                    max: 100
                                },
                                {
                                    name: '结构组合',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartOne,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart2() {
                // console.log('初始化echarts2')
                try {
                    this.$echarts.init(document.getElementById("myChart2")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart2"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '笔画形态',
                                    max: 100
                                },
                                {
                                    name: '章法占格',
                                    max: 100
                                },
                                {
                                    name: '结构组合',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartTwo,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart3() {
                // console.log('初始化echarts3')
                try {
                    this.$echarts.init(document.getElementById("myChart3")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart3"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '笔画形态',
                                    max: 100
                                },
                                {
                                    name: '章法占格',
                                    max: 100
                                },
                                {
                                    name: '结构组合',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartThree,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart4() {
                // console.log('初始化echarts4')
                try {
                    this.$echarts.init(document.getElementById("myChart4")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart4"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '笔画形态',
                                    max: 100
                                },
                                {
                                    name: '章法占格',
                                    max: 100
                                },
                                {
                                    name: '结构组合',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartFour,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart5() {
                // console.log('初始化echarts5')
                try {
                    this.$echarts.init(document.getElementById("myChart5")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart5"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '笔画形态',
                                    max: 100
                                },
                                {
                                    name: '章法占格',
                                    max: 100
                                },
                                {
                                    name: '结构组合',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartFive,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart6() {
                // console.log('初始化echarts6')
                try {
                    this.$echarts.init(document.getElementById("myChart6")).dispose();
                    let myChart = this.$echarts.init(document.getElementById("myChart6"));
                    myChart.setOption({
                        tooltip: {
                            show: true
                        },
                        radar: {
                            indicator: [{
                                    name: '作品完整度',
                                    max: 100
                                },
                                {
                                    name: '笔画形态',
                                    max: 100
                                },
                                {
                                    name: '章法占格',
                                    max: 100
                                },
                                {
                                    name: '结构组合',
                                    max: 100
                                }
                            ]
                        },
                        series: [{
                            symbolSize: 10,
                            symbol: "none",
                            itemStyle: {
                                borderColor: "rgba(66, 242, 185, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(66, 242, 185, 1)",
                            },
                            type: 'radar',
                            data: [{
                                // value: [60, 40, 76, 80],
                                value: this.chartSix,
                                name: '书写水平',
                                areaStyle: {
                                    color: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.5,
                                        colorStops: [{
                                                offset: 0,
                                                color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.15,
                                                color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 0.75,
                                                color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                            },
                                        ],
                                        global: false, // 缺省为 false
                                    },
                                },
                            }]
                        }]
                    })
                } catch (err) {
                    // console.log('initChart失败', err)
                }
            },
            initChart7(hold_right, hold_fail) {
                this.$echarts.init(document.getElementById("myChart7")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart7"));
                myChart.setOption({
                    title: {
                        text: '全校学生握笔姿势分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'center',
                        top: "10%"
                    },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: hold_right,
                                name: '正确握笔姿势',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_right + '%'
                                    },
                                },
                            },
                            {
                                value: hold_fail,
                                name: '错误握笔姿势',
                                itemStyle: {
                                    color: "#F86C6C"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#F86C6C', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_fail + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart8(xdata, data) {
                this.$echarts.init(document.getElementById("myChart8")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart8"));
                myChart.setOption({
                    title: {
                        text: '各年级握姿正确率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        data,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }]
                })
            },
            initChart9(posture_one, posture_two, posture_four) {
                this.$echarts.init(document.getElementById("myChart9")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart9"));
                myChart.setOption({
                    title: {
                        text: '全校学生错误握笔姿势分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    // legend: {
                    //     orient: 'horizontal',
                    //     left: 'center',
                    //     top: "10%"
                    // },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: posture_one,
                                name: '握姿1',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_one + '%'
                                    },
                                },
                            },
                            {
                                value: posture_two,
                                name: '握姿2',
                                itemStyle: {
                                    color: "#FAC858"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#FAC858', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_two + '%'
                                    },
                                },
                            },
                            {
                                value: posture_four,
                                name: '握姿4',
                                itemStyle: {
                                    color: "#5571C9"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#5571C9', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_four + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart10(hold_names, hold_series) {
                // console.log('hold_names:', hold_names);
                // console.log('hold_series:', hold_series);
                this.$echarts.init(document.getElementById("myChart10")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart10"));
                myChart.setOption({
                    title: {
                        text: '各年级学生握笔姿势分布',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'vertical',
                        right: 0, // 将图例放置在 grid 右边
                        top: 'middle',
                        data: hold_names
                    },
                    xAxis: {
                        type: 'category',
                        data: ['握姿1', '握姿2', '握姿4'],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: hold_series
                })
            },
            initChart11(hold_right, hold_fail) {
                this.$echarts.init(document.getElementById("myChart11")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart11"));
                myChart.setOption({
                    title: {
                        text: '全校学生坐姿分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'center',
                        top: "10%"
                    },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: hold_right,
                                name: '正确坐姿',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_right + '%'
                                    },
                                },
                            },
                            {
                                value: hold_fail,
                                name: '错误坐姿',
                                itemStyle: {
                                    color: "#F86C6C"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#F86C6C', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + hold_fail + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart12(xdata, data) {
                this.$echarts.init(document.getElementById("myChart12")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart12"));
                // console.log('xdata:', xdata)
                myChart.setOption({
                    title: {
                        text: '各年级坐姿正确率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        data,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }]
                })
            },
            initChart13(posture_one, posture_two, posture_four) {
                this.$echarts.init(document.getElementById("myChart13")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart13"));
                myChart.setOption({
                    title: {
                        text: '全校学生错误坐姿分布',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    // legend: {
                    //     orient: 'horizontal',
                    //     left: 'center',
                    //     top: "10%"
                    // },
                    series: [{
                        type: 'pie',
                        radius: '50%',
                        data: [{
                                value: posture_one,
                                name: '坐姿1',
                                itemStyle: {
                                    color: "#91CC75"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#91CC75', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_one + '%'
                                    },
                                },
                            },
                            {
                                value: posture_two,
                                name: '坐姿2',
                                itemStyle: {
                                    color: "#FAC858"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#FAC858', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_two + '%'
                                    },
                                },
                            },
                            {
                                value: posture_four,
                                name: '坐姿3',
                                itemStyle: {
                                    color: "#5571C9"
                                },
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#5571C9', // 设置文字颜色
                                        fontSize: 12 // 设置文字大小
                                    },
                                    formatter: function (params) {
                                        return params.name + '\n' + posture_four + '%'
                                    },
                                },
                            }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                })
            },
            initChart14(hold_names, hold_series) {
                this.$echarts.init(document.getElementById("myChart14")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart14"));
                myChart.setOption({
                    title: {
                        text: '各年级学生坐姿分布',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'vertical',
                        right: 0, // 将图例放置在 grid 右边
                        top: 'middle',
                        data: hold_names
                    },
                    xAxis: {
                        type: 'category',
                        data: ['坐姿1', '坐姿2', '坐姿3'],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: hold_series
                })
            },
            initChart15(xdata) {
                this.$echarts.init(document.getElementById("myChart15")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart15"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: ["作品完整度", "笔画形态", "章法占格", "结构组合"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        name: '前测平均水平',
                        data: xdata,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }, {
                        name: '全国常模',
                        data: [82.42, 79.82, 74.45, 73.40],
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        }
                    }],
                    legend: {
                        // data: ['前测平均水平','全国常模'],
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 10
                    },
                })
            },
            initChart16(series) {
                this.$echarts.init(document.getElementById("myChart16")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart16"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: ["左右结构", "上下结构", "独体结构", "包围结构"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: series,
                    legend: {
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 2
                    },
                })
            },
            initChart17(wzd_names, wzd_series) {
                // console.log("wzd_names:",wzd_names);
                // console.log("wzd_series:",wzd_series);
                this.$echarts.init(document.getElementById("myChart17")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart17"));
                myChart.setOption({
                    title: {
                        text: '作品完整度分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart18(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart18")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart18"));
                myChart.setOption({
                    title: {
                        text: '笔画形态成绩分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            // 章法占格成绩分析(ABC)
            initChart19(wzd_names, wzd_series) {
                // console.log('章法占格成绩分析(ABC):',wzd_names,wzd_series)
                this.$echarts.init(document.getElementById("myChart19")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart19"));
                myChart.setOption({
                    title: {
                        text: '章法占格成绩分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            // 结构组合成绩分析(ABC)
            initChart20(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart20")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart20"));
                myChart.setOption({
                    title: {
                        text: '结构组合成绩分析',
                        left: 'center'
                    },
                    grid: {
                        left: '3%',
                        right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart21(xdata) {
                this.$echarts.init(document.getElementById("myChart21")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart21"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}: {c}%'
                    },
                    xAxis: {
                        type: 'category',
                        data: ["作品完整度", "笔画形态", "章法占格", "结构组合"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: [{
                        name: '前测平均水平',
                        data: xdata,
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                            fontSize: 16
                        }
                    }, {
                        name: '全国常模',
                        data: [82.42, 79.82, 74.45, 73.40],
                        type: 'line',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        }
                    }],
                    legend: {
                        // data: ['前测平均水平','全国常模'],
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 10
                    },
                })
            },
            initChart22(wzd_names, wzd_series) {
                this.$echarts.init(document.getElementById("myChart22")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart22"));
                myChart.setOption({
                    title: {
                        text: '各年级书写辨析对比',
                        left: 'center'
                    },
                    grid: {
                        // left: '3%',
                        // right: '20%', // 将 grid 右边距设置为 20%
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        orient: 'horizontal',
                        bottom: 30
                    },
                    xAxis: {
                        type: 'category',
                        data: wzd_names,
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: 16
                        }
                    },
                    series: wzd_series
                })
            },
            initChart23(series) {
                this.$echarts.init(document.getElementById("myChart23")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart23"));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: ["左右结构", "上下结构", "独体结构", "包围结构"],
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: 16
                        }
                    },
                    series: series,
                    legend: {
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 2
                    },
                })
            },
            initChart24(series) {
                // console.log('series:',series);
                let xAxisData = [];
                let seriesData = [];
                series.map(item => {
                    if (item.name == '合计') {
                        return
                    } else {
                        xAxisData.push(item.name);
                        seriesData.push(item.total - item.posture_three)
                    }
                })
                this.$echarts.init(document.getElementById("myChart24")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart24"));
                myChart.setOption({
                    title: {
                        text: '握笔错误人数',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: seriesData,
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        }
                    }],
                    legend: {
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 2
                    },
                })
            },
            initChart25(series) {
                // console.log('series:',series);
                let xAxisData = [];
                let seriesData = [];
                series.map(item => {
                    if (item.name == '合计') {
                        return
                    } else {
                        xAxisData.push(item.name);
                        seriesData.push(item.total - item.sit_four)
                    }
                })
                this.$echarts.init(document.getElementById("myChart25")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart25"));
                myChart.setOption({
                    title: {
                        text: '坐姿错误人数',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: seriesData,
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        }
                    }],
                    legend: {
                        orient: 'horizontal', // 将图例放置到底部并水平排列
                        bottom: 2
                    },
                })
            },
            // 各年级 方向、笔性、粗细维度成绩分析
            initChart26(data) {
                // console.log('data:', data);
                let xAxis_data = [];
                let series_data = [];
                let fxobj = {
                    data: [],
                    type: 'bar',
                    name: '方向',
                }
                let bxobj = {
                    data: [],
                    type: 'bar',
                    name: '笔性',
                }
                let cxobj = {
                    data: [],
                    type: 'bar',
                    name: '粗细',
                }
                data.map(item => {
                    xAxis_data.push(item.name);
                    fxobj.data.push(item.fx_val.toFixed(2));
                    bxobj.data.push(item.bx_val.toFixed(2));
                    cxobj.data.push(item.cx_val.toFixed(2));
                })
                series_data = [fxobj, bxobj, cxobj];
                this.$echarts.init(document.getElementById("myChart26")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart26"));
                myChart.setOption({
                    title: {
                        text: '方向、笔性、粗细维度成绩分析',
                        left: 'center'
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxis_data
                    },
                    yAxis: {
                        type: 'value'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: series_data
                })
            },
            // 各年级 章法占格分析
            initChart27(data) {
                // console.log('data:', data);
                let xAxis_data = [];
                let series_data = [];
                let jmobj = {
                    data: [],
                    type: 'bar',
                    name: '卷面整洁',
                }
                let zgobj = {
                    data: [],
                    type: 'bar',
                    name: '占格占位',
                }
                let zxobj = {
                    data: [],
                    type: 'bar',
                    name: '字形大小',
                }
                data.map(item => {
                    xAxis_data.push(item.name);
                    jmobj.data.push(item.jm_val.toFixed(2));
                    zgobj.data.push(item.zg_val.toFixed(2));
                    zxobj.data.push(item.zx_val.toFixed(2));
                })
                series_data = [jmobj, zgobj, zxobj];
                // console.log('xAxis_data:',xAxis_data)
                // console.log('series_data:',series_data)
                this.$echarts.init(document.getElementById("myChart27")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart27"));
                myChart.setOption({
                    title: {
                        text: '章法占格分析',
                        left: 'center'
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxis_data
                    },
                    yAxis: {
                        type: 'value'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: series_data
                })
            },
            // 各年级 结构组合分维度分析
            initChart28(data) {
                // console.log('data:', data);
                let xAxis_data = [];
                let series_data = [];
                let zxobj = {
                    data: [],
                    type: 'bar',
                    name: '字形重心'
                }
                let zcobj = {
                    data: [],
                    type: 'bar',
                    name: '主次收放'
                }
                let bhobj = {
                    data: [],
                    type: 'bar',
                    name: '笔画关系'
                }
                let bjobj = {
                    data: [],
                    type: 'bar',
                    name: '部件关系'
                }
                data.map(item => {
                    xAxis_data.push(item.name);
                    zxobj.data.push(item.zx_val.toFixed(2));
                    zcobj.data.push(item.zc_val.toFixed(2));
                    bhobj.data.push(item.bh_val.toFixed(2));
                    bjobj.data.push(item.bj_val.toFixed(2));
                })
                series_data = [zxobj, zcobj, bhobj, bjobj];
                this.$echarts.init(document.getElementById("myChart28")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart28"));
                myChart.setOption({
                    title: {
                        text: '字形重心、主次收放、笔画关系、部件关系维度成绩分析',
                        left: 'center'
                    },
                    legend: {
                        orient: 'horizontal',
                        top: 25
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxis_data
                    },
                    yAxis: {
                        type: 'value'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: series_data
                })
            },
            // 整体-平台上课总次数
            initChart29(xAxisData, seriesData) {
                this.$echarts.init(document.getElementById("myChart29")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart29"));
                let option = {
                    title: {
                        text: '平台上课总次数Top10',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: seriesData,
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        }
                    }]
                };
                myChart.setOption(option)
            },
            // 整体报告表格
            async initTableData() {
                let data = {
                    chart: 4,
                    level: 5,
                    orgId: this.schoolOrgID,
                    attId: 26,
                    termKey: this.termValue
                }
                let resData = await this.$Api.DataScreen.queryTableData(data);
                console.log('各年级试卷数量(包含各年级orgId)---:', resData);
                let total = {
                    name: '合计',
                    allPage: 0,
                    count: 0,
                    result: 0,
                    failPage: 0
                }
                resData.data.map(item => {
                    item.allPage = item.count * 2 > item.result ? item.count * 2 : item.result;
                    item.failPage = item.allPage - item.result;
                    total.allPage += item.allPage;
                    total.count += item.count;
                    total.result += item.result;
                    total.failPage += item.failPage;

                    // 报告2.0新增,请求各年级一级维度评语
                    this.getwdCommentByClass(item.orgId, item.name);
                    // 获取各年级注册人数
                    this.getRegisterBygrade(item.orgId, item.name);
                    // 获取各年级优秀作品名单
                    this.getExcellentUsers('class', item.orgId, item.name)
                    // 获取各年级优秀作品
                    this.getExcellentWork('class', item.orgId, item.name)
                })
                // console.log(total);
                resData.data.push(total);
                this.tabalAll = resData.data;
                this.allInfo.stuNum = total.count;
                this.allInfo.pageNum = total.allPage;
            },
            // 整体报告注册人数
            async initTableData2() {
                let data = {
                    orgId: this.schoolOrgID,
                    termKey: this.termValue
                }
                let resData = await this.$Api.DataScreen.queryJoinRate(data);
                console.log('整体报告注册人数数据(新):', resData);

                let max = resData.data[0].joinCount == 0 ? 0 : (resData.data[0].joinCount / resData.data[0]
                    .registerCount).toFixed(2) * 100;
                let min = resData.data[0].joinCount == 0 ? 0 : (resData.data[0].joinCount / resData.data[0]
                    .registerCount).toFixed(2) * 100;
                let maxName = resData.data[0].termName;
                let minName = resData.data[0].termName;
                let total = {
                    termName: '合计',
                    joinCount: 0,
                    workCount: 0,
                }
                resData.data.map(item => {
                    total.joinCount += item.joinCount;
                    total.workCount += item.workCount;
                    item.joinRate = item.joinCount == 0 ? 0 : (item.joinCount / item.registerCount).toFixed(
                        2) * 100;
                    if (item.joinRate > max) {
                        max = item.joinRate;
                        maxName = item.termName
                    }
                    if (item.joinRate < min) {
                        min = item.joinRate;
                        minName = item.termName
                    }
                })
                this.maxRateName = maxName;
                this.minRateName = minName;
                this.allTableData = resData.data;
                console.log('total:', total)
                this.totalData = total;
                // resData.data.map(item => {
                //     total.joinCount += item.joinCount;
                //     total.workCount += item.workCount;
                // });
                // resData.data.push(total);
                // console.log('resData.data----',resData.data)
            },
            //判断用户角色组织
            async queryUserMaxOrg() {
                let resData = await this.$Api.Home.queryUserMaxOrg();
                // console.log("resData", resData);
                this.schoolOrgID = resData.data.orgId;
                this.schoolName = resData.data.name
                this.queryOrgInfo();
                this.initData();
                this.initTableData();
                this.initTableData2();
                this.getLessonCourseInfo();
                setTimeout(() => {
                    this.initChart();
                    this.initChart2();
                    this.initChart3();
                    this.initChart4();
                    this.initChart5();
                    this.initChart6();
                }, 1000)
            },
            // 获取年级列表
            async queryOrgInfo() {
                let data = {
                    orgId: this.schoolOrgID,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.queryOrgInfo(data);
                // console.log('年级列表:', resData)
                this.gradeList = resData.data.sonOrgList;
                let arr = [];
                let arr_after = [];
                let series = [];
                let wzd_names = []; //各年级作品完整度x轴
                let wzd_series = []; //各年级作品完整度series
                let xsx_series = []; //各年级笔画形态series
                let yzx_series = []; //各年级章法占格series
                let zqd_series = []; //各年级结构组合series
                let zql_series = []; //各年级234题正确率series
                let sx_series = []; //日常书写与刻意书写series
                let wzd_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let wzd_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let wzd_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let xsx_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let xsx_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let xsx_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let yzx_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let yzx_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let yzx_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zqd_a = {
                    data: [],
                    type: 'bar',
                    name: 'A(80-100)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zqd_b = {
                    data: [],
                    type: 'bar',
                    name: 'B(65-80)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zqd_c = {
                    data: [],
                    type: 'bar',
                    name: 'C(0-65)',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zql_q2 = {
                    data: [],
                    type: 'bar',
                    name: '生字音形义辨析',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zql_q3 = {
                    data: [],
                    type: 'bar',
                    name: '不同结构生字辨析',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let zql_q4 = {
                    data: [],
                    type: 'bar',
                    name: '不同笔顺笔画生字辨析',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let rcsx = {
                    data: [],
                    type: 'bar',
                    name: '日常书写',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                let kysx = {
                    data: [],
                    type: 'bar',
                    name: '刻意书写',
                    label: {
                        show: true,
                        position: 'top',
                        formatter: function (params) {
                            return params.value + '%'
                        },
                    },
                    barWidth: 20
                };
                // 前测整体报告(各个年级数据)
                const promises = this.gradeList.map(item => {
                    let data = {
                        chart: 4,
                        level: 6, // 5学校 6年级 7班级
                        orgId: item.orgId,
                        termKey: this.termValue
                    }
                    return this.$Api.DataScreen.basicsStatistics(data)
                        .then(res => {
                            // console.log('res:',res)
                            res.name = item.name;
                            res.orgId = item.orgId;
                            return res
                        })
                })
                Promise.all(promises)
                    .then(res => {
                        console.log('Promise.all各年级数据:', res);
                        let woziAll = [];
                        let sitAll = [];
                        let total_hold = {
                            name: '合计',
                            posture_one: 0,
                            posture_two: 0,
                            posture_three: 0,
                            posture_four: 0
                        }
                        let total_sit = {
                            name: '合计',
                            sit_one: 0,
                            sit_two: 0,
                            sit_three: 0,
                            sit_four: 0,
                        }
                        let hold_xdata = [];
                        let hold_data = [];
                        let hold_series = [];
                        let hold_names = [];
                        let sit_xdata = [];
                        let sit_data = [];
                        let sit_series = [];
                        let sit_names = [];

                        res.map(item => {
                            if (JSON.stringify(item.data) !== '{}') {
                                arr.push(item.name);
                                if (item.name == '一年级') {
                                    this.gradeOne = item.data;
                                    let arr = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ]
                                    let arr2 = [];
                                    try {
                                        arr2 = [
                                            item.data.complete_art.count == 0 ? 0 : (item.data
                                                .complete_art.result / item.data.complete_art.count)
                                            .toFixed(2), // 作品完整度
                                            item.data.form_art.count == 0 ? 0 : (item.data.form_art
                                                .result / item.data.form_art.count).toFixed(
                                                2), //笔画形态
                                            item.data.layout_art.count == 0 ? 0 : (item.data
                                                .layout_art.result / item.data.layout_art.count)
                                            .toFixed(2), //章法占格
                                            item.data.structure_art.count == 0 ? 0 : (item.data
                                                .structure_art.result / item.data.structure_art
                                                .count).toFixed(2), // 结构组合
                                        ]
                                    } catch (err) {
                                        arr2 = []
                                    }
                                    this.chartOne = item.data.complete_art ? arr2 : arr;

                                    if (this.chartOne[0] < 86) {
                                        this.chartOne_str += '作品完整度,';
                                        this.chartOne_count += 1
                                    }
                                    if (this.chartOne[1] < 78) {
                                        this.chartOne_str += '笔画形态,';
                                        this.chartOne_count += 1
                                    }
                                    if (this.chartOne[3] < 75) {
                                        this.chartOne_str += '结构组合,';
                                        this.chartOne_count += 1
                                    }
                                    if (this.chartOne[2] < 76) {
                                        this.chartOne_str += '章法占格,';
                                        this.chartOne_count += 1
                                    }
                                    let wzObj = {
                                        name: '1年级',
                                        posture_one: this.gradeOne.dimension_a_1.result,
                                        posture_two: this.gradeOne.dimension_a_2.result,
                                        posture_three: this.gradeOne.dimension_a_3.result,
                                        posture_four: this.gradeOne.dimension_a_4.result
                                    };
                                    console.log('chartOne_str---', this.chartOne_str)
                                    let zzObj = {
                                        name: '1年级',
                                        sit_one: this.gradeOne.dimension_b_1.result,
                                        sit_two: this.gradeOne.dimension_b_2.result,
                                        sit_three: this.gradeOne.dimension_b_3.result,
                                        sit_four: this.gradeOne.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                    this.getBadweByGrade(item.data, item.orgId, item.name)
                                    try {
                                        // 报告2.0新增,计算各年级成绩最差一级维度
                                        let arr = [{
                                                code: "complete",
                                                name: '作品完整度',
                                                value: (item.data.complete_art.result / item.data
                                                    .complete_art.count).toFixed(2)
                                            },
                                            {
                                                code: "form",
                                                name: '笔画形态',
                                                value: (item.data.form_art.result / item.data
                                                    .form_art
                                                    .count).toFixed(2)
                                            },
                                            {
                                                code: "layout",
                                                name: '章法占格',
                                                value: (item.data.layout_art.result / item.data
                                                    .layout_art.count).toFixed(2)
                                            },
                                            {
                                                code: "structure",
                                                name: '结构组合',
                                                value: (item.data.structure_art.result / item.data
                                                    .structure_art.count).toFixed(2)
                                            },
                                        ].sort((a, b) => a.value - b.value)
                                        console.log(item.name + '最差一级维度是:' + arr[0].name + 'orgId是:' +
                                            item.orgId)
                                        this.getGradeCommit([arr[0].code], item.orgId, item.name, arr[0]
                                            .name)
                                    } catch (err) {
                                        // console.log(item.name + '没有人工点评数据')
                                    }

                                } else if (item.name == '二年级') {
                                    this.gradeTwo = item.data;
                                    let arr = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ]
                                    let arr2 = [];
                                    try {
                                        arr2 = [
                                            item.data.complete_art.count == 0 ? 0 : (item.data
                                                .complete_art.result / item.data.complete_art.count)
                                            .toFixed(2), // 作品完整度
                                            item.data.form_art.count == 0 ? 0 : (item.data.form_art
                                                .result / item.data.form_art.count).toFixed(
                                                2), //笔画形态
                                            item.data.layout_art.count == 0 ? 0 : (item.data
                                                .layout_art.result / item.data.layout_art.count)
                                            .toFixed(2), //章法占格
                                            item.data.structure_art.count == 0 ? 0 : (item.data
                                                .structure_art.result / item.data.structure_art
                                                .count).toFixed(2), // 结构组合
                                        ]
                                    } catch (err) {
                                        arr2 = []
                                    }
                                    this.chartTwo = item.data.complete_art ? arr2 : arr;
                                    if (this.chartTwo[0] < 86) {
                                        this.chartTwo_str += '作品完整度,';
                                        this.chartTwo_count += 1
                                    }
                                    if (this.chartTwo[1] < 78) {
                                        this.chartTwo_str += '笔画形态,';
                                        this.chartTwo_count += 1
                                    }
                                    if (this.chartTwo[3] < 75) {
                                        this.chartTwo_str += '结构组合,';
                                        this.chartTwo_count += 1
                                    }
                                    if (this.chartTwo[2] < 76) {
                                        this.chartTwo_str += '章法占格,';
                                        this.chartTwo_count += 1
                                    }
                                    let wzObj = {
                                        name: '2年级',
                                        posture_one: this.gradeTwo.dimension_a_1.result,
                                        posture_two: this.gradeTwo.dimension_a_2.result,
                                        posture_three: this.gradeTwo.dimension_a_3.result,
                                        posture_four: this.gradeTwo.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '2年级',
                                        sit_one: this.gradeTwo.dimension_b_1.result,
                                        sit_two: this.gradeTwo.dimension_b_2.result,
                                        sit_three: this.gradeTwo.dimension_b_3.result,
                                        sit_four: this.gradeTwo.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                    this.getBadweByGrade(item.data, item.orgId, item.name)
                                    try {
                                        // 报告2.0新增,计算各年级成绩最差一级维度
                                        let arr = [{
                                                code: "complete",
                                                name: '作品完整度',
                                                value: (item.data.complete_art.result / item.data
                                                    .complete_art.count).toFixed(2)
                                            },
                                            {
                                                code: "form",
                                                name: '笔画形态',
                                                value: (item.data.form_art.result / item.data
                                                    .form_art
                                                    .count).toFixed(2)
                                            },
                                            {
                                                code: "layout",
                                                name: '章法占格',
                                                value: (item.data.layout_art.result / item.data
                                                    .layout_art.count).toFixed(2)
                                            },
                                            {
                                                code: "structure",
                                                name: '结构组合',
                                                value: (item.data.structure_art.result / item.data
                                                    .structure_art.count).toFixed(2)
                                            },
                                        ].sort((a, b) => a.value - b.value)
                                        // console.log(item.name + '最差一级维度是:' + arr[0].name +'orgId是:'+item.orgId)
                                        this.getGradeCommit([arr[0].code], item.orgId, item.name, arr[0]
                                            .name)
                                    } catch (err) {
                                        // console.log(item.name + '没有人工点评数据')
                                    }
                                } else if (item.name == '三年级') {
                                    this.gradeThree = item.data;
                                    let arr = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ]
                                    let arr2 = [];
                                    try {
                                        arr2 = [
                                            item.data.complete_art.count == 0 ? 0 : (item.data
                                                .complete_art.result / item.data.complete_art.count)
                                            .toFixed(2), // 作品完整度
                                            item.data.form_art.count == 0 ? 0 : (item.data.form_art
                                                .result / item.data.form_art.count).toFixed(
                                                2), //笔画形态
                                            item.data.layout_art.count == 0 ? 0 : (item.data
                                                .layout_art.result / item.data.layout_art.count)
                                            .toFixed(2), //章法占格
                                            item.data.structure_art.count == 0 ? 0 : (item.data
                                                .structure_art.result / item.data.structure_art
                                                .count).toFixed(2), // 结构组合
                                        ]
                                    } catch (err) {
                                        arr2 = []
                                    }
                                    this.chartThree = item.data.complete_art ? arr2 : arr;
                                    if (this.chartThree[0] < 90) {
                                        this.chartThree_str += '作品完整度,';
                                        this.chartThree_count += 1
                                    }
                                    if (this.chartThree[1] < 83) {
                                        this.chartThree_str += '笔画形态,';
                                        this.chartThree_count += 1
                                    }
                                    if (this.chartThree[2] < 78) {
                                        this.chartThree_str += '结构组合,';
                                        this.chartThree_count += 1
                                    }
                                    if (this.chartThree[3] < 79) {
                                        this.chartThree_str += '章法占格,';
                                        this.chartThree_count += 1
                                    }
                                    let wzObj = {
                                        name: '3年级',
                                        posture_one: this.gradeThree.dimension_a_1.result,
                                        posture_two: this.gradeThree.dimension_a_2.result,
                                        posture_three: this.gradeThree.dimension_a_3.result,
                                        posture_four: this.gradeThree.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '3年级',
                                        sit_one: this.gradeThree.dimension_b_1.result,
                                        sit_two: this.gradeThree.dimension_b_2.result,
                                        sit_three: this.gradeThree.dimension_b_3.result,
                                        sit_four: this.gradeThree.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                    this.getBadweByGrade(item.data, item.orgId, item.name)
                                    try {
                                        // 报告2.0新增,计算各年级成绩最差一级维度
                                        let arr = [{
                                                code: "complete",
                                                name: '作品完整度',
                                                value: (item.data.complete_art.result / item.data
                                                    .complete_art.count).toFixed(2)
                                            },
                                            {
                                                code: "form",
                                                name: '笔画形态',
                                                value: (item.data.form_art.result / item.data
                                                    .form_art
                                                    .count).toFixed(2)
                                            },
                                            {
                                                code: "layout",
                                                name: '章法占格',
                                                value: (item.data.layout_art.result / item.data
                                                    .layout_art.count).toFixed(2)
                                            },
                                            {
                                                code: "structure",
                                                name: '结构组合',
                                                value: (item.data.structure_art.result / item.data
                                                    .structure_art.count).toFixed(2)
                                            },
                                        ].sort((a, b) => a.value - b.value)
                                        // console.log(item.name + '最差一级维度是:' + arr[0].name +'orgId是:'+item.orgId)
                                        this.getGradeCommit([arr[0].code], item.orgId, item.name, arr[0]
                                            .name)
                                    } catch (err) {
                                        // console.log(item.name + '没有人工点评数据')
                                    }
                                } else if (item.name == '四年级') {
                                    this.gradeFour = item.data;
                                    let arr = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ]
                                    let arr2 = [];
                                    try {
                                        arr2 = [
                                            item.data.complete_art.count == 0 ? 0 : (item.data
                                                .complete_art.result / item.data.complete_art.count)
                                            .toFixed(2), // 作品完整度
                                            item.data.form_art.count == 0 ? 0 : (item.data.form_art
                                                .result / item.data.form_art.count).toFixed(
                                                2), //笔画形态
                                            item.data.layout_art.count == 0 ? 0 : (item.data
                                                .layout_art.result / item.data.layout_art.count)
                                            .toFixed(2), //章法占格
                                            item.data.structure_art.count == 0 ? 0 : (item.data
                                                .structure_art.result / item.data.structure_art
                                                .count).toFixed(2), // 结构组合
                                        ]
                                    } catch (err) {
                                        arr2 = []
                                    }

                                    this.chartFour = item.data.complete_art ? arr2 : arr;
                                    if (this.chartFour[0] < 90) {
                                        this.chartFour_str += '作品完整度,';
                                        this.chartFour_count += 1
                                    }
                                    if (this.chartFour[1] < 83) {
                                        this.chartFour_str += '笔画形态,';
                                        this.chartFour_count += 1
                                    }
                                    if (this.chartFour[3] < 78) {
                                        this.chartFour_str += '结构组合,';
                                        this.chartFour_count += 1
                                    }
                                    if (this.chartFour[2] < 79) {
                                        this.chartFour_str += '章法占格,';
                                        this.chartFour_count += 1
                                    }
                                    let wzObj = {
                                        name: '4年级',
                                        posture_one: this.gradeFour.dimension_a_1.result,
                                        posture_two: this.gradeFour.dimension_a_2.result,
                                        posture_three: this.gradeFour.dimension_a_3.result,
                                        posture_four: this.gradeFour.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '4年级',
                                        sit_one: this.gradeFour.dimension_b_1.result,
                                        sit_two: this.gradeFour.dimension_b_2.result,
                                        sit_three: this.gradeFour.dimension_b_3.result,
                                        sit_four: this.gradeFour.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                    this.getBadweByGrade(item.data, item.orgId, item.name)
                                    try {
                                        // 报告2.0新增,计算各年级成绩最差一级维度
                                        let arr = [{
                                                code: "complete",
                                                name: '作品完整度',
                                                value: (item.data.complete_art.result / item.data
                                                    .complete_art.count).toFixed(2)
                                            },
                                            {
                                                code: "form",
                                                name: '笔画形态',
                                                value: (item.data.form_art.result / item.data
                                                    .form_art
                                                    .count).toFixed(2)
                                            },
                                            {
                                                code: "layout",
                                                name: '章法占格',
                                                value: (item.data.layout_art.result / item.data
                                                    .layout_art.count).toFixed(2)
                                            },
                                            {
                                                code: "structure",
                                                name: '结构组合',
                                                value: (item.data.structure_art.result / item.data
                                                    .structure_art.count).toFixed(2)
                                            },
                                        ].sort((a, b) => a.value - b.value)
                                        // console.log(item.name + '最差一级维度是:' + arr[0].name +'orgId是:'+item.orgId)
                                        this.getGradeCommit([arr[0].code], item.orgId, item.name, arr[0]
                                            .name)
                                    } catch (err) {
                                        // console.log(item.name + '没有人工点评数据')
                                    }
                                } else if (item.name == '五年级') {
                                    this.gradeFive = item.data;
                                    let arr = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ]
                                    let arr2 = [];
                                    try {
                                        arr2 = [
                                            item.data.complete_art.count == 0 ? 0 : (item.data
                                                .complete_art.result / item.data.complete_art.count)
                                            .toFixed(2), // 作品完整度
                                            item.data.form_art.count == 0 ? 0 : (item.data.form_art
                                                .result / item.data.form_art.count).toFixed(
                                                2), //笔画形态
                                            item.data.layout_art.count == 0 ? 0 : (item.data
                                                .layout_art.result / item.data.layout_art.count)
                                            .toFixed(2), //章法占格
                                            item.data.structure_art.count == 0 ? 0 : (item.data
                                                .structure_art.result / item.data.structure_art
                                                .count).toFixed(2), // 结构组合
                                        ]
                                    } catch (err) {
                                        arr2 = []
                                    }
                                    this.chartFive = item.data.complete_art ? arr2 : arr;
                                    if (this.chartFive[0] < 92) {
                                        this.chartFive_str += '作品完整度,';
                                        this.chartFive_count += 1
                                    }
                                    if (this.chartFive[1] < 85) {
                                        this.chartFive_str += '笔画形态,';
                                        this.chartFive_count += 1
                                    }
                                    if (this.chartFive[3] < 81) {
                                        this.chartFive_str += '结构组合,';
                                        this.chartFive_count += 1
                                    }
                                    if (this.chartFive[2] < 82) {
                                        this.chartFive_str += '章法占格,';
                                        this.chartFive_count += 1
                                    }
                                    let wzObj = {
                                        name: '5年级',
                                        posture_one: this.gradeFive.dimension_a_1.result,
                                        posture_two: this.gradeFive.dimension_a_2.result,
                                        posture_three: this.gradeFive.dimension_a_3.result,
                                        posture_four: this.gradeFive.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '5年级',
                                        sit_one: this.gradeFive.dimension_b_1.result,
                                        sit_two: this.gradeFive.dimension_b_2.result,
                                        sit_three: this.gradeFive.dimension_b_3.result,
                                        sit_four: this.gradeFive.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                    this.getBadweByGrade(item.data, item.orgId, item.name)
                                    try {
                                        // 报告2.0新增,计算各年级成绩最差一级维度
                                        let arr = [{
                                                code: "complete",
                                                name: '作品完整度',
                                                value: (item.data.complete_art.result / item.data
                                                    .complete_art.count).toFixed(2)
                                            },
                                            {
                                                code: "form",
                                                name: '笔画形态',
                                                value: (item.data.form_art.result / item.data
                                                    .form_art
                                                    .count).toFixed(2)
                                            },
                                            {
                                                code: "layout",
                                                name: '章法占格',
                                                value: (item.data.layout_art.result / item.data
                                                    .layout_art.count).toFixed(2)
                                            },
                                            {
                                                code: "structure",
                                                name: '结构组合',
                                                value: (item.data.structure_art.result / item.data
                                                    .structure_art.count).toFixed(2)
                                            },
                                        ].sort((a, b) => a.value - b.value)
                                        // console.log(item.name + '最差一级维度是:' + arr[0].name +'orgId是:'+item.orgId)
                                        this.getGradeCommit([arr[0].code], item.orgId, item.name, arr[0]
                                            .name)
                                    } catch (err) {
                                        // console.log(item.name + '没有人工点评数据')
                                    }
                                } else if (item.name == '六年级') {
                                    this.gradeSix = item.data;
                                    let arr = [(item.data.dimension_c_4.result * 100 / item.data
                                            .dimension_c_4.count).toFixed(2),
                                        (item.data.dimension_d_4.result / item.data.dimension_d_4
                                            .count).toFixed(2),
                                        (item.data.dimension_e_4.result / item.data.dimension_e_4
                                            .count).toFixed(2),
                                        (item.data.dimension_f_1.result / item.data.dimension_f_1
                                            .count).toFixed(2)
                                    ]
                                    let arr2 = [];
                                    try {
                                        arr2 = [
                                            item.data.complete_art.count == 0 ? 0 : (item.data
                                                .complete_art.result / item.data.complete_art.count)
                                            .toFixed(2), // 作品完整度
                                            item.data.form_art.count == 0 ? 0 : (item.data.form_art
                                                .result / item.data.form_art.count).toFixed(
                                                2), //笔画形态
                                            item.data.layout_art.count == 0 ? 0 : (item.data
                                                .layout_art.result / item.data.layout_art.count)
                                            .toFixed(2), //章法占格
                                            item.data.structure_art.count == 0 ? 0 : (item.data
                                                .structure_art.result / item.data.structure_art
                                                .count).toFixed(2), // 结构组合
                                        ]
                                    } catch (err) {
                                        arr2 = []
                                    }
                                    this.chartSix = item.data.complete_art ? arr2 : arr;
                                    if (this.chartSix[0] < 92) {
                                        this.chartSix_str += '作品完整度,';
                                        this.chartSix_count += 1
                                    }
                                    if (this.chartSix[1] < 85) {
                                        this.chartSix_str += '笔画形态,';
                                        this.chartSix_count += 1
                                    }
                                    if (this.chartSix[3] < 81) {
                                        this.chartSix_str += '结构组合,';
                                        this.chartSix_count += 1
                                    }
                                    if (this.chartSix[2] < 82) {
                                        this.chartSix_str += '章法占格,';
                                        this.chartSix_count += 1
                                    }
                                    let wzObj = {
                                        name: '6年级',
                                        posture_one: this.gradeSix.dimension_a_1.result,
                                        posture_two: this.gradeSix.dimension_a_2.result,
                                        posture_three: this.gradeSix.dimension_a_3.result,
                                        posture_four: this.gradeSix.dimension_a_4.result
                                    };
                                    let zzObj = {
                                        name: '6年级',
                                        sit_one: this.gradeSix.dimension_b_1.result,
                                        sit_two: this.gradeSix.dimension_b_2.result,
                                        sit_three: this.gradeSix.dimension_b_3.result,
                                        sit_four: this.gradeSix.dimension_b_4.result,
                                    };
                                    woziAll.push(wzObj);
                                    sitAll.push(zzObj);
                                    this.getBadweByGrade(item.data, item.orgId, item.name)
                                    try {
                                        // 报告2.0新增,计算各年级成绩最差一级维度
                                        let arr = [{
                                                code: "complete",
                                                name: '作品完整度',
                                                value: (item.data.complete_art.result / item.data
                                                    .complete_art.count).toFixed(2)
                                            },
                                            {
                                                code: "form",
                                                name: '笔画形态',
                                                value: (item.data.form_art.result / item.data
                                                    .form_art
                                                    .count).toFixed(2)
                                            },
                                            {
                                                code: "layout",
                                                name: '章法占格',
                                                value: (item.data.layout_art.result / item.data
                                                    .layout_art.count).toFixed(2)
                                            },
                                            {
                                                code: "structure",
                                                name: '结构组合',
                                                value: (item.data.structure_art.result / item.data
                                                    .structure_art.count).toFixed(2)
                                            },
                                        ].sort((a, b) => a.value - b.value)
                                        // console.log(item.name + '最差一级维度是:' + arr[0].name +'orgId是:'+item.orgId)
                                        this.getGradeCommit([arr[0].code], item.orgId, item.name, arr[0]
                                            .name)
                                    } catch (err) {
                                        // console.log(item.name + '没有人工点评数据')
                                    }
                                }
                                let obj = {
                                    name: item.name,
                                    data: [],
                                    type: 'line',
                                    smooth: true
                                }
                                let zuoyou = item.data.xsx_zuoyou.count ? (item.data.xsx_zuoyou.result /
                                    item.data.xsx_zuoyou.count).toFixed(2) : 0;
                                let shangxia = item.data.xsx_shangxia.count ? (item.data.xsx_shangxia
                                    .result / item.data.xsx_shangxia.count).toFixed(2) : 0;
                                let duti = item.data.xsx_duti.count ? (item.data.xsx_duti.result / item
                                    .data.xsx_duti.count).toFixed(2) : 0;
                                let baowei = item.data.xsx_baowei.count ? (item.data.xsx_baowei.result /
                                    item.data.xsx_baowei.count).toFixed(2) : 0;
                                obj.data.push(zuoyou, shangxia, duti, baowei);
                                this.jgTable.zy_data.push(zuoyou);
                                this.jgTable.sx_data.push(shangxia);
                                this.jgTable.dt_data.push(duti);
                                this.jgTable.bw_data.push(baowei);
                                series.push(obj);
                                // 各年级作品完整度柱状图
                                wzd_names.push(item.name);
                                wzd_a.data.push((item.data.complete_art_a.count * 100 / item.data
                                    .complete_art.count).toFixed(2));
                                wzd_b.data.push((item.data.complete_art_b.count * 100 / item.data
                                    .complete_art.count).toFixed(2));
                                wzd_c.data.push((item.data.complete_art_c.count * 100 / item.data
                                    .complete_art.count).toFixed(2));
                                xsx_a.data.push((item.data.form_art_a.count * 100 / item.data.form_art
                                    .count).toFixed(2));
                                xsx_b.data.push((item.data.form_art_b.count * 100 / item.data.form_art
                                    .count).toFixed(2));
                                xsx_c.data.push((item.data.form_art_c.count * 100 / item.data.form_art
                                    .count).toFixed(2));

                                zqd_a.data.push((item.data.structure_art_a.count * 100 / item.data
                                    .structure_art.count).toFixed(2));
                                zqd_b.data.push((item.data.structure_art_b.count * 100 / item.data
                                    .structure_art.count).toFixed(2));
                                zqd_c.data.push((item.data.structure_art_c.count * 100 / item.data
                                    .structure_art.count).toFixed(2));

                                yzx_a.data.push((item.data.layout_art_a.count * 100 / item.data
                                    .layout_art.count).toFixed(2));
                                yzx_b.data.push((item.data.layout_art_b.count * 100 / item.data
                                    .layout_art.count).toFixed(2));
                                yzx_c.data.push((item.data.layout_art_c.count * 100 / item.data
                                    .layout_art.count).toFixed(2));

                                // if(JSON.stringify(item.data) == '{}')
                                zql_q2.data.push({
                                    value: item.data.q2_zql.count ? (item.data.q2_zql.result *
                                        100 / item.data.q2_zql.count).toFixed(2) : 0,
                                    name: item.name
                                })
                                zql_q3.data.push({
                                    value: item.data.q3_zql.count ? (item.data.q3_zql.result *
                                        100 / item.data.q3_zql.count).toFixed(2) : 0,
                                    name: item.name
                                })
                                zql_q4.data.push({
                                    value: item.data.q4_zql.count ? (item.data.q4_zql.result *
                                        100 / item.data.q4_zql.count).toFixed(2) : 0,
                                    name: item.name
                                })
                                rcsx.data.push({
                                    value: item.data.dimension_f_4.count ? (item.data
                                        .dimension_f_4.result / item.data.dimension_f_4
                                        .count).toFixed(2) : 0,
                                    name: item.name
                                })
                                kysx.data.push({
                                    value: item.data.dimension_d_4.count ? (item.data
                                        .dimension_d_4.result / item.data.dimension_d_4
                                        .count).toFixed(2) : 0,
                                    name: item.name
                                })

                            } else {
                                // console.log('当前年级没有前测数据:', item.name);
                                // wzd_names.push(item.name);
                                // zql_q2.data.push({
                                //     value: 0,
                                //     name: item.name
                                // })
                                // zql_q3.data.push({
                                //     value: 0,
                                //     name: item.name
                                // })
                                // zql_q4.data.push({
                                //     value: 0,
                                //     name: item.name
                                // })
                                // rcsx.data.push({
                                //     value: 0,
                                //     name: item.name
                                // })
                                // kysx.data.push({
                                //     value: 0,
                                //     name: item.name
                                // })
                            }
                        })

                        let arr2 = [];
                        this.gradeList.map(item => {
                            arr.map(items => {
                                if (item.name == items) {
                                    arr2.push(item)
                                }
                            })
                        })
                        // console.log('arr2:', arr2)
                        this.gradeList = arr2;
                        // 握姿series
                        woziAll.map(item => {
                            total_hold.posture_one += item.posture_one,
                                total_hold.posture_two += item.posture_two,
                                total_hold.posture_three += item
                                .posture_three,
                                total_hold.posture_four += item.posture_four,
                                item.total = item.posture_four + item.posture_one + item
                                .posture_two + item.posture_three,
                                item.rightRatio = (item.posture_three / item.total *
                                    100)
                                .toFixed(2),
                                hold_xdata.push(item.name);
                            hold_data.push(item.rightRatio);
                            // 计算各年级错误握姿统计
                            let obj = {
                                name: item.name,
                                data: [item.posture_one, item.posture_two, item
                                    .posture_four
                                ],
                                type: 'bar',
                                label: {
                                    show: true,
                                    position: 'top'
                                },
                                barWidth: 20
                            }
                            hold_series.push(obj);
                            hold_names.push(item.name);
                        })
                        sitAll.map(item => {
                            total_sit.sit_one += item.sit_one,
                                total_sit.sit_two += item.sit_two,
                                total_sit.sit_three += item.sit_three,
                                total_sit.sit_four += item.sit_four,
                                item.total = item.sit_four + item.sit_one + item
                                .sit_two +
                                item.sit_three,
                                item.rightRatio = (item.sit_four / item.total * 100)
                                .toFixed(2),
                                sit_xdata.push(item.name);
                            sit_data.push(item.rightRatio);
                            let obj = {
                                name: item.name,
                                data: [item.sit_one, item.sit_two, item.sit_three],
                                type: 'bar',
                                label: {
                                    show: true,
                                    position: 'top'
                                },
                                barWidth: 20
                            }
                            sit_series.push(obj);
                            sit_names.push(item.name);
                        })
                        // 计算全校握姿
                        let hold_total = total_hold.posture_one + total_hold
                            .posture_two + total_hold.posture_three + total_hold
                            .posture_four;
                        let hold_right = (total_hold.posture_three / hold_total * 100).toFixed(2);
                        console.log('正确数:', total_hold.posture_three);
                        console.log('总人数:', hold_total * 100);
                        // console.log('正确比例:',(total_hold.posture_three / hold_total *100).toFixed(2))
                        let hold_fail = 100 - hold_right;
                        this.holdInfo.allNum = hold_total;
                        this.holdInfo.rightNnm = total_hold.posture_three;
                        this.holdInfo.failNum = hold_total - total_hold.posture_three;
                        this.holdInfo.rightRatio = hold_right;
                        this.holdInfo.faliRatio = hold_fail.toFixed(2);
                        this.initChart7(hold_right, hold_fail.toFixed(2));
                        // 计算全校坐姿
                        let sit_total = total_sit.sit_one + total_sit.sit_two + total_sit
                            .sit_three + total_sit.sit_four;
                        let sit_right = (total_sit.sit_four / sit_total * 100).toFixed(2);
                        let sit_fail = (100 - sit_right).toFixed(2);
                        this.sitInfo.allNum = sit_total;
                        this.sitInfo.rightNum = total_sit.sit_four;
                        this.sitInfo.failNum = sit_total - total_sit.sit_four;
                        this.sitInfo.rightRatio = sit_right;
                        this.sitInfo.faliRatio = sit_fail;
                        this.initChart11(sit_right, sit_fail);
                        // 计算各年级学生握笔姿势正确率
                        let minValue = hold_data[0];
                        let minIndex = 0;
                        let maxValue = hold_data[0];
                        let maxIndex = 0;
                        for (let i = 1; i < hold_data.length; i++) {
                            if (Number(hold_data[i]) < minValue) {
                                minValue = Number(hold_data[i]);
                                minIndex = i;
                            } else if (Number(hold_data[i]) > maxValue) {
                                maxValue = Number(hold_data[i]);
                                maxIndex = i;
                            }
                        }
                        let sitMinValue = sit_data[0];
                        let sitMinIndex = 0;
                        let sitMaxValue = sit_data[0];
                        let sitMaxIndex = 0;
                        for (let i = 1; i < sit_data.length; i++) {
                            if (Number(sit_data[i]) < sitMinValue) {
                                sitMinValue = Number(sit_data[i]);
                                sitMinIndex = i;
                            } else if (Number(sit_data[i]) > sitMaxValue) {
                                sitMaxValue = Number(sit_data[i]);
                                sitMaxIndex = i;
                            }
                        }

                        this.holdInfo.highName = hold_xdata[maxIndex];
                        this.holdInfo.highRatio = maxValue;
                        this.holdInfo.lowName = hold_xdata[minIndex];
                        this.holdInfo.lowRatio = minValue;
                        this.sitInfo.highName = sit_xdata[sitMaxIndex];
                        this.sitInfo.highRatio = sitMaxValue;
                        this.sitInfo.lowName = sit_xdata[sitMinIndex];
                        this.sitInfo.lowRatio = sitMinValue;
                        this.initChart8(hold_xdata, hold_data);
                        this.initChart12(sit_xdata, sit_data);

                        // 计算全校学生错误握笔姿势分布
                        this.holdInfo.hold_1 = total_hold.posture_one;
                        this.holdInfo.hold_2 = total_hold.posture_two;
                        this.holdInfo.hold_4 = total_hold.posture_four;
                        this.holdInfo.ratio_1 = (total_hold.posture_one / this.holdInfo
                            .failNum *
                            100).toFixed(2);
                        this.holdInfo.ratio_2 = (total_hold.posture_two / this.holdInfo
                            .failNum *
                            100).toFixed(2);
                        this.holdInfo.ratio_4 = (total_hold.posture_four / this.holdInfo
                            .failNum *
                            100).toFixed(2);
                        this.sitInfo.hold_1 = total_sit.sit_one;
                        this.sitInfo.hold_2 = total_sit.sit_two;
                        this.sitInfo.hold_4 = total_sit.sit_three;
                        this.sitInfo.ratio_1 = (total_sit.sit_one / this.sitInfo.failNum * 100)
                            .toFixed(2);
                        this.sitInfo.ratio_2 = (total_sit.sit_two / this.sitInfo.failNum * 100)
                            .toFixed(2);
                        this.sitInfo.ratio_4 = (total_sit.sit_three / this.sitInfo.failNum *
                                100)
                            .toFixed(2);
                        this.initChart9(this.holdInfo.ratio_1, this.holdInfo.ratio_2, this
                            .holdInfo
                            .ratio_4);
                        this.initChart13(this.sitInfo.ratio_1, this.sitInfo.ratio_2, this
                            .sitInfo
                            .ratio_4);
                        // console.log('柱状图name:', sit_names)
                        // console.log('柱状图data:', sit_series);
                        console.log('total_hold---:',total_hold)
                        let max = Object.keys(total_hold)
                            .filter(key => key.startsWith("posture") && key !== "posture_three")
                            .reduce((a, b) => total_hold[a] > total_hold[b] ? a : b);
                        let mostObj = {
                            posture_one: 1,
                            posture_two: 2,
                            posture_four: 4
                        }
                        let sitMostObj = {
                            '坐姿1': this.sitInfo.hold_1,
                            '坐姿2': this.sitInfo.hold_2,
                            '坐姿3': this.sitInfo.hold_4,
                        }
                        let sitMax = Object.keys(sitMostObj).reduce((a, b) => a > b ? a : b);
                        // console.log('sitMax:', sitMax)
                        console.log('max:',max)
                        this.holdInfo.mostFail = mostObj[max];
                        this.sitInfo.mostFail = sitMax;
                        this.initChart10(hold_names, hold_series)
                        this.initChart14(sit_names, sit_series)
                        woziAll.push(total_hold);
                        sitAll.push(total_sit);
                        this.woziAll = woziAll;
                        this.sitAll = sitAll;
                        // console.log('正确握姿数量:', hold_total)
                        wzd_series.push(wzd_a, wzd_b, wzd_c);
                        xsx_series.push(xsx_a, xsx_b, xsx_c);
                        yzx_series.push(yzx_a, yzx_b, yzx_c);
                        zqd_series.push(zqd_a, zqd_b, zqd_c);
                        zql_series.push(zql_q2, zql_q3, zql_q4);
                        sx_series.push(rcsx, kysx);
                        this.initChart24(this.woziAll);
                        this.initChart25(this.sitAll);
                        // console.log('wzd_series:',wzd_series)
                    })
                    .then(res => {
                        // console.log('sx_series:', sx_series);
                        this.jgTable.class = wzd_names;
                        this.pjwdTable.rc_data = sx_series[0].data;
                        this.pjwdTable.ky_data = sx_series[1].data;
                        var rc_count = 0;
                        let ky_count = 0;
                        sx_series[0].data.map(item => {
                            rc_count += Number(item)
                        })
                        sx_series[1].data.map(item => {
                            ky_count += Number(item)
                        })

                        let rc_mean = (rc_count / wzd_names.length).toFixed(2);
                        let ky_mean = (ky_count / wzd_names.length).toFixed(2);
                        let minVal = rc_mean > ky_mean ? ky_mean : rc_mean;
                        // console.log('rc_mean:', rc_mean);
                        // console.log('ky_mean:', ky_mean);
                        // console.log('minVal:', minVal);
                        if (rc_mean > ky_mean) {
                            this.pjwdTable.compare = '稍低'
                        } else {
                            this.pjwdTable.compare = '稍高'
                        }
                        if (minVal >= 80) {
                            this.pjwdTable.comment =
                                '学生整体书写工整，与笔画形态较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。'
                        } else if (minVal >= 65 && minVal < 80) {
                            this.pjwdTable.comment =
                                '学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。'
                        } else if (minVal < 65) {
                            this.pjwdTable.comment =
                                '学生整体书写不太规范，与笔画形态较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。'
                        }

                        this.zqlTable.zql_name = wzd_names;
                        this.zqlTable.zql_q2 = zql_series[0].data;
                        this.zqlTable.zql_q3 = zql_series[1].data;
                        this.zqlTable.zql_q4 = zql_series[2].data;
                        let wzdData = wzd_series.filter(item => {
                            return item.name == this.allInfo.all_wzd_high
                        })[0].data;
                        let xsxData = xsx_series.filter(item => {
                            return item.name == this.allInfo.all_xsx_high
                        })[0].data;
                        let yzxData = yzx_series.filter(item => {
                            return item.name == this.allInfo.all_yzx_high
                        })[0].data;
                        let zqdData = zqd_series.filter(item => {
                            return item.name == this.allInfo.all_zqd_high
                        })[0].data;
                        this.comparisonClass(wzdData, 'wzd');
                        this.comparisonClass(xsxData, 'xsx');
                        this.comparisonClass(yzxData, 'yzx');
                        this.comparisonClass(zqdData, 'zqd');
                        // this.initChart16(series);
                        // this.initChart23(series);
                        this.initChart17(wzd_names, wzd_series);
                        this.initChart18(wzd_names, xsx_series);

                        this.initChart20(wzd_names, zqd_series);
                        this.initChart22(wzd_names, zql_series);
                        setTimeout(() => {
                            this.initChart19(wzd_names, yzx_series);
                        }, 2000);
                        // 各年级4项结构对比
                        let baowei = [];
                        let zuoyou = [];
                        let duti = [];
                        let shangxia = [];
                        series.map(item => {
                            let zyobj = {
                                name: item.name,
                                value: Number(item.data[0])
                            }
                            let sxobj = {
                                name: item.name,
                                value: Number(item.data[1])
                            }
                            let dtobj = {
                                name: item.name,
                                value: Number(item.data[2])
                            }
                            let bwobj = {
                                name: item.name,
                                value: Number(item.data[3])
                            }

                            baowei.push(bwobj);
                            zuoyou.push(zyobj);
                            duti.push(dtobj);
                            shangxia.push(sxobj)
                        })
                        const bwResult = baowei.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        const zyResult = zuoyou.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        const dtResult = duti.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        const sxResult = shangxia.reduce((acc, curr) => {
                            if (curr.value > acc.max) {
                                acc.max = curr.value;
                                acc.maxName = curr.name;
                            }

                            if (curr.value < acc.min) {
                                acc.min = curr.value;
                                acc.minName = curr.name;
                            }

                            return acc;
                        }, {
                            min: Infinity,
                            max: -Infinity
                        });
                        this.constructionInfo = {
                            bwMax: bwResult.max,
                            bwMin: bwResult.min,
                            bwMaxName: bwResult.maxName,
                            bwMinName: bwResult.minName,
                            zyMax: zyResult.max,
                            zyMin: zyResult.min,
                            zyMaxName: zyResult.maxName,
                            zyMinName: zyResult.minName,
                            dtMax: dtResult.max,
                            dtMin: dtResult.min,
                            dtMaxName: dtResult.maxName,
                            dtMinName: dtResult.minName,
                            sxMax: sxResult.max,
                            sxMin: sxResult.min,
                            sxMaxName: sxResult.maxName,
                            sxMinName: sxResult.minName,
                        }

                    })
                    .catch(err => {
                        console.log('Promise.all失败:', err)
                    })
                // 前测个人报告
                const promise2 = this.gradeList.map(item => {
                    let data2 = {
                        chart: 4,
                        level: 6,
                        orgId: item.orgId,
                        attId: 26,
                        termKey: this.termValue
                    }
                    return this.$Api.DataScreen.queryTableData(data2)
                        .then(resData => {
                            resData.name = item.name;
                            return resData;
                        })
                        .catch(err => {
                            console.log('err:', err)
                        })
                })
                Promise.all(promise2)
                    .then(resData => {
                        // console.log('Promise.all2:', resData);
                        // console.log('个人报告数据:', resData)
                        resData.map(item => {
                            if (item.name == '一年级') {
                                this.tabalOne = item.data;
                                let grade = {
                                    label: '一年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data.push(grade)

                                }
                            } else if (item.name == '二年级') {
                                this.tabalTwo = item.data;
                                let grade = {
                                    label: '二年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data.push(grade)

                                }
                            } else if (item.name == '三年级') {
                                this.tabalThree = item.data;
                                let grade = {
                                    label: '三年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data.push(grade)

                                }

                            } else if (item.name == '四年级') {
                                this.tabalFour = item.data;
                                let grade = {
                                    label: '四年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data.push(grade)

                                }

                            } else if (item.name == '五年级') {
                                this.tabalFive = item.data;
                                let grade = {
                                    label: '五年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data.push(grade)

                                }

                            } else if (item.name == '六年级') {
                                this.tabalSix = item.data;
                                let grade = {
                                    label: '六年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data.push(grade)

                                }
                            }
                        })
                    })

                // 后测整体报告
                const promises3 = this.gradeList.map(item => {
                    let data = {
                        chart: 5,
                        level: 6, // 5学校 6年级 7班级
                        orgId: item.orgId,
                        termKey: this.termValue
                    }
                    return this.$Api.DataScreen.basicsStatistics(data)
                        .then(res => {
                            res.name = item.name;
                            return res
                        })
                })
                Promise.all(promises3)
                    .then(res => {
                        // console.log('Promise.all后测:', res);
                        res.map(item => {
                            if (JSON.stringify(item.data) !== '{}') {
                                arr_after.push(item.name);
                            } else {
                                // console.log('当前年级没有后测数据:', item.name)
                            }
                        })

                        let arr2 = [];
                        this.gradeList.map(item => {
                            arr_after.map(items => {
                                if (item.name == items) {
                                    arr2.push(item)
                                }
                            })
                        })
                        // console.log('arr2后测:', arr2)
                        this.gradeList_after = arr2;
                    })
                    .catch(err => {
                        console.log('Promise.all失败:', err)
                    })
                // 后测个人报告
                const promise4 = this.gradeList.map(item => {
                    let data2 = {
                        chart: 5,
                        level: 6,
                        orgId: item.orgId,
                        attId: 26,
                        termKey: this.termValue
                    }
                    return this.$Api.DataScreen.queryTableData(data2)
                        .then(resData => {
                            resData.name = item.name;
                            return resData;
                        })
                        .catch(err => {
                            console.log('err:', err)
                        })
                })
                Promise.all(promise4)
                    .then(resData => {
                        // console.log('Promise.all2:', resData);
                        // console.log('个人报告数据:', resData)
                        resData.map(item => {
                            if (item.name == '一年级') {
                                this.tabalOne_after = item.data;
                                let grade = {
                                    label: '一年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data_after.push(grade)

                                }
                            } else if (item.name == '二年级') {
                                this.tabalTwo_after = item.data;
                                let grade = {
                                    label: '二年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data_after.push(grade)

                                }
                            } else if (item.name == '三年级') {
                                this.tabalThree_after = item.data;
                                let grade = {
                                    label: '三年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data_after.push(grade)

                                }

                            } else if (item.name == '四年级') {
                                this.tabalFour_after = item.data;
                                let grade = {
                                    label: '四年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data_after.push(grade)

                                }

                            } else if (item.name == '五年级') {
                                this.tabalFive_after = item.data;
                                let grade = {
                                    label: '五年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data_after.push(grade)
                                }

                            } else if (item.name == '六年级') {
                                this.tabalSix_after = item.data;
                                let grade = {
                                    label: '六年级',
                                    children: []
                                }
                                item.data.map(items => {
                                    let obj = {
                                        classId: items.bindId,
                                        label: items.name
                                    }
                                    grade.children.push(obj)
                                })
                                if (grade.children.length > 0) {
                                    this.data_after.push(grade)
                                }
                            }
                        })

                    })
            },
            // 对比一致性与相似性
            comparison(val1, val2) {
                let result;
                if (val1 < val2) {
                    result = val1
                } else {
                    result = val2
                }
                // console.log('result:', result);
                if (result >= 80) {
                    return 0
                } else if (result >= 65 && result < 80) {
                    return 1
                } else {
                    return 2
                }
            },
            // 书写维度评语判断
            comparisonWd(val) {
                if (val >= 80) {
                    return 0
                }
                if (val >= 60 && val < 80) {
                    return 1
                }
                if (val < 60) {
                    return 2
                }
            },
            // 书写基础计算各柱状图最高最低年级
            comparisonClass(arr, type) {
                // console.log('arr:', arr, 'type:', type)
                let max = Number(arr[0]);
                let maxIndex = 0;
                let min = Number(arr[0]);
                let minIndex = 0;
                // let classObj = {
                //     0: '一年级',
                //     1: '二年级',
                //     2: '三年级',
                //     3: '四年级',
                //     4: '五年级',
                //     5: '六年级'
                // }

                for (let i = 1; i < arr.length; i++) {
                    if (Number(arr[i]) > max) {
                        maxIndex = i;
                        max = Number(arr[i]);
                    }

                    if (Number(arr[i]) < min) {
                        minIndex = i;
                        min = Number(arr[i]);
                    }
                }
                switch (type) {
                    case 'wzd':
                        // this.allInfo.all_wzd_HClass = classObj[maxIndex];
                        // this.allInfo.all_wzd_LClass = classObj[minIndex];
                        this.allInfo.all_wzd_HClass = this.gradeList[maxIndex].name;
                        this.allInfo.all_wzd_LClass = this.gradeList[minIndex].name;
                        break;
                    case 'xsx':
                        // this.allInfo.all_xsx_HClass = classObj[maxIndex];
                        // this.allInfo.all_xsx_LClass = classObj[minIndex];
                        this.allInfo.all_xsx_HClass = this.gradeList[maxIndex].name;
                        this.allInfo.all_xsx_LClass = this.gradeList[minIndex].name;
                        break;
                    case 'yzx':
                        // this.allInfo.all_yzx_HClass = classObj[maxIndex];
                        // this.allInfo.all_yzx_LClass = classObj[minIndex];
                        this.allInfo.all_yzx_HClass = this.gradeList[maxIndex].name;
                        this.allInfo.all_yzx_LClass = this.gradeList[minIndex].name;
                        break;
                    case 'zqd':
                        // this.allInfo.all_zqd_HClass = classObj[maxIndex];
                        // this.allInfo.all_zqd_LClass = classObj[minIndex];
                        this.allInfo.all_zqd_HClass = this.gradeList[maxIndex].name;
                        this.allInfo.all_zqd_LClass = this.gradeList[minIndex].name;
                        break;
                }
            },
            // 计算2、3、4题最低正确率
            computedMinVal(data) {
                let minValue = data[0].value;
                let minIndex = 0;
                for (let i = 1; i < data.length; i++) {
                    if (Number(data[i].value) < Number(minValue)) {
                        minValue = data[i].value;
                        minIndex = i;
                    }
                }
                let obj = {
                    name: data[minIndex].name,
                    value: minValue
                }
                // console.log('obj:',obj)
                return obj
            },
            //获取学年学期列表 this.$Api.DataScreen
            getTermList() {
                this.$Api.DataScreen.queryTermList()
                    .then(res => {
                        // console.log('学年学期列表:', res);
                        let terms = {
                            1: '上学期',
                            2: '下学期'
                        }
                        res.data.map(item => {
                            let term = {
                                value: item.key,
                                label: `${item.year}-${item.year + 1}${terms[item.term]}`
                            }
                            this.termOptions.push(term)
                        })
                        this.termValue = this.termOptions[1].value;
                        this.queryUserMaxOrg();
                        this.getwdCode();
                    })
                    .catch(err => {
                        console.log('err:', err)
                    })
            },
            // 选择学年
            termChange(value) {
                this.hasNewReport(value);
                // console.log(value);
            },
            // 获取一二级维度code
            getwdCode() {
                this.$Api.DataScreen.getwdCode()
                    .then(res => {
                        // console.log('一二级维度:', res)
                    })
                    .catch(err => {
                        console.log('获取维度失败:', err)
                    })
            },
            // 各年级书写水平评语
            async getGradeCommit(codes, orgId, name, wdname) {
                // console.log('codes:', codes)
                let data = {
                    codes,
                    orgId,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.getwdComment(data);
                // console.log(name+'评语:',resData);
                let arr = []
                let str =
                    `${name}学生在${wdname}方面整体较弱，建议在书写时注意书写${resData.data[codes[0]].name},建议学生${resData.data[codes[0]].advice}`
                arr.push(str);
                this.gradeCommentList = this.gradeCommentList.concat(arr);
            },
            // 各年级笔画形态评语
            async getGradeCommit_bihua(codes, orgId, classname, wdname, score, ) {
                let data = {
                    codes,
                    orgId,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.getwdComment(data);
                if (!resData.data.form) {
                    // console.log(`${classname}没有评语`)
                } else {
                    // console.log(classname + '评语:', resData);
                    let arr = []
                    let str =
                        `${classname}在笔画形态-${resData.data.form.name}维度平均分最低为${score.toFixed(2)},${resData.data.form.describe}${resData.data.form.advice}`
                    arr.push(str);
                    // console.log('arr:', arr);
                    this.gradeCommentList_bihua = this.gradeCommentList_bihua.concat(arr);
                }

            },
            //  各年级章法占格评语
            async getGradeCommit_zhangfa(codes, orgId, classname, wdname, score, ) {
                let data = {
                    codes,
                    orgId,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.getwdComment(data);
                // console.log(classname + '评语:', resData);
                if (!resData.data.layout) {
                    // console.log(`${classname}没有评语`)
                } else {
                    // console.log(classname + '评语:', resData);
                    let arr = []
                    let str =
                        `${classname}在章法占格-${wdname}维度平均分最低为${score.toFixed(2)},${resData.data.layout.describe}${resData.data.layout.advice}`
                    arr.push(str);
                    // console.log('arr:', arr);
                    this.gradeCommentList_zhangfa = this.gradeCommentList_zhangfa.concat(arr);
                }
            },
            //  各年级结构组合评语
            async getGradeCommit_jiegou(codes, orgId, classname, wdname, score, ) {
                let data = {
                    codes,
                    orgId,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.getwdComment(data);
                // console.log(classname + '评语:', resData);
                if (!resData.data.structure) {
                    // console.log(`${classname}没有评语`)
                } else {
                    // console.log(classname + '评语:', resData);
                    let arr = []
                    let str =
                        `${classname}在结构组合-${wdname}维度平均分最低为${score.toFixed(2)},${resData.data.structure.describe}${resData.data.structure.advice}`
                    arr.push(str);
                    // console.log('arr:', arr);
                    this.gradeCommentList_jiegou = this.gradeCommentList_jiegou.concat(arr);
                }
            },
            // 优秀作品名单
            async getExcellentUsers(type, orgId, className) {
                // console.log('优秀作品type:',type)
                if (type == 'school') {
                    let data = {
                        orgId,
                        termKey: this.termValue
                    };
                    let resData = await this.$Api.DataScreen.getExcellentUsers(data);
                    // console.log('全校优秀作品名单:', resData);
                    this.excellentUsers.all = resData.data
                } else {
                    let gradeList = {
                        '一年级': 'first_grade',
                        '二年级': 'second_grade',
                        '三年级': 'third_grade',
                        '四年级': 'fourth_grade',
                        '五年级': 'fifth_grade',
                        '六年级': 'sixth_grade',
                    }
                    let data = {
                        orgId: this.schoolOrgID,
                        gradeOrgId: orgId,
                        termKey: this.termValue
                    }
                    let resData = await this.$Api.DataScreen.getExcellentUsers(data);
                    // console.log(className + '优秀作品名单:',resData);
                    this.excellentUsers[gradeList[className]] = resData.data
                }
            },
            // 优秀作品
            async getExcellentWork(type, orgId, className) {
                if (type == 'school') {
                    let data = {
                        orgId,
                        termKey: this.termValue
                    };
                    let resData = await this.$Api.DataScreen.getExcellentWork(data);
                    // console.log('全校优秀作品:',resData);
                    this.excellentWork.all = resData.data
                } else {
                    let gradeList = {
                        '一年级': 'first_grade',
                        '二年级': 'second_grade',
                        '三年级': 'third_grade',
                        '四年级': 'fourth_grade',
                        '五年级': 'fifth_grade',
                        '六年级': 'sixth_grade',
                    }
                    let data = {
                        orgId: this.schoolOrgID,
                        gradeOrgId: orgId,
                        termKey: this.termValue
                    }
                    let resData = await this.$Api.DataScreen.getExcellentWork(data);
                    // console.log(className + '优秀作品:',resData);
                    this.excellentWork[gradeList[className]] = resData.data
                }

            },
            // 各年级注册人数
            async getRegisterBygrade(gradeOrgId, className) {
                let data = {
                    orgId: this.schoolOrgID,
                    termKey: this.termValue,
                    gradeOrgId
                }
                let resData = await this.$Api.DataScreen.queryJoinRate(data);
                console.log(className + '报告注册人数数据(新):', resData);
                let max = resData.data[0].joinCount == 0 ? 0 : (resData.data[0].joinCount / resData.data[0]
                    .registerCount).toFixed(2) * 100;
                let min = resData.data[0].joinCount == 0 ? 0 : (resData.data[0].joinCount / resData.data[0]
                    .registerCount).toFixed(2) * 100;
                let maxName = resData.data[0].termName;
                let minName = resData.data[0].termName;
                var totalNum = 0;
                var totalPage = 0;
                resData.data.map(item => {
                    totalNum += item.joinCount;
                    totalPage += item.workCount
                    item.joinRate = item.joinCount == 0 ? 0 : (item.joinCount / item.registerCount).toFixed(
                        2) * 100;
                    if (item.joinRate > max) {
                        max = item.joinRate;
                        maxName = item.termName
                    }
                    if (item.joinRate < min) {
                        min = item.joinRate;
                        minName = item.termName
                    }
                    this.getBadweByclassOrgId(item.termIndex, item.termName, className);
                    this.getClassTable(item.termIndex, item.termName, className)
                })
                switch (className) {
                    case '一年级':
                        this.tableData_one.tabal = resData.data;
                        this.tableData_one.max = maxName;
                        this.tableData_one.min = minName;
                        this.tableData_one.totalNum = totalNum;
                        this.tableData_one.totalPage = totalPage;
                        break;
                    case '二年级':
                        this.tableData_two.tabal = resData.data;
                        this.tableData_two.max = maxName;
                        this.tableData_two.min = minName;
                        this.tableData_two.totalNum = totalNum;
                        this.tableData_two.totalPage = totalPage;
                        break;
                    case '三年级':
                        this.tableData_three.tabal = resData.data;
                        this.tableData_three.max = maxName;
                        this.tableData_three.min = minName;
                        this.tableData_three.totalNum = totalNum;
                        this.tableData_three.totalPage = totalPage;
                        break;
                    case '四年级':
                        this.tableData_four.tabal = resData.data;
                        this.tableData_four.max = maxName;
                        this.tableData_four.min = minName;
                        this.tableData_four.totalNum = totalNum;
                        this.tableData_four.totalPage = totalPage;
                        break;
                    case '五年级':
                        this.tableData_five.tabal = resData.data;
                        this.tableData_five.max = maxName;
                        this.tableData_five.min = minName;
                        this.tableData_five.totalNum = totalNum;
                        this.tableData_five.totalPage = totalPage;
                        break;
                    case '六年级':
                        this.tableData_six.tabal = resData.data;
                        this.tableData_six.max = maxName;
                        this.tableData_six.min = minName;
                        this.tableData_six.totalNum = totalNum;
                        this.tableData_six.totalPage = totalPage;
                        break;
                }
            },
            // 整体报告-笔画形态分维度分析
            async getAnalyseChart(orgId, code, termKey) {
                let wdname = {
                    layout: '章法占格',
                    structure: '结构组合',
                    form: '笔画形态'
                }
                let data = {
                    orgId,
                    code,
                    termKey
                }
                let resData = await this.$Api.DataScreen.getAnalyseChart(data);
                // console.log(`${wdname[code]}分维度分析:`, resData);
                let fxarr = []; // 方向
                let bxarr = []; // 笔性
                let cxarr = []; // 粗细
                resData.data.gradeList.map(item => {
                    let obj;
                    if (resData.data.data[item.id].data['form_1'].score && resData.data.data[item.id].data[
                            'form_2'].score && resData.data.data[item.id].data['form_3'].score) {
                        obj = {
                            name: resData.data.data[item.id].name,
                            fx_val: resData.data.data[item.id].data['form_1'].score,
                            bx_val: resData.data.data[item.id].data['form_2'].score,
                            cx_val: resData.data.data[item.id].data['form_3'].score,
                            orgId: item.id
                        }
                        this.bihuaTableData.push(obj);
                        fxarr.push(obj);
                        bxarr.push(obj);
                        cxarr.push(obj);
                        this.fx_min.name = fxarr.sort((a, b) => a.fx_val - b.fx_val)[0].name;
                        this.fx_min.val = fxarr.sort((a, b) => a.fx_val - b.fx_val)[0].fx_val.toFixed(2);
                        this.bx_min.name = bxarr.sort((a, b) => a.bx_val - b.bx_val)[0].name;
                        this.bx_min.val = bxarr.sort((a, b) => a.bx_val - b.bx_val)[0].bx_val.toFixed(2);
                        this.cx_min.name = cxarr.sort((a, b) => a.cx_val - b.cx_val)[0].name;
                        this.cx_min.val = cxarr.sort((a, b) => a.cx_val - b.cx_val)[0].cx_val.toFixed(2);
                        setTimeout(() => {
                            this.initChart26(this.bihuaTableData);
                        }, 2000);

                    }
                })
                // console.log('笔画形态表格:', this.bihuaTableData);
                // 计算各年级笔画形态维度最低评语
                let bhjson = {
                    cx_val: '粗细',
                    bx_val: '笔性',
                    fx_val: '方向'
                }
                this.bihuaTableData.map(item => {
                    let min_val = '';
                    let min_name = '';
                    if (item.bx_val > item.cx_val) {
                        min_val = item.cx_val;
                        min_name = 'cx_val';
                    } else {
                        min_val = item.bx_val;
                        min_name = 'bx_val';
                    }
                    if (min_val > item.fx_val) {
                        min_val = item.fx_val;
                        min_name = 'fx_val'
                    }
                    this.getGradeCommit_bihua(['form'], item.orgId, item.name, bhjson[
                        min_name], min_val, )
                })
            },
            // 整体报告-章法占格分维度分析
            async getAnalyseChart2(orgId, code, termKey) {
                let wdname = {
                    layout: '章法占格',
                    structure: '结构组合',
                    form: '笔画形态'
                }
                let data = {
                    orgId,
                    code,
                    termKey
                }
                let resData = await this.$Api.DataScreen.getAnalyseChart(data);
                // console.log(`${wdname[code]}分维度分析:`, resData);
                let jmarr = []; // 卷面整洁
                let zgarr = []; // 占格站位
                let zxarr = []; // 字形大小
                resData.data.gradeList.map(item => {
                    let obj2;
                    if (resData.data.data[item.id].data['layout_1'].score && resData.data.data[item.id]
                        .data['layout_2'].score && resData.data.data[item.id].data['layout_3'].score) {
                        obj2 = {
                            name: resData.data.data[item.id].name,
                            jm_val: resData.data.data[item.id].data['layout_1'].score,
                            zg_val: resData.data.data[item.id].data['layout_2'].score,
                            zx_val: resData.data.data[item.id].data['layout_3'].score,
                            orgId: item.id
                        }
                        this.zhangfaTableData.push(obj2);
                        jmarr.push(obj2);
                        zgarr.push(obj2);
                        zxarr.push(obj2);
                        this.jm_min.name = jmarr.sort((a, b) => a.jm_val - b.jm_val)[0].name;
                        this.jm_min.val = jmarr.sort((a, b) => a.jm_val - b.jm_val)[0].jm_val.toFixed(2);
                        this.zg_min.name = zgarr.sort((a, b) => a.zg_val - b.zg_val)[0].name;
                        this.zg_min.val = zgarr.sort((a, b) => a.zg_val - b.zg_val)[0].zg_val.toFixed(2);
                        this.zx_min.name = zxarr.sort((a, b) => a.zx_val - b.zx_val)[0].name;
                        this.zx_min.val = zxarr.sort((a, b) => a.zx_val - b.zx_val)[0].zx_val.toFixed(2);

                        setTimeout(() => {
                            this.initChart27(this.zhangfaTableData);
                        }, 2000);
                    }

                });
                // console.log('章法占格表格:', this.zhangfaTableData);
                // 计算各年级章法占格最低评语
                let bhjson = {
                    jm_val: '卷面整洁',
                    zg_val: '占格占位',
                    zx_val: '字形大小'
                }
                this.zhangfaTableData.map(item => {
                    let min_val = '';
                    let min_name = '';
                    if (item.jm_val > item.zg_val) {
                        min_val = item.zg_val;
                        min_name = 'zg_val';
                    } else {
                        min_val = item.jm_val;
                        min_name = 'jm_val';
                    }
                    if (min_val > item.zx_val) {
                        min_val = item.zx_val;
                        min_name = 'zx_val'
                    }
                    this.getGradeCommit_zhangfa(['layout'], item.orgId, item.name, bhjson[
                        min_name], min_val, )
                })
            },
            // 整体报告-结构组合分维度分析
            async getAnalyseChart3(orgId, code, termKey) {
                let wdname = {
                    layout: '章法占格',
                    structure: '结构组合',
                    form: '笔画形态'
                }
                let data = {
                    orgId,
                    code,
                    termKey
                }
                let resData = await this.$Api.DataScreen.getAnalyseChart(data);
                // console.log(`${wdname[code]}分维度分析:`, resData);
                let zxarr = []; // 字形重心
                let zcarr = []; // 主次收放
                let bharr = []; // 笔画关系
                let bjarr = []; // 部件关系
                resData.data.gradeList.map(item => {
                    let obj2;
                    if (resData.data.data[item.id].data['structure_1'].score && resData.data.data[item.id]
                        .data['structure_2'].score && resData.data.data[item.id].data['structure_3']
                        .score && resData.data.data[item.id].data['structure_3'].score) {
                        obj2 = {
                            name: resData.data.data[item.id].name,
                            zx_val: resData.data.data[item.id].data['structure_1'].score,
                            zc_val: resData.data.data[item.id].data['structure_2'].score,
                            bh_val: resData.data.data[item.id].data['structure_3'].score,
                            bj_val: resData.data.data[item.id].data['structure_4'].score,
                            orgId: item.id
                        }
                        this.jiegouTableData.push(obj2);
                        zxarr.push(obj2);
                        zcarr.push(obj2);
                        bharr.push(obj2);
                        bjarr.push(obj2);
                        this.zx_min.name = zxarr.sort((a, b) => a.zx_val - b.zx_val)[0].name;
                        this.zx_min.val = zxarr.sort((a, b) => a.zx_val - b.zx_val)[0].zx_val.toFixed(2);
                        this.zc_min.name = zcarr.sort((a, b) => a.zc_val - b.zc_val)[0].name;
                        this.zc_min.val = zcarr.sort((a, b) => a.zc_val - b.zc_val)[0].zc_val.toFixed(2);
                        this.bh_min.name = bharr.sort((a, b) => a.bh_val - b.bh_val)[0].name;
                        this.bh_min.val = bharr.sort((a, b) => a.bh_val - b.bh_val)[0].bh_val.toFixed(2);
                        this.bj_min.name = bjarr.sort((a, b) => a.bj_val - b.bj_val)[0].name;
                        this.bj_min.val = bjarr.sort((a, b) => a.bj_val - b.bj_val)[0].bj_val.toFixed(2);

                        setTimeout(() => {
                            this.initChart28(this.jiegouTableData);
                        }, 2000);
                    }

                });
                // console.log('结构组合表格:', this.jiegouTableData);
                let bhjson = {
                    zx_val: '字形重心',
                    zc_val: '主次收放',
                    bh_val: '笔画关系',
                    bj_val: '部件关系'
                }
                this.jiegouTableData.map(item => {
                    let min_val = '';
                    let min_name = '';
                    if (item.zx_val > item.zc_val) {
                        min_val = item.zc_val;
                        min_name = 'zc_val';
                    } else {
                        min_val = item.zx_val;
                        min_name = 'zx_val';
                    }
                    if (min_val > item.bh_val) {
                        min_val = item.bh_val;
                        min_name = 'bh_val'
                    }
                    if (min_val > item.bj_val) {
                        min_val = item.bj_val;
                        min_name = 'bj_val'
                    }
                    this.getGradeCommit_jiegou(['structure'], item.orgId, item.name, bhjson[
                        min_name], min_val, )
                })
            },
            // 年级报告-根据orgId获取各年级一级维度分析
            async getwdCommentByClass(orgId, className) {
                let data = {
                    orgId,
                    codes: ['complete', 'layout', 'structure', 'form'],
                    termKey: this.termValue
                }
                // console.log('data:', data)
                let resData = await this.$Api.DataScreen.getwdCommentByClass(data);
                let obj = {
                    orgId,
                    data: resData.data,
                    className
                }
                this.gradeCommentList_all.push(obj)
            },
            // 计算各年级最差的2个一级维度，并组合成评语
            async getBadweByGrade(gradeData, orgId, gradeName) {
                if (gradeData.complete_art) {
                    // 计算成绩最差的2个维度进行分析
                    let arr = [{
                            code: "complete",
                            name: '作品完整度',
                            value: gradeData.complete_art.result / gradeData.complete_art.count
                        },
                        {
                            code: "form",
                            name: '笔画形态',
                            value: gradeData.form_art.result / gradeData.form_art.count
                        },
                        {
                            code: "layout",
                            name: '章法占格',
                            value: gradeData.layout_art.result / gradeData.layout_art.count
                        },
                        {
                            code: "structure",
                            name: '结构组合',
                            value: gradeData.structure_art.result / gradeData.structure_art.count
                        },
                    ].sort((a, b) => a.value - b.value)
                    let data = {
                        codes: [arr[0].code, arr[1].code],
                        orgId: orgId,
                        termKey: this.termValue
                    };
                    let resData = await this.$Api.DataScreen.getwdComment(data);
                    // console.log('请求报告', resData);
                    let str1 = this.allComments_art[arr[0].code] + '需注意书写' + resData.data[arr[0].code].name +
                        '问题,建议' + resData.data[arr[0].code].advice;
                    let str2 = this.allComments_art[arr[1].code] + '需注意书写' + resData.data[arr[1].code].name +
                        '问题,建议' + resData.data[arr[1].code].advice;
                    switch (gradeName) {
                        case '一年级':
                            this.badComment_first_grade_1 = str1;
                            this.badComment_first_grade_2 = str2;
                            break;
                        case '二年级':
                            this.badComment_second_grade_1 = str1;
                            this.badComment_second_grade_2 = str2;
                            break;
                        case '三年级':
                            this.badComment_third_grade_1 = str1;
                            this.badComment_third_grade_2 = str2;
                            break;
                        case '四年级':
                            this.badComment_fourth_grade_1 = str1;
                            this.badComment_fourth_grade_2 = str2;
                            break;
                        case '五年级':
                            this.badComment_fifth_grade_1 = str1;
                            this.badComment_fifth_grade_2 = str2;
                            break;
                        case '六年级':
                            this.badComment_sixth_grade_1 = str1;
                            this.badComment_sixth_grade_2 = str2;
                            break;
                    }
                } else {
                    console.log('没有人工点评')
                }
            },
            // 计算各年级下各班级最差一级维度并组合成评语
            async getBadweByclassOrgId(orgId, termName, className) {
                let data = {
                    orgId,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.getwdComment(data);
                let str;
                let wdlist = {
                    complete: {
                        name: '作品完整度',
                        des: '作品完整度要求作品内容完整、用字正确。'
                    },
                    form: {
                        name: '笔画形态',
                        des: '笔画形态由方向、笔性、粗细组成。'
                    },
                    layout: {
                        name: '章法占格',
                        des: '章法占格由卷面整洁、占格占位、字形大小组成。'
                    },
                    structure: {
                        name: '结构组合',
                        des: '结构组合由字形重心、主次收放、笔画关系、部件关系组成。'
                    }

                };
                if (resData.data) {
                    switch (className) {
                        case '一年级':
                            for (var i in resData.data) {
                                resData.data[i].wdname = resData.data[i].code.split('_')[0];
                                str =
                                    `${termName}学生在${wdlist[resData.data[i].wdname].name}方面整体较弱,建议在书写时应注意${resData.data[i].advice}`
                                this.badComment_one.push(str);
                            }
                            break;
                        case '二年级':
                            for (var i in resData.data) {
                                resData.data[i].wdname = resData.data[i].code.split('_')[0];
                                str =
                                    `${termName}学生在${wdlist[resData.data[i].wdname].name}方面整体较弱,建议在书写时应注意${resData.data[i].advice}`
                                this.badComment_two.push(str);
                            }
                            break;
                        case '三年级':
                            for (var i in resData.data) {
                                resData.data[i].wdname = resData.data[i].code.split('_')[0];
                                str =
                                    `${termName}学生在${wdlist[resData.data[i].wdname].name}方面整体较弱,建议在书写时应注意${resData.data[i].advice}`
                                this.badComment_three.push(str);
                            }
                            break;
                        case '四年级':
                            for (var i in resData.data) {
                                resData.data[i].wdname = resData.data[i].code.split('_')[0];
                                str =
                                    `${termName}学生在${wdlist[resData.data[i].wdname].name}方面整体较弱,建议在书写时应注意${resData.data[i].advice}`
                                this.badComment_four.push(str);
                            }
                            break;
                        case '五年级':
                            for (var i in resData.data) {
                                resData.data[i].wdname = resData.data[i].code.split('_')[0];
                                str =
                                    `${termName}学生在${wdlist[resData.data[i].wdname].name}方面整体较弱,建议在书写时应注意${resData.data[i].advice}`
                                this.badComment_five.push(str);
                            }
                            break;
                        case '六年级':
                            for (var i in resData.data) {
                                resData.data[i].wdname = resData.data[i].code.split('_')[0];
                                str =
                                    `${termName}学生在${wdlist[resData.data[i].wdname].name}方面整体较弱,建议在书写时应注意${resData.data[i].advice}`
                                this.badComment_six.push(str);
                            }
                            break;
                    }
                }

            },
            // 整体报告-平台上课总数
            async getLessonCourseInfo() {
                let data = {
                    orgId: this.schoolOrgID,
                    termKey: this.termValue
                }
                let resData = await this.$Api.DataScreen.getLessonCourseInfo(data);
                // console.log('平台上课总数:',resData);
                this.teaData = resData.data;
                let arr = resData.data.slice(0, 10);
                let seriesData = [];
                let xData = [];
                arr.map(item => {
                    seriesData.push(item.count);
                    xData.push(item.userName)
                })
                setTimeout(() => {
                    this.initChart29(xData, seriesData);
                }, 1000);

            },
            // 年级报告-根据班级orgid获取班级握姿、坐姿数据
            async getClassTable(orgId, className, gradeName) {
                let data = {
                    chart: 4,
                    level: 7, // 5学校 6年级 7班级
                    orgId,
                    termKey: this.termValue
                };
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                
                switch (gradeName) {
                    case '一年级':
                        var obj = {};
                        if (JSON.stringify(resData.data) == '{}') {} else {
                            obj.wztotal = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_3.result + resData.data.dimension_a_4.result;
                            obj.zztotal = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result + resData.data.dimension_b_4.result
                            obj.className = className;
                            obj.wzData = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_4.result;
                            obj.zzData = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result;
                            this.tableData_one.tabal2.push(obj);
                        }

                        break;
                    case '二年级':
                        var obj = {};
                        if (JSON.stringify(resData.data) == '{}') {
                            // obj.className = className;
                            // obj.wzData = 0;
                            // obj.zzData = 0;
                            // obj.wztotal = 0;
                            // obj.zztotal = 0;
                        } else {
                            obj.wztotal = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_3.result + resData.data.dimension_a_4.result;
                            obj.zztotal = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result + resData.data.dimension_b_4.result
                            obj.className = className;
                            obj.wzData = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_4.result;
                            obj.zzData = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result;
                            this.tableData_two.tabal2.push(obj);
                        }

                        break;
                    case '三年级':
                        console.log(`${gradeName}${className}详细数据:`,resData);
                        var obj = {};
                        if (JSON.stringify(resData.data) == '{}') {
                            // obj.className = className;
                            // obj.wzData = 0;
                            // obj.zzData = 0;
                            // obj.wztotal = 0;
                            // obj.zztotal = 0;
                        } else {
                            obj.wztotal = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_3.result + resData.data.dimension_a_4.result;
                            obj.zztotal = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result + resData.data.dimension_b_4.result
                            obj.className = className;
                            obj.wzData = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_4.result;
                            obj.zzData = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result;
                            this.tableData_three.tabal2.push(obj);
                        }

                        break;
                    case '四年级':
                        var obj = {};
                        if (JSON.stringify(resData.data) == '{}') {
                            // obj.className = className;
                            // obj.wzData = 0;
                            // obj.zzData = 0;
                            // obj.wztotal = 0;
                            // obj.zztotal = 0;
                        } else {
                            obj.wztotal = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_3.result + resData.data.dimension_a_4.result;
                            obj.zztotal = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result + resData.data.dimension_b_4.result
                            obj.className = className;
                            obj.wzData = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_4.result;
                            obj.zzData = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result;
                            this.tableData_four.tabal2.push(obj);
                        }

                        break;
                    case '五年级':
                        var obj = {};
                        if (JSON.stringify(resData.data) == '{}') {} else {
                            obj.wztotal = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_3.result + resData.data.dimension_a_4.result;
                            obj.zztotal = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result + resData.data.dimension_b_4.result
                            obj.className = className;
                            obj.wzData = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_4.result;
                            obj.zzData = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result;
                            this.tableData_five.tabal2.push(obj);
                        }

                        break;
                    case '六年级':
                        var obj = {};
                        if (JSON.stringify(resData.data) == '{}') {
                            // obj.className = className;
                            // obj.wzData = 0;
                            // obj.zzData = 0;
                            // obj.wztotal = 0;
                            // obj.zztotal = 0;
                        } else {
                            obj.wztotal = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_3.result + resData.data.dimension_a_4.result;
                            obj.zztotal = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result + resData.data.dimension_b_4.result
                            obj.className = className;
                            obj.wzData = resData.data.dimension_a_1.result + resData.data.dimension_a_2.result +
                                resData.data.dimension_a_4.result;
                            obj.zzData = resData.data.dimension_b_1.result + resData.data.dimension_b_2.result +
                                resData.data.dimension_b_3.result;
                            this.tableData_six.tabal2.push(obj);
                        }

                        break;
                }
            },
            // 判断是否有新版报告
            async hasNewReport(termKey) {
                let data = {
                    orgId: this.schoolOrgID,
                    termKey
                }
                let resData = await this.$Api.DataScreen.hasNewReport(data);
                // console.log('查询是否有新报告:',resData);
                if (resData.data) {
                    this.termValue = termKey;
                    this.gradeOne = {};
                    this.gradeTwo = {};
                    this.gradeThree = {};
                    this.gradeFour = {};
                    this.gradeFive = {};
                    this.gradeSix = {};
                    this.bihuaTableData = [];
                    this.zhangfaTableData = [];
                    this.jiegouTableData = [];
                    this.gradeCommentList_all = [];
                    this.queryUserMaxOrg();
                    this.getwdCode();
                } else {
                    this.$router.replace('/report')
                }
            }
        },
        mounted() {
            this.getTermList();
        },
    };
</script>
<style lang='less' scoped>
    @import url("./less/report.less");

    .tac {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 500px;

        .el-col-12 {
            height: 100vh;

            ul {
                height: 100%;
            }
        }
    }
</style>