<!-- 自定义table组件 -->
<template>
    <div class="myTable">
        <!-- 整体注册人数 -->
        <table border="1" class="table" v-if="type == 'join'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>{{ item.termName }}</td>
                <td>{{ item.registerCount }}</td>
                <td>{{ item.joinCount }}</td>
                <td>{{ item.joinRate.toFixed(2) }}%</td>
            </tr>
        </table>
        <!-- 整体坐姿 -->
        <table border="1" class="table" v-if="type == 'wbzs'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in newTableData" :key="index">
                <td>{{ item.gradeName }}</td>
                <td>{{ item.total - item.posture_three }}</td>
                <td>{{ (100 - item.rightRatio).toFixed(2) }}%</td>
            </tr>
        </table>
        <!-- 整体握笔姿势 -->
        <table border="1" class="table" v-if="type == 'zz'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in newTableData" :key="index">
                <td>{{ item.gradeName }}</td>
                <td>{{ item.total - item.sit_four }}</td>
                <td>{{ (100 - item.rightRatio).toFixed(2) }}%</td>
            </tr>
        </table>
        <!-- 整体书写维度 -->
        <p v-if="type == 'sxwd'">针对前测样本数据综合分析四个书写基础维度得分，学生在{{ wdData_sx[1] }}{{ wdData_sx[0].length }}个维度低于全国平均水平。</p>
        <table border="1" class="table" v-if="type == 'sxwd'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in wdData" :key="index">
                <td>{{ item.wd }}</td>
                <td>{{ item.tip }}</td>
                <td>{{ item.num }}
                    <span v-if="Number(item.num) > item.all">↑</span>
                    <span v-else style="color:red">↓</span>
                </td>
                <td>{{ item.all }}</td>
            </tr>
        </table>
        <!-- 整体优秀作品 -->
        <table border="1" class="table" v-if="type == 'works'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>{{ item.userName }}</td>
                <td>{{ item.gradeName }}</td>
                <td>{{ item.className.substring(3) }}</td>
            </tr>
        </table>
        <!-- 整体教师上课情况 -->
        <table border="1" class="table" v-if="type == 'teacher'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.gradeName }}</td>
                <td>{{ item.className }}</td>
                <td>{{ item.userName }}</td>
                <td>{{ item.count }}</td>
            </tr>
        </table>
        <!-- 整体笔画形态 -->
        <table border="1" class="table" v-if="type == 'bihua'">
            <tr>
                <th colspan="10">方向、笔性、粗细维度成绩分析</th>
            </tr>
            <tr>
                <th v-for="(item, index) in bihuaThead" :key="index">{{ item }}</th>
            </tr>
            <tr>
                <td>方向</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.fx_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>笔性</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.bx_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>粗细</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.cx_val.toFixed(2) }}</td>
            </tr>
        </table>
        <!-- 整体章法占格 -->
        <table border="1" class="table" v-if="type == 'zhangfa'">
            <tr>
                <th colspan="10">章法占格成绩分析</th>
            </tr>
            <tr>
                <th v-for="(item, index) in bihuaThead" :key="index">{{ item }}</th>
            </tr>
            <tr>
                <td>卷面整洁</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.jm_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>占格占位</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.zg_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>字形大小</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.zx_val.toFixed(2) }}</td>
            </tr>
        </table>
        <!-- 整体结构组合 -->
        <table border="1" class="table" v-if="type == 'jiegou'">
            <tr>
                <th colspan="10">字形重心、主次收放、笔画关系、部件关系维度成绩分析</th>
            </tr>
            <tr>
                <th v-for="(item, index) in bihuaThead" :key="index">{{ item }}</th>
            </tr>
            <tr>
                <td>字形重心</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.zx_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>主次收放</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.zc_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>笔画关系</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.bh_val.toFixed(2) }}</td>
            </tr>
            <tr>
                <td>部件关系</td>
                <td v-for="(item, index) in tableData" :key="index">{{ item.bj_val.toFixed(2) }}</td>
            </tr>
        </table>
        <!-- 各班级握笔姿势 -->
        <p v-if="type == 'wbzs_class' && maxwbzs_className.length > 0">
            (1)针对握笔姿势,有{{ total_wbzs }}名学生姿势错误,占全年级的{{ wbzs_rate }}%
            <span v-if="tableData.length > 1">,其中{{ maxwbzs_className[0] }}的错误率最高,{{ maxwbzs_className[1] }}错误率最低</span>
        </p>
        <table border="1" class="table" v-if="type == 'wbzs_class'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>{{ item.className }}</td>
                <td>{{ item.wzData }}</td>
                <td>{{ (item.wzData / item.wztotal * 100).toFixed(2) }}%</td>
            </tr>
            <template v-if="empty_class.length > 0">
                <tr v-for="(item, index) in empty_class" :key="index">
                    <td>{{ item.termName }}</td>
                    <td>0</td>
                    <td>0.00%</td>
                </tr>
            </template>
        </table>
        <!-- 各班级坐姿 -->
        <p v-if="type == 'zz_class' && maxzz_className.length > 0">
            (2)针对坐姿,有{{ total_zz }}名学生姿势错误,占全年级的{{ zz_rate }}%
            <span v-if="tableData.length > 1">,其中{{ maxzz_className[0] }}的错误率最高,{{ maxzz_className[1] }}错误率最低</span>
        </p>
        <table border="1" class="table" v-if="type == 'zz_class'">
            <tr>
                <th v-for="(item, index) in thead" :key="index">{{ item }}</th>
            </tr>
            <tr v-for="(item, index) in tableData" :key="index">
                <td>{{ item.className }}</td>
                <td>{{ item.zzData }}</td>
                <td>{{ (item.zzData / item.zztotal * 100).toFixed(2) }}%</td>
            </tr>
            <template v-if="empty_class.length > 0">
                <tr v-for="(item, index) in empty_class" :key="index">
                    <td>{{ item.termName }}</td>
                    <td>0</td>
                    <td>0.00%</td>
                </tr>
            </template>

        </table>

    </div>
</template>
<script>
export default {
    props: ['tableData', 'thead', 'type', 'tableAll'],
    computed: {
        newTableData() {
            this.tableData.map(item => {
                let str = item.name.substring(0, 1);
                // console.log(str);
                item.gradeName = this.classList[str]
            })
            return this.tableData.filter(item => {
                return item.name != '合计'
            })
        },
        wdData() {
            return [{
                wd: '作品完整度',
                tip: '内容完整、用字正确，标点符号使用精准',
                num: this.tableData.all_wzd_art ? this.tableData.all_wzd_art : this.tableData.all_wzd,
                all: 82.42
            },
            {
                wd: '笔画形态',
                tip: '笔法正确，字法规范，与规范例字书写相似	',
                num: this.tableData.all_xsx_art ? this.tableData.all_xsx_art : this.tableData.all_xsx,
                all: 79.82
            },
            {
                wd: '章法占格',
                tip: '书写自然流畅，大小占格合理',
                num: this.tableData.all_yzx_art ? this.tableData.all_yzx_art : this.tableData.all_yzx,
                all: 74.45
            },
            {
                wd: '结构组合	',
                tip: '排布协调，横成行、纵成列，占格居中',
                num: this.tableData.all_zqd_art ? this.tableData.all_zqd_art : this.tableData.all_zqd,
                all: 73.40
            },
            ]
        },
        wdData_sx() {
            let arr = [];
            let str = '';
            this.wdData.map((item, index) => {
                if (item.num < item.all) {
                    arr.push(item.wd);
                    str += (index == this.wdData.length - 1) ? item.wd : item.wd + '、'
                }
            })
            // console.log('str:', ...arr)
            return [arr, str]
        },
        bihuaThead() {
            let thead = ['二级维度']
            this.tableData.map(item => {
                // if(item.bx_val&&item.cx_val&&item.fx_val){
                thead.push(item.name)
                // }

            })
            return thead
        },
        total_wbzs() {
            var count = 0;
            this.tableData.map(item => {
                count += item.wzData
            })
            return count
        },
        wbzs_rate() {
            var count = 0;
            var total = 0;
            this.tableData.map(item => {
                count += item.wzData;
                total += item.wztotal;
            })
            return (count / total * 100).toFixed(2)
        },
        maxwbzs_className() {
            try {
                var maxVal = (this.tableData[0].wzData / this.tableData[0].wztotal * 100).toFixed(2);
                var maxName = this.tableData[0].className;
                var minVal = (this.tableData[0].wzData / this.tableData[0].wztotal * 100).toFixed(2);
                var minName = this.tableData[0].className;
                this.tableData.map(item => {
                    if ((item.wzData / item.wztotal * 100).toFixed(2) > maxVal) {
                        maxVal = (item.wzData / item.wztotal * 100).toFixed(2);
                        maxName = item.classNmae;
                    }
                    if ((item.wzData / item.wztotal * 100).toFixed(2) < minVal) {
                        minVal = (item.wzData / item.wztotal * 100).toFixed(2);
                        minName = item.className;
                    }
                })
                return [maxName, minName]
            } catch (err) {
                // console.log('没有数据:', err)
                return []
            }

        },
        total_zz() {
            var count = 0;
            this.tableData.map(item => {
                count += item.zzData
            })
            return count
        },
        zz_rate() {
            var count = 0;
            var total = 0;
            this.tableData.map(item => {
                count += item.zzData;
                total += item.zztotal;
            })
            return (count / total * 100).toFixed(2)
        },
        maxzz_className() {
            try {
                var maxVal = (this.tableData[0].zzData / this.tableData[0].zztotal * 100).toFixed(2);
                var maxName = this.tableData[0].className;
                var minVal = (this.tableData[0].zzData / this.tableData[0].zztotal * 100).toFixed(2);
                var minName = this.tableData[0].className;
                this.tableData.map(item => {
                    if ((item.zzData / item.zztotal * 100).toFixed(2) > maxVal) {
                        maxVal = (item.zzData / item.zztotal * 100).toFixed(2);
                        maxName = item.classNmae;
                    }
                    if ((item.zzData / item.zztotal * 100).toFixed(2) < minVal) {
                        minVal = (item.zzData / item.zztotal * 100).toFixed(2);
                        minName = item.className;
                    }
                })
                return [maxName, minName]
            } catch (err) {
                // console.log('zz没有数据:', err)
                return []
            }

        },
        empty_class() {
            console.log('this.tableAll', this.tableAll);
            console.log('this.tableData', this.tableData)
            let result = [];
            for (let i = 0; i < this.tableAll.length; i++) {
                let found = false;
                // 循环遍历 table，检查是否存在相同的班级
                for (let j = 0; j < this.tableData.length; j++) {
                    if (this.tableData[j].className === this.tableAll[i].termName) {
                        found = true;
                        break;
                    }
                }
                // 如果没有找到匹配的班级，则将其添加到结果数组中
                if (!found) {
                    result.push(this.tableAll[i]);
                }
            }
            console.log('result:', result)
            return result
        }
    },
    data() {
        return {
            classNmae: ['二级维度', '一年级', '二年级', '三年级'],
            teaData: [{
                grade: '一年级',
                class: '3班',
                name: '秦可欣',
                count: '123'
            },
            {
                grade: '一年级',
                class: '3班',
                name: '秦可欣',
                count: '111'
            },
            {
                grade: '一年级',
                class: '3班',
                name: '秦可欣',
                count: '222'
            },
            {
                grade: '一年级',
                class: '3班',
                name: '秦可欣',
                count: '333'
            },
            {
                grade: '一年级',
                class: '3班',
                name: '秦可欣',
                count: '444'
            },
            ],
            classList: {
                1: '一年级',
                2: '二年级',
                3: '三年级',
                4: '四年级',
                5: '五年级',
                6: '六年级'
            }
        }
    },
    methods: {},
    mounted() { },
};
</script>
<style lang='less' scoped>
@import url("./less/table.less");
</style>