<template>
    <div class="whole_table">
        <el-table :data="tableData" :resizable="false" :header-cell-style="headerCellStyle"
            :header-cell-class-name="box_size" :cell-style="cellStyle" class="wholeTable" height="850"
            @cell-click="selectStu" :empty-text="loadInfo">
            <el-table-column label="维度" align="center" fixed width="180">
                <el-table-column label="等级" align="center" fixed width="180">
                    <el-table-column label="书写目标" align="center" fixed width="180">
                        <!-- 192 -->
                        <el-table-column prop="userId" align="center" label="编号" width="55">
                            <template slot="header" slot-scope="scope">
                                <div class="text">编号</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="userName" align="center" label="姓名" width="55">
                            <!-- 67 -->
                            <template slot="header" slot-scope="scope">
                                <div class="text">姓名</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="className" align="center" label="班级" width="70">
                            <!-- 70 -->
                            <template slot="header" slot-scope="scope">
                                <div class="text">班级</div>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="居中占位" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="center_pos_levleA" align="center" label="占位精准" class="update-table"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">占位精准</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="center_pos_levleB" align="center" label="基本居中"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">基本居中</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="center_pos_levleC" align="center" label="略有偏移"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">略有偏移</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="center_pos_levleD" align="center" label="严重偏移"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">严重偏移</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="占格大小" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="grid_size_levleA" align="center" label="大小准确"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">大小准确</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="grid_size_levleB" align="center" label="大小合理"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">大小合理</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="grid_size_levleC" align="center" label="略有缩放"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">略有缩放</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="grid_size_levleD" align="center" label="缩放严重"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">缩放严重</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="字形重心" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="glyph_grav_levleA" align="center" label="险绝挺拔"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">险绝挺拔</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="glyph_grav_levleB" align="center" label="重心稳定"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">重心稳定</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="glyph_grav_levleC" align="center" label="略有歪斜"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">略有歪斜</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="glyph_grav_levleD" align="center" label="严重失衡"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">严重失衡</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="组合关系" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="combo_rel_levleA" align="center" label="穿插合理"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">穿插合理</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="combo_rel_levleB" align="center" label="略显局促"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">略显局促</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="combo_rel_levleC" align="center" label="字形松散"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">字形松散</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="combo_rel_levleD" align="center" label="比例失度"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">比例失度</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="收放关系" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="rewind_rel_levleA" align="center" label="收放自如"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">收放自如</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="rewind_rel_levleB" align="center" label="略有收放"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">略有收放</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="rewind_rel_levleC" align="center" label="主体紧凑"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">主体紧凑</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="rewind_rel_levleD" align="center" label="收放失度"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">收放失度</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="多画等距" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="multi_dist_levleA" align="center" label="疏密有致"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">疏密有致</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="multi_dist_levleB" align="center" label="间距均匀"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">间距均匀</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="multi_dist_levleC" align="center" label="布白不均"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">布白不均</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="multi_dist_levleD" align="center" label="空间凌乱"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">空间凌乱</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="笔画关系" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="stroke_rel_levleA" align="center" label="关系精准"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">关系精准</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="stroke_rel_levleB" align="center" label="关系准确"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">关系准确</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="stroke_rel_levleC" align="center" label="关系失准"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">关系失准</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="stroke_rel_levleD" align="center" label="关系混乱"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">关系混乱</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column label="作品完整度" align="center">
                <el-table-column label="优秀" align="center">
                    <el-table-column prop="integrity_levleA" align="center" label="完成90%以上"
                        :filters="[{ text: '优秀', value: '优秀' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">完成90%以上</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="良好" align="center">
                    <el-table-column prop="integrity_levleB" align="center" label="完成80%以上"
                        :filters="[{ text: '良好', value: '良好' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">完成80%以上</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="合格" align="center">
                    <el-table-column prop="integrity_levleC" align="center" label="完成60%以上"
                        :filters="[{ text: '合格', value: '合格' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">完成60%以上</div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="待提升" align="center">
                    <el-table-column prop="integrity_levleD" align="center" label="完成60%以下"
                        :filters="[{ text: '待提升', value: '待提升' }, { text: '空白', value: '' }]" :filter-method="filterHandler"
                        width="37">
                        <template slot="header" slot-scope="scope">
                            <div class="text">完成60%以下</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column :label="schoolName" align="center">
                <el-table-column label="路" align="center">
                    <el-table-column prop="excellent+" align="center" :filters="excellent_add_total" label="优秀"
                        :filter-method="filterHandler" width="47">
                        <template slot="header" slot-scope="scope">
                            <div class="text">优秀</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="excellent-" align="center" :filters="excellent_minus_total" label="良好"
                        :filter-method="filterHandler" width="42">
                        <template slot="header" slot-scope="scope">
                            <div class="text">良好</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="good" align="center" :filters="good_total" label="合格"
                        :filter-method="filterHandler" width="42">
                        <template slot="header" slot-scope="scope">
                            <div class="text">合格</div>
                        </template>
                    </el-table-column>
                    <!-- width="52" -->
                    <el-table-column prop="improve" align="center" :filters="improve_add_total" label="待提升"
                        :filter-method="filterHandler" width="48">
                        <template slot="header" slot-scope="scope">
                            <div class="text">待提升</div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            schoolName: '',
            loadInfo: '暂无数据',
            userId: [],
            username: [],
            className: [],

            options1: [],
            options2: [],
            value5: [],
            value11: [],
            input: '',

            excellent_add_total: [],
            excellent_minus_total: [],
            good_total: [],
            improve_add_total: [],

            once: true,
            whole_termList: [],

            whole_termValue: '',
            termKey: null,
            currentTerm: '',
            orgId: '',
            teac_Grade_class: '',
            roleType: '',
            reportValue: '',
            monthValue: '',
            table_type: '',
            schoolIdArr: ['181166', '1176', '181095', '181165', '1172', '181164', '1175', '181097', '1154'],
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 样式修改
        headerCellStyle({
            row,
            column,
            rowIndex,
            columnIndex
        }) {

            if (column.level == 3) {
                if (column.label != "书写目标") {
                    return {
                        padding: 0,
                    };
                }
            }
            if (column.label === this.schoolName) {
                this.$nextTick(() => {
                    document.querySelector(`.${column.id}`).setAttribute("rowSpan", "2");
                    let cell = document.querySelector(`.${column.id}`).querySelector(".cell");
                    // cell.style.lineHeight = "40px";
                    cell.style.lineHeight = "28px";
                });
                return {
                    color: "#01B58C",
                    fontWeight: "bold",
                    backgroundColor: '#d9f4ee',
                    fontSize: '18px',
                };
            }
            if (column.label === "地") {
                return {
                    display: "none"
                };
            }
            if (column.label === "路") {
                return {
                    display: "none"
                };
            }
            //维度
            if (column.level == 1) {
                if (columnIndex != 9) {
                    if (columnIndex % 2) {
                        return {
                            backgroundColor: '#daf4ed',
                            color: "#43c4a1"
                        }

                    } else {
                        return {
                            backgroundColor: '#f2fbf8',
                            color: "#43c4a1"
                        }
                    }
                }

            }
            //一级二级维度
            if (rowIndex == 2 || rowIndex == 1) {
                if (columnIndex > 0 && columnIndex < 45) {
                    return {
                        color: "#000",
                        writingMode: 'tb-rl',
                    };
                }
            }
            return {
                borderColor: "ightcoral",
                color: "#000"
            };
        },
        box_size({
            row,
            column,
            rowIndex,
            columnIndex
        }) {

            if (column.level == 3) {
                // if (column.label === "占位精准" || column.label === "大小准确" || column.label === "重心稳定" || column.label === "略有收放" || column.label === "疏密有致" || column.label === "关系精准" || column.label === "穿插合理" || column.label === "完成90%以上") {
                //     return 'color5'
                // }
                if (column.label != "书写目标" && column.label != "优秀" && column.label != "良好" && column.label != "合格" && column.label != "待提升") {
                    return 'table_header'
                } else if (column.label == "优秀" || column.label == "良好" || column.label == "合格" || column.label == "待提升") {
                    return 'table_header3'
                }
            } else if (column.level == 4) {
                return 'table_header2'
            }
        },
        cellStyle({
            row,
            column,
            rowIndex,
            columnIndex
        }) {

            if (column.level == 3) {
                if (column.label == '优秀' || column.label == '良好' || column.label == '合格' || column.label == '待提升') {
                    return {
                        color: "#01B58C",
                    };
                }
            }
            if (column.label == '姓名') {
                return {
                    color: "#22a5f1",
                    textDecoration: 'underline'
                };
            }
        },
        //过滤选择
        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] == value;
        },
        //点击跳转报告
        selectStu(row, column, cell, event) {
            if (column.label == '姓名') {
                const routeUrl = this.$router.resolve({
                    // path: '/ReportZhuShouStu',
                    path: '/NewReportZhuShouStu',
                    query: {
                        userId: row.userId,
                        termKey: this.whole_termValue,
                        termKeyName: this.currentTerm,
                        reportValue: this.reportValue,
                        monthValue: this.monthValue,
                        term_type: this.table_type,
                    }
                })
                window.open(routeUrl.href, '_blank')

            }
        },
        //表单数据
        async getSchoolCommentReport() {
            this.tableData = [];
            let data = {
                orgId: this.orgId,
                termKey: this.termKey,
                gradeId: this.roleType == 2 ? '' : this.value5,
                classId: this.value11,
                userName: this.input,
                examType: this.reportValue,
                examId: this.monthValue,
            };
            this.loadInfo = '数据加载中...';
            let resData = await this.$Api.DataScreen.getSchoolCommentReport(data);
            if (this.hide_school()) {
                this.loadInfo = '暂无数据';
                return false;
            } else {
                if (resData == null || resData == undefined || resData == '' || resData.data.length == 0) {
                    this.loadInfo = '暂无数据';
                    return false;
                } else {
                    this.loadInfo = '暂无数据';
                }
            }
            let newArray = resData.data.reduce((acc, current) => {
                // 查找新数组中是否已存在具有相同userId的对象
                let existingUser = acc.find(item => item.userId === current.userId);
                if (!existingUser) {
                    acc.push({
                        userName: current.userName,
                        className: current.className,
                        userId: current.userId,
                        [current.setCode]: current.setCode,
                        // [current.setCode + '_levle']: current.setLevel,
                        // [current.setCode]: current.setCode,
                        [current.setCode + '_levle']: current.setLevel,
                        [current.setCode + '_levle' + 'A']: current.setLevel == 4 ? '优秀' : '',
                        [current.setCode + '_levle' + 'B']: current.setLevel == 3 ? '良好' : '',
                        [current.setCode + '_levle' + 'C']: current.setLevel == 2 ? '合格' : '',
                        [current.setCode + '_levle' + 'D']: current.setLevel == 1 ? '待提升' : '',
                    });
                } else {
                    existingUser[current.setCode] = current.setCode;
                    // existingUser[current.setCode + '_levle'] = current.setLevel
                    // existingUser[current.setCode] = current.setCode;
                    existingUser[current.setCode + '_levle'] = current.setLevel;
                    existingUser[current.setCode + '_levle' + 'A'] = current.setLevel == 4 ? '优秀' : '';
                    existingUser[current.setCode + '_levle' + 'B'] = current.setLevel == 3 ? '良好' : '';
                    existingUser[current.setCode + '_levle' + 'C'] = current.setLevel == 2 ? '合格' : '';
                    existingUser[current.setCode + '_levle' + 'D'] = current.setLevel == 1 ? '待提升' : '';
                }

                return acc;
            }, []);
            //类别数组
            let keysToCheck = ['center_pos', 'grid_size', 'glyph_grav', 'rewind_rel', 'multi_dist', 'stroke_rel', 'combo_rel', 'integrity']
            let temporaryData = newArray;
            this.tableData = temporaryData.filter(item => {
                return keysToCheck.every(key => key in item);
            });
            this.tableData = this.tableData.slice(0, 50);
            //添加求和字段
            for (let index = 0; index < this.tableData.length; index++) {
                //添加求和字段
                this.tableData[index]['excellent+'] = this.count(this.tableData[index], '优秀');
                this.tableData[index]['excellent-'] = this.count(this.tableData[index], '良好');
                this.tableData[index]['good'] = this.count(this.tableData[index], '合格');
                this.tableData[index]['improve'] = this.count(this.tableData[index], '待提升');
                //前三（编号，姓名，班级）
                this.userId.push({
                    text: this.tableData[index].userId,
                    value: this.tableData[index].userId,
                });
                this.username.push(
                    {
                        text: this.tableData[index].userName,
                        value: this.tableData[index].userName,
                    }
                );
                this.className.push({
                    text: this.tableData[index].className,
                    value: this.tableData[index].className,
                });
            }
            const obj = {};
            const newArr = [];
            this.className.forEach((item) => {
                obj[item.value] ? "" : (obj[item.value] = true && newArr.push(item));
            });
            this.className = newArr;
            // 后四项筛选 去重、
            let excellent_add = [...new Set(this.tableData.map(item => item['excellent+']))];
            excellent_add.sort((a, b) => a - b);
            this.excellent_add_total = excellent_add.map(name => ({ value: name, text: name }));
            let excellent_minus = [...new Set(this.tableData.map(item => item['excellent-']))];
            excellent_minus.sort((a, b) => a - b);
            this.excellent_minus_total = excellent_minus.map(name => ({ value: name, text: name }));
            let good = [...new Set(this.tableData.map(item => item['good']))];
            good.sort((a, b) => a - b);
            this.good_total = good.map(name => ({ value: name, text: name }));
            let improve = [...new Set(this.tableData.map(item => item['improve']))];
            improve.sort((a, b) => a - b);
            this.improve_add_total = improve.map(name => ({ value: name, text: name }));
        },
        // 计算数量
        count(obj, type) {
            if (type == '优秀') {
                let num = 0;
                for (var i in obj) {

                    if (i != 'excellent+' && i != 'excellent-' && i != 'good' && i != 'improve') {
                        if (obj[i] == 4) {

                            num += 1
                        }
                    }
                }
                return num
            }
            if (type == '良好') {
                let num = 0;
                for (var i in obj) {
                    if (i != 'excellent+' && i != 'excellent-' && i != 'good' && i != 'improve') {
                        if (obj[i] == 3) {
                            num += 1
                        }
                    }
                }
                return num
            }
            if (type == '合格') {
                let num = 0;
                for (var i in obj) {
                    if (i != 'excellent+' && i != 'excellent-' && i != 'good' && i != 'improve') {
                        if (obj[i] == 2) {
                            num += 1
                        }
                    }
                }
                return num
            }
            if (type == '待提升') {
                let num = 0;
                for (var i in obj) {
                    if (i != 'excellent+' && i != 'excellent-' && i != 'good' && i != 'improve') {
                        if (obj[i] == 1) {
                            num += 1
                        }
                    }
                }
                return num
            }

        },
        hide_school() {
            let type = this.schoolIdArr.includes(this.$route.query.schoolId);
            if (this.schoolIdArr.includes(this.$route.query.schoolId) && this.termKey == '20232') {
                type = true;
            } else {
                type = false;
            }
            return type;
        },
        getParentData(orgId, termKey, roleType, value5, value5_name, value11, input, whole_termValue, currentTerm, table_type, reportValue, monthValue) {
            this.orgId = orgId;
            this.termKey = termKey;
            this.roleType = roleType;
            this.value5 = value5;
            this.value11 = value11;
            this.input = input;
            this.whole_termValue = whole_termValue;
            this.currentTerm = currentTerm;
            this.reportValue = reportValue;
            this.monthValue = monthValue;
            this.table_type = table_type;
            console.log('this.term_type------',this.table_type);
            this.getSchoolCommentReport();
            this.updateTableHeaders(table_type, value5_name)
        },
        updateTableHeaders(table_type, class_name_type) {
            // console.log('class_name_type',class_name_type);
            let elements = document.querySelectorAll('.table_header');
            // console.log('elements',elements.length);
            for (let index = 0; index < elements.length; index++) {
                elements[index].style.background = '#fff';
            }
            let arr = new Array();
            //上学期
            if (class_name_type == '一年级' || class_name_type == '二年级') {
                arr = [1, 5, 9, 14, 17, 22, 25, 29];
            } else if (class_name_type == '三年级' || class_name_type == '四年级') {
                arr = [1, 5, 9, 13, 17, 21, 24, 28];
            } else if (class_name_type == '五年级' || class_name_type == '六年级') {
                arr = [0, 4, 9, 12, 16, 21, 24, 28];
            }
            for (let index = 0; index < elements.length; index++) {
                for (let index1 = 0; index1 < arr.length; index1++) {
                    const element1 = arr[index1];
                    if (index == element1) {
                        elements[index].style.background = '#e5f7f3';
                    }
                }
            }
        },

    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.schoolName = this.$route.query.schoolName;
        this.$nextTick(() => {
            this.updateTableHeaders();
        });
        // console.log('我是新版表头');
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped></style>
<style>
/* .table_header {
    background-color: blue !important;
} */
</style>