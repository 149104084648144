<!-- 个人报告2.0(人工点评) -->
<template>
    <div class="stu_report flex-column-center">
        <div class="title">学生个人报告</div>
        <div class="className">{{className}}</div>
        <p>截止目前，本轮前测中，共计收到本班{{stuCount}}位同学提交的{{pageCount}}份试卷。以下是本班参与书写前期测试的学生名单：</p>
        <div class="allStu">
            <p>（按姓名音序排列）</p>
            <table border="1">
                <tr v-for="(item,index) in stuList" :key="index">
                    <td :class="{'actived':currentUser==items.userId}" v-for="items in item" :key="items.userId"
                        @click="newPersonReport(items.userId)">{{items.userName}}
                    </td>
                </tr>
            </table>
            <p>请点击姓名查看学生个人报告：</p>
        </div>
        <template v-if="showReport">
            <div class="stuItem" id="pdfDom">
                <div class="stuTitle flex-align-center">【{{currentInfo.userInfo.userName}}】汉字识写能力报告
                    <!-- <el-button type="primary" @click="outPutPdfFn" icon="el-icon-download">下载个人报告</el-button> -->
                </div>
                <span style="font-weight:bold">个人信息：</span>
                <table border="1">
                    <tr>
                        <td>姓名</td>
                        <td>{{currentInfo.userInfo.userName}}</td>
                    </tr>
                    <tr>
                        <td>班级</td>
                        <td>{{className}}</td>
                    </tr>
                    <tr>
                        <td>ID</td>
                        <td>{{currentInfo.userInfo.userId}}</td>
                    </tr>
                    <tr>
                        <td>测评时间</td>
                        <td>{{dayjs(currentInfo.createTime).format("YYYY-MM-DD HH:mm")}}</td>
                    </tr>
                    <tr>
                        <td>试卷回收</td>
                        <td>共{{currentInfo.papers}}页</td>
                    </tr>
                </table>
                <span>根据该生前测调查内容，分析如下：</span>
                <div class="part_one item">
                    <div class="title ">1、书写习惯</div>
                    <p>在后测中，该生握笔姿势{{currentInfo.woziSelecteds[0]==3?'正确':'错误'}}，坐姿{{currentInfo.zuoziSelecteds[0]==4?'正确':'错误'}}。针对常见的学生双姿与书写规范，我们在平台上提供了专家录制的握笔教学视频，可在课堂中跟随老师进行学习，养成良好的书写习惯。</p>
                    <!-- <p class="p_title">（1）握笔姿势</p> -->
                    <img src="../../assets/report/wobi.jpg">
                </div>
                <div class="part_three item">
                    <div class="title ">2、书写水平</div>
                    <p>针对后测样本数据综合分析四个书写基础维度得分，该生在{{sxInfo.str}}{{sxInfo.count}}个维度低于全国平均水平。</p>
                    <table border="1">
                        <tr>
                            <th>书写维度</th>
                            <th>考核要求</th>
                            <th>成绩</th>
                            <th>全国常模</th>
                        </tr>
                        <tr>
                            <td>作品完整度</td>
                            <td>内容完整、用字正确、标点符号使用精准</td>
                            <td>{{tableData.completeScore.toFixed(2)}}</td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[0]}}</td>
                        </tr>
                        <tr>
                            <td>笔画形态</td>
                            <td>笔法正确、字法规范、与规范例字书写相似</td>
                            <td>{{tableData.formScore.toFixed(2)}}</td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[2]}}</td>
                        </tr>
                        <tr>
                            <td>章法占格</td>
                            <td>书写自热流畅，大小占格合理</td>
                            <td>{{tableData.layoutScore.toFixed(2)}}</td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[1]}}</td>
                        </tr>
                        <tr>
                            <td>结构组合</td>
                            <td>排布协调。横成行、纵成列，占格居中</td>
                            <td>{{tableData.structureScore.toFixed(2)}}</td>
                            <td>{{gradeInfo[currentGradeName].writeDefault[3]}}</td>
                        </tr>
                    </table>
                    <p v-for="(item,index) in badComment" :key="index">{{item}}</p>
                    <div class="title " v-if="tableData.personalBestWord">3、书写较好的字</div>
                    <p v-if="tableData.personalBestWord"> 该学生在前测/后测中，书写较好的有以下几个字，请继续保持。</p>
                    <div class="bestWord" v-if="tableData.personalBestWord">
                        <div>
                            <div class="best" v-for="(item,index) in tableData.personalBestWord" :key=index>{{ item }}</div>
                        </div>
                    </div>
                    <div class="title ">4、书写基础解析</div>
                    <p>通过该学生在整个试卷中的汉字书写，采用整体测评方式对所写内容的作品完整度、笔画形态、章法占格、结构组合四个维度进行分析。如下：</p>
                    <div class="myChart item" id="myChart_stu"></div>
                    <p>该生作品整体水平为"{{levelList[level]}}"。</p>
                    <!--  作品完整度、章法占格、笔画形态、结构组合 -->
                    <p>(1)作品完整度成绩为{{tableData.completeScore.toFixed(2)}}，
                        {{tableData.completeScore>gradeInfo[currentGradeName].writeDefault[0]?'高于':'低于'}}
                        全国常模{{gradeInfo[currentGradeName].writeDefault[0]}}，
                        {{tableData.analyseAll.complete[0].comment}},{{tableData.analyseAll.complete[0].describe}}。</p>
                    <p>(2)笔画形态成绩为{{tableData.formScore.toFixed(2)}}，
                        {{tableData.formScore>gradeInfo[currentGradeName].writeDefault[2]?'高于':'低于'}}
                        全国常模{{gradeInfo[currentGradeName].writeDefault[2]}}。</p>
                    <p>{{tableData.analyseAll.form[0].name}}--{{tableData.analyseAll.form[0].comment}},{{tableData.analyseAll.form[0].describe}}
                    </p>
                    <p>{{tableData.analyseAll.form[1].name}}--{{tableData.analyseAll.form[1].comment}},{{tableData.analyseAll.form[1].describe}}
                    </p>
                    <p>{{tableData.analyseAll.form[2].name}}--{{tableData.analyseAll.form[2].comment}},{{tableData.analyseAll.form[2].describe}}
                    </p>
                    <p>(3)章法占格成绩为{{tableData.layoutScore.toFixed(2)}}，
                        {{tableData.layoutScore>gradeInfo[currentGradeName].writeDefault[1]?'高于':'低于'}}
                        全国常模{{gradeInfo[currentGradeName].writeDefault[1]}}。</p>
                    <p>{{tableData.analyseAll.layout[0].name}}--{{tableData.analyseAll.layout[0].comment}},{{tableData.analyseAll.layout[0].describe}}
                    </p>
                    <p>{{tableData.analyseAll.layout[1].name}}--{{tableData.analyseAll.layout[1].comment}},{{tableData.analyseAll.layout[1].describe}}
                    </p>
                    <p>{{tableData.analyseAll.layout[2].name}}--{{tableData.analyseAll.layout[2].comment}},{{tableData.analyseAll.layout[2].describe}}
                    </p>
                    <p>(4)结构组合成绩为{{tableData.structureScore.toFixed(2)}}，
                        {{tableData.structureScore>gradeInfo[currentGradeName].writeDefault[3]?'高于':'低于'}}
                        全国常模{{gradeInfo[currentGradeName].writeDefault[3]}}。</p>
                    <p>{{tableData.analyseAll.structure[0].name}}--{{tableData.analyseAll.structure[0].comment}},{{tableData.analyseAll.structure[0].describe}}
                    </p>
                    <p>{{tableData.analyseAll.structure[1].name}}--{{tableData.analyseAll.structure[1].comment}},{{tableData.analyseAll.structure[1].describe}}
                    </p>
                    <p>{{tableData.analyseAll.structure[2].name}}--{{tableData.analyseAll.structure[2].comment}},{{tableData.analyseAll.structure[2].describe}}
                    </p>
                    <p>{{tableData.analyseAll.structure[3].name}}--{{tableData.analyseAll.structure[3].comment}},{{tableData.analyseAll.structure[3].describe}}
                    </p>
                </div>
                <div class="part_two item">
                    <div class="title ">5、书写辨析</div>
                    <div class="title ">（1）基础识写与应用 </div>
                    <p>第二题为基础识写题，主要考察学生根据拼音和生字的轮廓、语义语境等认识生字的能力。测评发现，该生书写的正确率为
                        {{(currentInfo.userChart.dimension_c_1.result*100/currentInfo.userChart.dimension_c_1.count).toFixed(2)}}%，
                        本年级平均正确率为{{(currentInfo.gradeChart.q2_zql.result*100/currentInfo.gradeChart.q2_zql.count).toFixed(2)}}%。
                    </p>
                    <div class="title ">（2）部件与结构认知</div>
                    <p>第三题为部件与结构题，通过“偏旁部首组合生字”，可以考察学生对基本部件的认识，及所整组合生字的认识。能够考察学生对常用偏旁位置及组合规律的认识。
                        测评发现，该生正确率{{(currentInfo.userChart.dimension_c_2.result*100/currentInfo.userChart.dimension_c_2.count).toFixed(2)}}%，
                        本年级平均正确率为{{(currentInfo.gradeChart.q3_zql.result*100/currentInfo.gradeChart.q3_zql.count).toFixed(2)}}%。
                    </p>
                    <div class="title ">（3）笔顺笔画掌握</div>
                    <p>笔画关系到我们对汉字的基本结构和组织方式的理解，所以正确掌握书写顺序尤为重要。通过笔顺书写易错字的考察，
                        该生正确率{{(currentInfo.userChart.dimension_c_3.result*100/currentInfo.userChart.dimension_c_3.count).toFixed(2)}}%，
                        本年级平均正确率为{{(currentInfo.gradeChart.q4_zql.result*100/currentInfo.gradeChart.q4_zql.count).toFixed(2)}}%。
                        本题具有一定的难度，需要学生认识每个笔画，并熟练掌握书写顺序。</p>
                </div>
            </div>
        </template>

    </div>
</template>
<script>
    import {
        getPdf
    } from '../../utils/exportPdf'
    import MyTable from './table.vue';
    export default {
        props: ['className', 'classId', 'termKey'],
        components: {
            MyTable
        },
        data() {
            return {
                stuList: [],
                stuCount: '',
                pageCount: '',
                currentInfo: {
                    userInfo: {
                        userName: ''
                    },
                    woziSelecteds: []
                },
                chartData: [],
                level: '',
                currentUser: '',
                gradeInfo: {
                    '一年级': {
                        normDefault: 78, // 笔画形态全国常模
                        beautifulDefault: 76, // 章法占格全国常模
                        writeDefault: [86, 76, 78, 75], // 刻意书写全国常模,依次为 作品完整度、章法占格、笔画形态、结构组合
                        questions: ['大、田、少、书、马', '林、过、空、问', '小、山、大、王']
                    },
                    '二年级': {
                        normDefault: 78,
                        beautifulDefault: 76,
                        writeDefault: [86, 76, 78, 75],
                        questions: ['言、法、毛、川', '想、烟、都、摩', '为、出、火、竹']
                    },
                    '三年级': {
                        normDefault: 83,
                        beautifulDefault: 79,
                        writeDefault: [90, 79, 83, 78],
                        questions: ['目、色、虎、欣、鸟、墨', '群、抓、温、冒、背、花、围、道、问、成、及、母', '洞、报、为、垂、坐']
                    },
                    '四年级': {
                        normDefault: 83,
                        beautifulDefault: 79,
                        writeDefault: [90, 79, 83, 78],
                        questions: ['惊、程、犹、阔、横、卷', '堵顿跃、哲占劈、固凤阔、丈穴卫', '系、良、宅、滚、隙、庐']
                    },
                    '五年级': {
                        normDefault: 85,
                        beautifulDefault: 82,
                        writeDefault: [92, 82, 85, 81],
                        questions: ['朱、株、幕、暮、议、蚁、访、妨', '超、耻、闯、协、畔、露', '氏、糕、乃、岂、龟、慕']
                    },
                    '六年级': {
                        normDefault: 85,
                        beautifulDefault: 82,
                        writeDefault: [92, 82, 85, 81],
                        questions: ['国、民、余、绕、巧、夺', '凄、皎、骆、鸦、剔、射', '副、弦、汇、瓦、郑、尚']
                    }
                },
                showReport: false,
                comments: {
                    consistency: [
                        "学生整体对于单字的书写大小适中，整体表现优异。建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体书写大小基本一致，整体看来稍有不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。",
                        "学生整体字的书写大小偏差较大，分布不均匀，整体看来不协调，建议学生可以在今后的书写及训练中体验力量对笔画起伏变化的把控，更好表现出线条轻重、提按的变化，充分展示笔画质感。"
                    ],
                    like: [
                        "学生整体书写工整，与笔画形态较高，建议可以继续通过规律性的核心学习方法，举一反三运用在其他关联字中，进一步提升书写学习效率。",
                        "学生整体书写与例字基本相似，但是对于部分生字，如笔画较多、结构较为复杂的汉字，在书写时容易出现笔画使用不规范，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，提升自身规范书写效果。",
                        "学生整体书写不太规范，与笔画形态较低，在书写时容易出现笔画使用不规范、不合乎书写规律，汉字结构组合方式使用错误等问题，建议在今后的书写及训练中，以《通用规范汉字表》中字形、字样为标准，通过专家培训指导，提升自身规范书写基本功。"
                    ]
                },
                // termOptions: [],
                // termValue: ''

                // 2.0个人报告新增
                tableData: {
                    personalBestWord:[]
                },
                badComment: [],
                sxInfo: {
                    count: 0,
                    str: ''
                },
                level:0,
                levelList:{
                    1:'待提升',
                    2:'良好',
                    3:'优秀',
                    4:'优秀+'
                }
            }
        },
        computed: {
            diffName() {
                let num1 = Math.abs((this.currentInfo.userChart.dimension_f_4.result / this.currentInfo.userChart
                    .dimension_f_4.count).toFixed(2) - (this.currentInfo.userChart.dimension_d_4.result / this
                    .currentInfo.userChart.dimension_d_4.count).toFixed(2));
                let num2 = Math.abs((this.currentInfo.userChart.dimension_f_5.result / this.currentInfo.userChart
                    .dimension_f_5.count).toFixed(2) - (this.currentInfo.userChart.dimension_e_4.result / this
                    .currentInfo.userChart.dimension_e_4.count).toFixed(2));
                console.log(num1, num2)
                if (num1 > num2) {
                    return "笔画形态"
                } else {
                    return "章法占格"
                }
            },
            currentGradeName() {
                return this.className.slice(0, 3)
            }
        },
        watch: {
            classId: {
                handler(val) {
                    console.log('classId改变:', val);
                    this.classReport()
                },
                immediate: true
            }
        },
        methods: {
            exportpdf() {
                getPdf(`【${this.currentInfo.userInfo.userName}】汉字识写能力报告`, 'pdfDom')
            },
            initChart() {
                // console.log('初始化echarts')
                this.$echarts.init(document.getElementById("myChart_stu")).dispose();
                let myChart = this.$echarts.init(document.getElementById("myChart_stu"));
                myChart.setOption({
                    tooltip: {
                        show: true
                    },
                    radar: {
                        indicator: [{
                                name: '作品完整度',
                                max: 100
                            },
                            {
                                name: '笔画形态',
                                max: 100
                            },
                            {
                                name: '章法占格',
                                max: 100
                            },
                            {
                                name: '结构组合',
                                max: 100
                            }
                        ]
                    },
                    series: [{
                        symbolSize: 10,
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: 0.2,
                        },
                        lineStyle: {
                            width: 1,
                            color: "rgba(66, 242, 185, 1)",
                        },
                        type: 'radar',
                        data: [{
                            value: this.chartData,
                            name: '书写水平',
                            areaStyle: {
                                color: {
                                    type: "radial",
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.5,
                                    colorStops: [{
                                            offset: 0,
                                            color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 0.15,
                                            color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 0.75,
                                            color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                        }]
                    }]
                })
            },
            // 班级报告
            async classReport() {
                let data = {
                    classId: this.classId,
                    type: 1,
                    termKey: this.termKey
                }
                let resData = await this.$Api.DataScreen.queryClassReport(data);
                // console.log('班级报告:', resData);
                this.pageCount = resData.data.paperNumber;
                var chunk = 10;
                var len = resData.data.users.length;
                this.stuCount = len;
                var result = [];
                for (let i = 0; i < len; i += chunk) {
                    result.push(resData.data.users.slice(i, i + chunk)) // 每10项分成一组        
                }
                this.stuList = result;
                this.currentUser = result[0][0].userId;
                this.personRepost(result[0][0].userId);
                this.newPersonReport(result[0][0].userId)
            },
            // 个人报告
            async personRepost(userId) {
                let data = {
                    userId,
                    type: 1,
                    termKey: this.termKey
                }
                this.currentUser = userId;
                let resData = await this.$Api.DataScreen.queryPersonReport(data);
                console.log('个人报告:', resData);

                this.currentInfo = resData.data;
                this.chartData = [(resData.data.userChart.dimension_c_4.result * 100 / resData.data.userChart
                        .dimension_c_4.count).toFixed(2),
                    (resData.data.userChart.dimension_d_4.result / resData.data.userChart.dimension_d_4
                        .count).toFixed(2),
                    (resData.data.userChart.dimension_e_4.result / resData.data.userChart.dimension_e_4
                        .count).toFixed(2),
                    (resData.data.userChart.dimension_f_1.result / resData.data.userChart.dimension_f_1
                        .count).toFixed(2)
                ]
                let num = (this.currentInfo.userChart.dimension_d_4.result / this.currentInfo.userChart
                    .dimension_d_4.count).toFixed(2);
                // this.level = num > 80 ? '优秀' : num >= 65 && num < 80 ? '良好' : '待提升';

                this.showReport = true;
                setTimeout(() => {
                    this.initChart();
                }, 1000)
            },
            // 对比一致性与相似性
            comparison(val1, val2) {
                let result;
                if (val1 < val2) {
                    result = val1
                } else {
                    result = val2
                }
                // console.log('result:', result);
                if (result >= 80) {
                    return 0
                } else if (result >= 65 && result < 80) {
                    return 1
                } else {
                    return 2
                }
            },
            outPutPdfFn() {
                let vm = this;
                const A4_WIDTH = 592.28;
                const A4_HEIGHT = 841.89;
                // $myLoading 自定义等待动画组件，实现导出事件的异步等待交互
                // this.$myLoading('正在导出pdf，请稍候。。。', true);
                vm.$nextTick(() => {
                    // dom的id。
                    let target = document.getElementById('pdfDom');
                    let pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT;
                    // 获取分割dom，此处为class类名为item的dom
                    let lableListID = document.getElementsByClassName('item');
                    // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
                    for (let i = 0; i < lableListID.length; i++) {
                        let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) /
                            pageHeight);
                        if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                            let divParent = lableListID[i].parentNode; // 获取该div的父节点
                            let newNode = document.createElement('div');
                            newNode.className = 'emptyDiv';
                            newNode.style.background = '#ffffff';
                            let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i]
                                .offsetHeight);
                            newNode.style.height = _H + 30 + 'px';
                            newNode.style.width = '100%';
                            let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
                            // 判断兄弟节点是否存在
                            console.log(next);
                            if (next) {
                                // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                                divParent.insertBefore(newNode, next);
                            } else {
                                // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                                divParent.appendChild(newNode);
                            }
                        }
                    }
                    this.exportpdf()
                });
            },
            isSplit(nodes, index, pageHeight) {
                // 计算当前这块dom是否跨越了a4大小，以此分割
                if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index +
                        1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
                    return true;
                }
                return false;
            },

            // 报告2.0
            // 新版个人报告
            async newPersonReport(userId) {
                this.badComment = [];
                this.chartData = [];
                this.sxInfo.count = 0;
                this.sxInfo.str = ''
                let data = {
                    userId,
                    termKey: this.termKey
                }
                let data2 = {
                    userId,
                    type: 1,
                    termKey: this.termKey
                }
                this.currentUser = userId;
                let resData2 = await this.$Api.DataScreen.queryPersonReport(data2);
                console.log('旧版个人报告:', resData2);
                this.currentInfo = resData2.data;
                let resData = await this.$Api.DataScreen.getPeronWriteData(data);
                console.log('新版个人报告:', resData);
                this.level = resData.data.integrationLevel
                let wdlist = {
                    complete: {
                        name: '作品完整度',
                        des: '作品完整度要求作品内容完整、用字正确。'
                    },
                    form: {
                        name: '笔画形态',
                        des: '笔画形态由方向、笔性、粗细组成。'
                    },
                    layout: {
                        name: '章法占格',
                        des: '章法占格由卷面整洁、占格占位、字形大小组成。'
                    },
                    structure: {
                        name: '结构组合',
                        des: '结构组合由字形重心、主次收放、笔画关系、部件关系组成。'
                    }

                };

                for (var i in resData.data.analyseData) {
                    resData.data.analyseData[i].wdname = resData.data.analyseData[i].code.split('_')[0];
                    let str = wdlist[resData.data.analyseData[i].wdname].des + '需注意书写' + resData.data.analyseData[i]
                        .name +
                        '建议' + resData.data.analyseData[i].advice;
                    this.badComment.push(str)
                }
                this.tableData = resData.data;
                this.chartData = [resData.data.completeScore.toFixed(2), resData.data.formScore.toFixed(2), resData
                    .data.layoutScore.toFixed(2),
                    resData.data.structureScore.toFixed(2)
                ];
                if (this.tableData.completeScore < this.gradeInfo[this.currentGradeName].writeDefault[0]) {
                    this.sxInfo.count += 1;
                    this.sxInfo.str += '作品完整度,'
                }
                if (this.tableData.formScore < this.gradeInfo[this.currentGradeName].writeDefault[2]) {
                    this.sxInfo.count += 1;
                    this.sxInfo.str += '笔画形态,'
                }
                if (this.tableData.layoutScore < this.gradeInfo[this.currentGradeName].writeDefault[1]) {
                    this.sxInfo.count += 1;
                    this.sxInfo.str += '章法占格,'
                }
                if (this.tableData.structureScore < this.gradeInfo[this.currentGradeName].writeDefault[3]) {
                    this.sxInfo.count += 1;
                    this.sxInfo.str += '结构组合,'
                }
                setTimeout(() => {
                    this.initChart();
                }, 1000)
                console.log('this.chartData:', this.chartData)
            }
        },
        mounted() {},
    };
</script>
<style lang='less' scoped>
    @import url("./less/report_stu.less");
</style>